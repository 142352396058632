<section *ngIf="loading">
    <h2>Loading...</h2>
    <mat-progress-bar mode="indeterminate" color="primary" aria-label="Loading...">
    </mat-progress-bar>
  </section>
  
    <br />
  <mat-card>
    <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
        <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
            <div>
                <strong class="noMarginHeader">Results ({{firmCredentials.length}})</strong> 
                &nbsp;&nbsp;&nbsp;&nbsp;     
                <mat-form-field style="width:200px;">
                <input (keyup)="filterTable($event)" placeholder="Filter Credentials" matInput />
                <input placeholder="Filter Credentials" matInput />
                </mat-form-field>      
            </div>  
            <!-- <button mat-raised-button color="primary" title="Add Credential" (click)="editFirm(null)">
              <mat-icon>add</mat-icon> Add Credential
          </button>    -->
    </div>
    <br />
      <mat-table [dataSource]="dataSource" matSort #TableSort="matSort" class="mat-elevation-z8"
        style="overflow-x: auto;">
        <mat-row>Results ({{firmCredentials.length}})</mat-row>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  
        <ng-container matColumnDef="firmLeadCredentialType">
          <mat-header-cell *matHeaderCellDef mat-sort-header  fxFlex.lt-sm="100">Name</mat-header-cell>
          <mat-cell *matCellDef="let result"  fxFlex.lt-sm="100">
            <span>{{result.firmLeadCredentialType}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef fxFlex="7" fxFlex.lt-sm="100" style="justify-content: center !important;">
            Actions</mat-header-cell>
          <mat-cell *matCellDef="let result" fxFlex="7" fxFlex.lt-sm="100" style="justify-content: center !important;">
            <a href="javascript:void(0)" title="Delete {{result.ControlNumber}}">
              <mat-icon>delete</mat-icon>
            </a>
          </mat-cell>
        </ng-container>

      </mat-table>
      <br />
      <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 50, firmCredentials.length]" #TablePaginator="matPaginator"
        showFirstLastButtons></mat-paginator>
    </div>
  </mat-card>