<div [ngStyle]="{'--entity-color' : uiData.individual.color}">
    <div *ngIf="!displayData.personLicense && !displayData.personData" fxLayout='column' fxLayoutGap="16px">    
        <h2>Apply or Link your account to an Individual Certification </h2>      
        <mat-grid-list role="list"[cols]="!sharedService.mobile? applyData.width: 6" rowHeight="230px" gutterSize="16px">
            <mat-grid-tile role="listitem" [colspan]="!sharedService.mobile? applyData.cols: 6">
                <mat-grid-tile-header>
                    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                        <mat-icon class="medium-icon m-24">{{applyData.icon}}</mat-icon>
                        <span class="f-s-20">{{applyData.title}}</span>
                    </div>
                </mat-grid-tile-header>

                <div fxLayout="column">
                    <span *ngFor="let line of applyData.description" style="padding: 5px;">{{line}}</span>
                </div>    

                <mat-grid-tile-footer (click)="applicationClicked()">
                    <span mat-line class="f-s-20" style="padding: 20px;">{{applyData.action}}</span>
                </mat-grid-tile-footer>
            </mat-grid-tile>
        </mat-grid-list>      
    </div>

    <div *ngIf="displayData.personLicense && !displayData.personData" fxLayout='column' fxLayoutGap="16px">    
        <h2>Pending Individual Application</h2>      
        <mat-grid-list role="list"[cols]="!sharedService.mobile? applyData.width: 6" rowHeight="230px" gutterSize="16px">
            <mat-grid-tile role="listitem" [colspan]="!sharedService.mobile? applyData.cols: 6">
                <mat-grid-tile-header>
                    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                        <mat-icon class="medium-icon m-24">{{applyData.icon}}</mat-icon>
                        <span class="f-s-20">{{displayData.personLicense.firstName}}</span>
                    </div>
                </mat-grid-tile-header>

                <div fxLayout="row" style="flex: 1; display: flex; justify-content: space-between;">            
                    <div fxLayout="column">
                        <span style="padding: 5px;"><strong>Application ID: </strong>{{displayData.personLicense.id}}</span>                        
                        <span style="padding: 5px;"><strong>Application Date: </strong>{{displayData.personLicense.licenseDate | date:'shortDate' }}</span>
                        <span style="padding: 5px;"><strong>Application Status: </strong><div routerLink="/firm/apply" class="status-card" [ngStyle]="{'--status-color': statusColor(displayData.personLicense.status)}">{{displayData.personLicense.status}}</div></span>                        
                    </div>
                </div>   

                <mat-grid-tile-footer (click)="applicationClicked()">                    
                    <span mat-line class="f-s-20" style="padding: 20px;">View Application</span>
                </mat-grid-tile-footer>
            </mat-grid-tile>
        </mat-grid-list>      
    </div>

    <div *ngIf="displayData.personData">
        <h2>Individual</h2>      
        <mat-grid-list role="list" [cols]="!sharedService.mobile ? applyData.width : 6" rowHeight="230px" gutterSize="16px">
            <mat-grid-tile role="listitem" [colspan]="!sharedService.mobile ? applyData.cols : 6">
                <mat-grid-tile-header>
                    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
                        <mat-icon class="medium-icon m-24">{{applyData.icon}}</mat-icon>
                        <span class="f-s-20">{{displayData.personData.firstName}}</span>
                    </div>
                </mat-grid-tile-header>
        
                <div style="display: flex; flex-direction: column; height: 60%; width: 100%;">
                    <div style="margin: 1%;" class="data-container"> <!-- Use a class for styling -->
                        <div *ngFor="let row of displayFields">
                            <strong>{{row.displayName}} </strong>
                            <ng-container [ngSwitch]="row.dataType">
                                <ng-template [ngSwitchCase]="'date'">{{displayData.personData[row.dataName] | date:'shortDate'}}</ng-template>
                                <ng-template [ngSwitchCase]="'bool'">
                                    <input type="checkbox" [checked]="displayData.personData[row.dataName]" disabled>
                                </ng-template>
                                <ng-template [ngSwitchDefault]>{{displayData.personData[row.dataName]}}</ng-template>
                            </ng-container>
                        </div>
                    </div>
                </div>
        
                <mat-grid-tile-footer [routerLink]="'/individual/manage'">
                    <span mat-line class="f-s-20" style="padding: 20px;">Manage Individual</span>
                </mat-grid-tile-footer>
            </mat-grid-tile>
        </mat-grid-list>                  
    </div>
</div>