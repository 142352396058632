<div *ngIf="!loading" [@slideRight]>
  <mat-card>
    <div>
      <h2>Certificates</h2>      
        <app-firm-status-card [displayData]="{firmLicense,payment,firmData}"></app-firm-status-card>      
    </div>
  </mat-card>        
</div>

<div *ngIf="loading">
  <div [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
    <h1>Loading</h1>
  </div>      
</div>