<div class="matero-container-wrap matero-header-above matero-navbar-side" [ngClass]="{
  'matero-sidenav-collapsed': options.sidenavCollapsed && displaySidebar,
  'matero-header-white': !sharedService.darkMode
}" [dir]="options.dir">
<ng-progress></ng-progress>

<app-navbar (toggleSidenav)="sidenav.toggle()" [isMobile]="isMobile">
</app-navbar>

<div *ngIf="!production" [ngClass]="{
    'watermark': !isMobile,
    'small-watermark': isMobile
  }">
  <span>{{ build }}</span>
</div>

<app-timeout *ngIf="useTimeout"></app-timeout>

<mat-sidenav-container class="matero-container" autosize autoFocus>
  <mat-sidenav *ngIf="displaySidebar" #sidenav class="matero-sidenav" [mode]="isMobile ? 'over' : 'side'"
    [opened]="options.navPos === 'side' && options.sidenavOpened && !isMobile"
    (openedChange)="sidenavOpenedChange($event)" (closedStart)="sidenavCloseStart()" style="
      background-size: 90%;
      background-repeat: no-repeat;
      background-position: center bottom;
      background-image: url(../../assets/images/translucentOhaLogo.png);
    ">
    <app-sidebar (toggleCollapsed)="toggleCollapsed()" [isMobile]="isMobile" [ripple]="!isMobile">
    </app-sidebar>
  </mat-sidenav>

  <mat-sidenav-content fxLayout="column" #content class="matero-content-wrap">
    <div class="matero-content" *ngIf="!isIframe">
      <router-outlet></router-outlet>
    </div>
    <app-footer *ngIf="!isMobile" fxFlexOffset="auto"></app-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
</div>
