<section *ngIf="generating" class="no print">
  Generating...close when complete.
</section>
<section class="p-b-16">
  <div fxLayout="row" fxLayoutGap="8px" class="m-t-16 no print">
    <button type="button" mat-stroked-button color="primary" onclick="printJS({ printable: 'paymentSlip', type: 'html' })">
      Print Form
   </button>

    <!-- <button type="button" mat-stroked-button color="primary" (click)="pdf.saveAs('remittance.pdf')">
      Export to PDF
    </button> -->

    <button type="button" mat-stroked-button color="warn" (click)="cancel()">Close</button>
  </div>
</section>
  <mat-dialog-content>
    
  </mat-dialog-content>
