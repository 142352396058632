<section *ngIf="loading">
    <h2>Loading...</h2>
    <mat-progress-bar mode="indeterminate" color="primary" aria-label="Loading...">
    </mat-progress-bar>
</section>

<div (window:resize)="onResize($event)">
  <br />
  <mat-card>
    <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
        <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
            <div>
                <strong class="noMarginHeader">Results ({{firmLicenseApplications.length}})</strong> 
                &nbsp;&nbsp;&nbsp;&nbsp;     
                <mat-form-field style="width:200px;">
                  <input (keyup)="filterTable($event)" placeholder="Filter Applications" matInput />
                  <input placeholder="Filter Applications" matInput />
                </mat-form-field>      
            </div>     
    </div>
    <br />
    <mat-table [dataSource]="dataSource" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
      <mat-row>Results ({{firmLicenseApplications.length}})</mat-row>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row [ngClass]="sharedService.isMobile ? 'mobile-row':''" *matRowDef="let row; columns: displayedColumns;" (click)="viewApplication(row)"></mat-row>
    
      <ng-container matColumnDef="id">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.lt-sm="100">Application ID</mat-header-cell>
        <mat-cell *matCellDef="let result" fxFlex.lt-sm="100">
          <span title="Edit {{result.id}}" *ngIf="result.id !== ''">{{result.id}}</span>
        </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="status">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.lt-sm="100">Application Status</mat-header-cell>
        <mat-cell *matCellDef="let result" fxFlex.lt-sm="100">
          <span>{{result.status}}</span>
        </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="applicationDate">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.lt-sm="100">Application Date</mat-header-cell>
        <mat-cell *matCellDef="let result" fxFlex.lt-sm="100">
          <span>{{result.licenseDate | date:'shortDate'}}</span>
        </mat-cell>
      </ng-container>
    
      <ng-container matColumnDef="applicationType">
        <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.lt-sm="100">Application Type</mat-header-cell>
        <mat-cell *matCellDef="let result" fxFlex.lt-sm="100">
          <span>{{result.licenseType}}</span>
        </mat-cell>
      </ng-container>
    </mat-table>
    
      <br />
      <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 50, firmLicenseApplications.length]" #TablePaginator="matPaginator"
        showFirstLastButtons></mat-paginator>
    </div>
  </mat-card>
</div>  