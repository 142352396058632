import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { LEAD_BASED_PAINT, APPLICATION_STATUS, ANIMATIONS } from '@shared/utils/app-static-data';
import { IAccountAffiliation } from 'app/models/common/account-affiliation';
import { IFirm } from 'app/models/firm/firm';
import { IFirmLicense } from 'app/models/firm/firm-license';
import { CommonService } from 'app/services/common/common.service';
import { SharedService } from 'app/services/core/shared.service';
import { FirmService } from 'app/services/firm/firm.service';
import { UserInformationService } from 'app/services/user-info/user-info.service';
import { ToastrService } from 'ngx-toastr';
import { AddressComponent } from '../common/address/address.component';
import { AdminAccountsComponent } from '../common/admin-accounts/admin-accounts.component';
import { ApplicationsComponent } from '../common/applications/applications.component';
import { ContactComponent } from '../common/contact/contact.component';
import { StaffComponent } from '../common/staff/staff.component';
import { FirmCertificateListComponent } from '../firm/certificate/firm-certificate-list.component';
import { FirmCredentialListComponent } from '../firm/credential/firm-credential-list.component';
import { IPayment } from '../payments/models/payment';
import { IPersonLicense } from 'app/models/people/person-license';
import { IPerson } from 'app/models/people/person';
import { PersonService } from 'app/services/person/person.service';
import { IndividualAddressComponent } from './individual-address/individual-address.component';
import { IndividualApplicationsComponent } from './individual-applications/individual-applications.component';
import { IndividualCertificatesComponent } from './individual-certificates/individual-certificates.component';
import { IndividualContactInformationComponent } from './individual-contact-information/individual-contact-information.component';
import { IndividualFirmEmploymentComponent } from './individual-firm-employment/individual-firm-employment.component';
import { IndividualInformationComponent } from './individual-information/individual-information.component';
import { IndividualTestingComponent } from './individual-testing/individual-testing.component';
import { IndividualTrainingComponent } from './individual-training/individual-training.component';

@Component({
  selector: 'app-individual',
  templateUrl: './individual.component.html',
  styleUrls: ['./individual.component.scss'],
  animations: ANIMATIONS,  
})
export class IndividualComponent implements OnInit {
  userId: string = null;
  personLicense: IPersonLicense = null;  
  accountAffiliations: IAccountAffiliation[];
  personAccountAffiliation: IAccountAffiliation[];

  personData: IPerson = null;
  personApplications: IFirmLicense[];
    
  loading: boolean = true;
  firmForm: FormGroup = null;
  uiData = LEAD_BASED_PAINT;    
  objectKeys = Object.keys;  

  tabData: {component:any, title:string, icon:string, count:string}[];

  headerData: {category:{icon:string,title:string,data:any,displayType:string}[]}[]; 

  constructor(public sharedService: SharedService,
    public personService: PersonService,
    public commonService: CommonService) 
  {}

  ngOnInit(): void {      
    this.checkAfiiliationsLoading();
  }  

  isBoolean(data: any){
    return (typeof data === 'boolean');
  }

  checkAfiiliationsLoading(){
    this.loading = true;
    if(!this.commonService.loaded){
      setTimeout(() => {
        this.checkAfiiliationsLoading();    
      }, 2000);
    }
    else
      this.loadAffiliations()
  } 

  loadAffiliations(){        
    this.accountAffiliations = this.commonService.accountAffiliations; 
    this.personAccountAffiliation = this.commonService.firmAccountAffiliations;

    var personAffiliations = this.accountAffiliations.filter(x=>x.person !== null && x.status !== APPLICATION_STATUS.cancelled)
    this.personData = personAffiliations.length ? personAffiliations[0].person : null;        
    this.personLicense = this.personService.personLicense;    
        
    if(this.personData){
      this.loadManagementData();
      this.loading = false;   
      // this.personService.loadPersonData(this.personData).subscribe(res=>{        
      //   this.loadManagementData();
      //   this.loading = false;
      // },err=>{})      
    }
    else{   
      this.loading = false;               
      //this.getPaymentStatus();      
    }    
  }

  applicationClicked(){}
  cancelIndividualAffiliation(){}

  loadManagementData(){    
    //this.firmCertificates = this.firmService.firmCertificates;
    //this.firmApplications = this.firmService.firmLicenses.filter(x=>x.firm !== null && x.firm.id === this.firmService.affiliatedFirm.id);
    //this.firmCredentials = this.firmService.firmCredentials;
    
    this.tabData =
    [
      {component:IndividualApplicationsComponent, title:'Applications - ', icon:'', count: '1'},
      {component:IndividualCertificatesComponent, title:'Certificates - ', icon:this.uiData.certificate.icon, count: '1'},
      {component:IndividualTestingComponent, title:'Test History - ', icon:this.uiData.credential.icon, count: '1'},
      {component:IndividualTrainingComponent, title:'Training History - ', icon:this.uiData.staff.icon, count: '2'},
      {component:IndividualFirmEmploymentComponent, title:'Firm Employment - ', icon:this.uiData.address.icon, count: '1'},
      {component:IndividualAddressComponent, title:'Address', icon:this.uiData.contact.icon, count: ''},
      {component:IndividualContactInformationComponent, title:'Contact Information', icon:'', count: ''},
      {component:IndividualInformationComponent, title:'Personal Information', icon:'', count: ''},
    ];

  this.headerData= [
    {category:[
        {icon:this.uiData.id.icon,title:'Individual Id: ',data:1, displayType:'string'},
        {icon:this.uiData.name.icon,title:'Individual Status: ',data: 'Active',displayType:'string'},
      ]
    },
    // {category:[
    //     {icon:this.uiData.id.icon,title:'CCB: ',data:'',displayType:'string'},
    //     {icon:this.uiData.calendar.icon,title:'CCB Expiration: ',data:'',displayType:'date'},
    //   ]
    // },
    {category:[        
        {icon:this.uiData.calendar.icon,title:'Next Certificate Expiration: ',data:'11/12/23',displayType:'date'},
        //{icon:this.uiData.calendar.icon,title:'Next Staff Expiration: ',data:'11/12/23',displayType:'date'},
      ]
    },
    {category:[
        {icon:this.uiData.name.icon,title:'Published to Website: ',data:true,displayType:'string'},
        //{icon:this.uiData.id.icon,title:'Services Avalable: ',data:'TEST',displayType:'string'},
      ]
    },
  ];
  }
}
