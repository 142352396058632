import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IContactInformation } from 'app/models/common/contact-information';
import { IPersonContactInformation } from 'app/models/people/personContactInformation';
import { CommonService } from 'app/services/common/common.service';
import { FirmService } from 'app/services/firm/firm.service';
import { PersonService } from 'app/services/person/person.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  public loading: boolean = true;
  private loadCount: number = 0;

  public firmPersonContact: IPersonContactInformation;
  public firmContactInformation: IContactInformation;

  constructor(
    private firmService: FirmService, 
    private personService: PersonService, 
    private commonService: CommonService,
    private dialog: MatDialog,) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(){   
    this.firmService.getFirmContactInformation(this.firmService.affiliatedFirm.id).subscribe(result=>{
      this.firmContactInformation = result.contactInformation;      
      this.doneLoading();
    },error=>{}); 

    this.personService.GetAllFirmPOCPeopleContactInformation(this.firmService.affiliatedFirm.id).subscribe(result=>{
      this.firmPersonContact = result;      
      this.doneLoading();
    },error=>{});
  }

  doneLoading(){
    if(++this.loadCount >= 2){
      this.loading = false;
    }
  }
}
