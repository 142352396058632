import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, Inject, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn, AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { environment } from '@env/environment';
import { ANIMATIONS, APPLICATION_STATUS, FIRM_APPLICATION_TYPES, FIRM_SERVICES, FIRM_SERVICE_FOR, LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { StaffAddEditComponent } from 'app/components/common/staff/add-edit/staff-add-edit.component';
import { IFirmLicenseDocument } from 'app/models/firm/firm-license-document';
import { IFirmLicense } from 'app/models/firm/firm-license';
import { IDocumentFile } from 'app/models/document/document-file';
import { SharedService } from 'app/services/core/shared.service';
import { UserInformationService } from 'app/services/user-info/user-info.service';
import { Certificate } from 'crypto';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription } from 'rxjs';
import { filter, debounceTime } from 'rxjs/operators';
import { IFirmLicensePerson } from 'app/models/firm/firm-license-person';
import { FirmService } from 'app/services/firm/firm.service';
import { CommonService } from 'app/services/common/common.service';
import { IAccountAffiliation } from 'app/models/common/account-affiliation';
import { IFirm } from 'app/models/firm/firm';
import { PaymentService } from 'app/components/payments/service/payment.service';
import { IFirmLicenseDeficiency } from 'app/models/firm/firm-license-deficiency';
import { IPersonContactInformation } from 'app/models/people/personContactInformation';
import { PersonService } from 'app/services/person/person.service';

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-firm-license',
  templateUrl: './firm-license.component.html',
  styleUrls: ['./firm-license.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }],
  animations: ANIMATIONS
})
export class FirmLicenseComponent implements OnInit {
  public firmServices = FIRM_SERVICES;
  public uiData: any = LEAD_BASED_PAINT;  
  public firmApplicationTypes = FIRM_APPLICATION_TYPES;

  private inputChangeSubject = new Subject<string>();
  public firmToLink: IFirm = null;
  public firmToLinkAndAddress: any = null;
  public firmLicense: IFirmLicense = null;  
  public firmLicenseDeficiencies: IFirmLicenseDeficiency[] = [];
  public firmLicenseDocuments: IFirmLicenseDocument[] = [];
  public firmLicensePersons: IFirmLicensePerson[] = [];  
  public existingFirmAccountAffiliation: IAccountAffiliation;
  public newAccountAffiliation: IAccountAffiliation = {userId:'',id:0,firm:null,atp:null,person:null,status:''};  

  public allFirmNames: {name: string, isPrivate: boolean}[] = [];
  public filteredFirmNames: {name: string, isPrivate: boolean}[] = [];
  public firmPOCs: IPersonContactInformation[] = [];

  public bgColor:string = LEAD_BASED_PAINT.firm.color;
  public licenseType: string = this.firmApplicationTypes.lbpa;
  public entityTypes: string[] = [this.firmApplicationTypes.lbpa,this.firmApplicationTypes.rrp];
  public firmLinkFields: {} = {    
    street:'Street',
    city:'City',
    state:'State',
    zip:'Zip',
    county:'County',
  };
  public individualTable:{icon:string,title:string,layout:any}
  
  public loadCount: number = 0;
  public finishedLoadingCount: number = 0;
  public finishedFirmLoadingCount: number = 0;

  public applyHeader: string = "Apply for a Firm Certificate"
  public linkHeader: string = "Link to an existing Firm"
  public genericHeader: string = "Apply for a new Firm or Link to an existing Firm"  
  public affiliationHeader: string = "Apply for a new Firm Certificate or Renew an existing Firm Certificate"
  public currentHeader: string = this.genericHeader

  public loading: boolean = true;
  public innerLoading: boolean = true;
  public showSubmitted: boolean = false;
  public showConfirmed: boolean = false;
  public selectedType: boolean = false;
  public showStartButton: boolean = true;
  public applyLicense: boolean = false;
  public firmToLinkValidated: boolean = false;
  public canContinueLicense: boolean = false;
  public canEdit: boolean = true;
  public isFirmLinkNameValid: boolean = false;

  public today: Date = new Date(Date.now());
  public step: number = 0;

  @ViewChild('firmStepper') firmStepper: MatStepper;
  
  public licenseApplicationDataForm = null;
  public licenseApplicationAttestationForm = null

  public documentColumns: string[] = ['fileName'];
  public documentSource = new MatTableDataSource<any>();
  pageSize = 50;    

  dataSource = new MatTableDataSource<any>();
  @ViewChild('TablePaginator') paginator: MatPaginator;
  @ViewChild('TableSort') sort: MatSort;
  
  constructor(
    public sharedService: SharedService,
    public firmService: FirmService,
    public commonService: CommonService,
    public personService: PersonService,
    private router: Router,
    private toastr: ToastrService,
    private dialog: MatDialog,   
    public userService: UserInformationService, 
    private route: ActivatedRoute,    
    public paymentService: PaymentService,
  ) {
      this.inputChangeSubject
          .pipe(debounceTime(500))
          .subscribe((value: string) => {
              this.handleInputChange(value);
          });
  }

  ngOnInit(): void {      
    if(history.state.licenseDate){
      this.firmLicense = history.state;            
      this.applicationPassed();
    }      
    else
      this.startLoading();
  }

  startLoading(){    
    this.loading = true;
    this.licenseType = this.firmApplicationTypes.lbpa
    this.initializeApplicationData();  
    this.checkIfFirmServiceLoaded();
    this.checkIfCommonServiceLoaded();
  }

  applicationPassed(){    
    this.existingFirmAccountAffiliation = this.commonService.accountAffiliations.filter(x=>x.status !== 'Cancelled' && x.firm !== null)[0];    
    this.setLicenseFormData(this.applyHeader);
    this.loadFirmLicenseFormData();
    this.updateIndividualTable();     
    this.setUserId();

    this.currentHeader = this.applyHeader;
    this.applyLicense =true;
    this.selectedType = true;
    this.loading = false;
    this.canEdit = this.firmLicense.status !==APPLICATION_STATUS.inReview && this.firmLicense.status !==APPLICATION_STATUS.cancelled && this.firmLicense.status !==APPLICATION_STATUS.approved;   
    //this.selectedType = true;
  }

  checkIfFirmServiceLoaded(){    
    if(!this.firmService.loaded){
      setTimeout(() => {
        this.checkIfFirmServiceLoaded();    
      }, 1500);
    }
    else
      this.loadFirmLicenses(); 
  }

  checkIfCommonServiceLoaded(){    
    if(!this.commonService.loaded){
      setTimeout(() => {
        this.checkIfCommonServiceLoaded();    
      }, 1500);
    }
    else
      this.loadFirmAccountAffiliation(); 
  }
  
  loadFirmLicenses(){    
    var result = this.firmService.firmLicenses.filter(x => x.status !== APPLICATION_STATUS.cancelled && x.status !== APPLICATION_STATUS.approved && x.firm === null) 
    
    if (result.length){      
      this.firmLicense = result[0]
      this.canContinueLicense = true;

      this.firmService.getFirmLicensePeople(this.firmLicense.id).subscribe(
        result=>{                  
          this.firmLicensePersons = result;                              
          this.finishedFirmLoading();
        },
        error=>{
          console.log("There was an error loading the firm license people:",error);
        }
      ); 
      this.firmService.getFirmLicenseDocuments(this.firmLicense.id).subscribe(
        result=>{        
          this.firmLicenseDocuments = result;                                     
          this.finishedFirmLoading();
        },
        error=>{
          console.log("There was an error loading the firm license documents:",error);
        }
      ); 
      this.firmService.getFirmLicenseDeficiencies(this.firmLicense.id).subscribe(result=>{        
        this.firmLicenseDeficiencies = result.filter(x=>!x.isResolved);
      },error=>{
        console.log("There was an error loading the firm license deficiencies:",error);
      })
    }
    else{
      this.finishedLoading();
    }             
  }

  loadFirmAccountAffiliation(){
    var result = this.commonService.accountAffiliations.filter(x=>x.status === 'Active' && x.firm != null)
    if (result.length)
    {
      this.existingFirmAccountAffiliation = result[0]
    }
    this.finishedLoading();    
  }

  finishedFirmLoading(){
    ++this.finishedFirmLoadingCount;    
    if(this.finishedFirmLoadingCount >= 2){      
      this.finishedLoading();
    }    
  }

  finishedLoading(){
    ++this.finishedLoadingCount;    
    if(this.finishedLoadingCount >= 2){
      this.currentHeader = this.existingFirmAccountAffiliation ? this.affiliationHeader : this.genericHeader;
      if (this.existingFirmAccountAffiliation){
        this.initializeApplicationData();
        this.prefillFirmLicense();
      }      
      this.canEdit = this.firmLicense.status === APPLICATION_STATUS.inProgress || this.firmLicense.status === APPLICATION_STATUS.actionNeeded
      this.loading = false;   
    }    
  }

  getServiceForTypes(){
    return Object.values(FIRM_SERVICE_FOR);
  }

  onCoverFee(row: any){             
    var person = this.firmLicensePersons.filter(x=>x.id === row.id)[0]
    person.firmIsPaying = !row.FirmIsPaying;    
    person.licenseId = person.license.id;    
    this.firmService.saveFirmLicensePerson(person).subscribe(result=>{},
      error=>{console.log("An error occurred while saving the individual:", error)});
  }

  onFirmNameChanged(value: string) {
    if(this.allFirmNames.find(result => result.name === value))
    {      
      this.isFirmLinkNameValid = true;
    }
    else{
      this.firmToLinkAndAddress = null;
      this.isFirmLinkNameValid = false;      
      this.inputChangeSubject.next(value);
    }
  }

  private handleInputChange(value: string) {    
    if(value !== "" && value.length > 3)    
    {
      this.firmService.getSimilarFirmNames(value).subscribe(res=>{
        this.allFirmNames = res;
        this.filteredFirmNames = res.filter(x => !x.isPrivate);        
      },err=>{})        
    }
    else{
      this.allFirmNames = [];
      this.filteredFirmNames = [];
    }    
  }

  displayFn(value: string): string {
    return value ? value : '';
  }

  filterTable(event: any) {
    //this.dataSource.filter = event.target.value.trim().toLowerCase();
  }

  cancelApplication(){        
    const dialogRef = this.firmLicense.status === APPLICATION_STATUS.submitted ? 
      this.commonService.openDialog('<strong>You are about to withdraw your Firm Application</strong>.\nYour application will return to an <strong>In Progress Status</strong> where you can <strong>Edit</strong> your <strong>Firm Application</strong> as needed.\n\nAre you sure you want to <strong>Withdraw</strong> your\n<strong> Firm Application </strong> ?', 'Withdraw Firm Application Confirmation'):
      this.commonService.openDialog('You are about to cancel your Firm Application\n<strong>Your application data will be lost and you will have to start a new Firm Application.\n\nPAYMENTS THAT HAVE ALREADY BEEN MADE ARE NON-REFUNDABLE!</strong>\n\nAre you sure you want to cancel your\n<strong> Firm Application </strong> ?', 'Cancel Firm Application Confirmation');

    dialogRef.afterClosed().subscribe(result => {
      if (result) {        
        this.firmLicense.status = this.firmLicense.status === APPLICATION_STATUS.submitted ? APPLICATION_STATUS.inProgress : APPLICATION_STATUS.cancelled;
        this.firmService.saveFirmLicense(this.firmLicense).subscribe(
          res=>{
            this.firmService.loadFirmLicenses();
            this.resetAll();            
          },
          err=>{})
      } 
    });
  }

  editIndividual(event: MouseEvent, individual: IFirmLicensePerson) {     
    const target = event.target as HTMLElement;       
    if (!target.classList.contains('stop-propagation') && !target.classList.contains('mat-checkbox-inner-container') && this.canEdit) {      
      if(!individual)
      {
        individual = { 
          id: 0,
          licenseId: this.firmLicense.id,
          license: this.firmLicense,
          person: null,
          certificateId: null,
          firstName: null,
          lastName: null,
          discipline: null,
          atp:  null,
          trainingDate: null,
          firmIsPaying: false,
        };
      }
      individual.licenseId = this.firmLicense.id

      const dialogRef = this.dialog.open(StaffAddEditComponent, {
        width: '90%',
        data: {individualData: individual, licenseType: this.licenseType}
      });
      dialogRef.afterClosed().subscribe(result => {
        ++this.loadCount 
        this.updateApplicationPeople();
      });
    }
  }

  deleteIndividual(row: any){    
    const dialogRef = this.commonService.openDialog('You are about to delete a certified individual from this application.\n <strong>Any uploaded documents attached to this individual will be lost.</strong> \n\n Are you sure you want to delete the individual: \n<strong>' + row.Name + '</strong> ?','Delete Certified Individual Confirmation');    

    dialogRef.afterClosed().subscribe(result => {
      if (result) {  
        var filteredDocuments = this.firmLicenseDocuments.filter(x=>x.licensePersonId == row.id);
        var documentTotalCount = filteredDocuments.length
        var currentDocumentCount = 0

        if(documentTotalCount > 0){
          filteredDocuments.forEach(document=>{
            this.firmService.deleteFirmLicenseDocument(document.id).subscribe(result=>{
              this.commonService.deleteDocumentFile(document.document.id).subscribe(result=>{}, error=>{console.error("An error occurred while deleting the file: ", error);});
              if(++currentDocumentCount >= documentTotalCount){
                this.firmService.deleteFirmLicensePerson(row.id).subscribe(
                  result=>{   
                    this.updateApplicationDocuments();           
                    this.updateApplicationPeople()
                  },
                  error=>{console.error("An error occurred while deleting the individual: ", error)}
                );
              }
            },error=>{console.error("An error occurred while deleting the file: ", error)})
          });
        }
        else{
          this.firmService.deleteFirmLicensePerson(row.id).subscribe(result=>{
            this.updateApplicationPeople()
          },
          error=>{console.error("An error occurred while deleting the individual: ", error)}); 
        }          
      } 
    });
  }

  loadFirmLicenseFormData(): void {
    this.loadFormFields();
    if(this.firmLicense.id !== 0)
    {      
      this.updateApplicationPeople();
      this.updateApplicationDocuments();
    }
  }

  updateApplicationPeople() {    
    this.firmService.getFirmLicensePeople(this.firmLicense.id).subscribe(
      result=>{                
        this.firmLicensePersons = result;                              
        this.dataLoaded();
      },
      error=>{}
    ); 
  }
  
  updateApplicationDocuments(){
    this.firmService.getFirmLicenseDocuments(this.firmLicense.id).subscribe(
      result=>{        
        this.firmLicenseDocuments = result;                                     
        this.dataLoaded();
      },
      error=>{}
    ); 
  }

  dataLoaded(){    
    this.loadCount++;
    if(this.loadCount >= 2){
      this.firmLicenseDocuments.filter(x=>x.licensePersonId > 0).forEach((document) => {
        if(this.firmLicensePersons.filter(x=>x.id == document.licensePersonId)[0].hasOwnProperty('Documents'))
          this.firmLicensePersons.filter(x=>x.id == document.licensePersonId)[0]['Documents'].push(document.fileName);
        else
          this.firmLicensePersons.filter(x=>x.id == document.licensePersonId)[0]['Documents'] = [document.fileName];
      })      
      this.updateIndividualTable();
      this.individualTable.layout.data = this.assignApplicationPersonsToIData()
      this.loading=false;      
    }
  }

  loadFormFields(): void {    
    this.licenseType = this.firmLicense.licenseType;    
    this.showStartButton = false;
    
    for (const controlName in this.firmLicense) {
      if (this.licenseApplicationDataForm.controls.hasOwnProperty(controlName)) {
        this.licenseApplicationDataForm.controls[controlName].setValue(this.firmLicense[controlName]);
        if(!this.canEdit)
          this.licenseApplicationDataForm.controls[controlName].disable(); 
      } else if (this.licenseApplicationAttestationForm.controls.hasOwnProperty(controlName)) {
        this.licenseApplicationAttestationForm.controls[controlName].setValue(this.firmLicense[controlName]);
        if(!this.canEdit)
          this.licenseApplicationAttestationForm.controls[controlName].disable(); 
      }
    }      
  }

  exitApplication(isDirty: boolean){
    const dialogRef = this.commonService.openDialog(
      isDirty ? 'You are about to exit this application.\n <strong>Any changes you have made to this page will not be saved.</strong> \n\n Are you sure you want to exit?':
      '<strong>You are about to exit this application.</strong>\n\n Are you sure you want to exit?',
      'Are You Sure?');    

    dialogRef.afterClosed().subscribe(result => {
      if (result) {        
        this.resetAll()              
      } 
    });
  }

  resetAll(){
    this.selectedType = false;        
    this.showSubmitted = false;
    this.showConfirmed = false;
    this.showStartButton = true;
    this.applyLicense = false;
    this.loadCount = 0    
    this.firmLicensePersons = [];
    this.firmLicenseDocuments = [];
    this.licenseType = this.firmApplicationTypes.lbpa;        
    this.startLoading();
    this.resetFormFields();    
  }

  resetFormFields() {
    Object.keys(this.licenseApplicationDataForm.controls).forEach(key => {
      if (typeof this.licenseApplicationDataForm.controls[key].value === 'boolean') {
        this.licenseApplicationDataForm.get(key)?.setValue(false);
      } else {
        this.licenseApplicationDataForm.get(key)?.setValue('');
      }
    });

    Object.keys(this.licenseApplicationAttestationForm.controls).forEach(key => {
      if (typeof this.licenseApplicationAttestationForm.controls[key].value === 'boolean') {
        this.licenseApplicationAttestationForm.get(key)?.setValue(false);
      } else {
        this.licenseApplicationAttestationForm.get(key)?.setValue('');
      }
    });
  }  

  initializeApplicationData(): void {    
    const date = new Date();
    
    this.firmLicense = {
      id: 0,

      userId: '',    
      firm: null,    
      status: APPLICATION_STATUS.inProgress,    
      licenseDate: date.toISOString(),
      licenseType: this.firmApplicationTypes.lbpa,      
      firmName: '',
      ccb: '',
      renewal: false,
      previousLicense: '',
      isPublic: false,
      inspections: false,
      clearanceTesting: false,
      leadHazardScreening: false,
      riskAssessments: false,
      abatement: false,
      mailingIsPhysical: false,
      contactTitle: '',
      contactFirstName: '',
      contactMiddleName: '',
      contactLastName: '',
      contactPhone: '',
      contactAlternatePhone: '',
      contactFaxPhone: '',
      contactEmail: '',
      contactConfirmEmail: '',
      firmPhysicalStreet: '',
      firmPhysicalState: '',
      firmPhysicalCity: '',
      firmPhysicalZip: '',
      firmPhysicalCounty: '',
      firmMailingStreet: '',
      firmMailingState: '',
      firmMailingCity: '',
      firmMailingZip: '',
      firmMailingCounty: '',
      firmPhone: '',
      firmAlternatePhone: '',
      firmFaxPhone: '',
      firmEmail: '',
      firmConfirmEmail: '',
      firmWebsite: '',
      firmServices: '',
      attestation: false,
      esignature: '',
    };
  }

  prefillFirmLicense(){    
    var firm = this.existingFirmAccountAffiliation.firm;   
     
    this.firmLicense.firmName = firm.name;
    this.firmLicense.ccb = firm.ccb;
    this.firmLicense.isPublic = !firm.isPrivate;    
    this.firmLicense.firmServices = firm.servicesAvailable;   
    this.firmLicense.firm = firm;

    this.firmService.getFirmAddresses(firm.id).subscribe(result=>{      
      var physicalAddress = result.filter(x=>x.isCurrentPhysical)[0];
      var mailingAddress = result.filter(x=>x.isCurrentMailing)[0];

      this.firmLicense.firmPhysicalCity = physicalAddress.address.city;
      this.firmLicense.firmPhysicalCounty = physicalAddress.address.county;
      this.firmLicense.firmPhysicalState = physicalAddress.address.state;
      this.firmLicense.firmPhysicalStreet = physicalAddress.address.street;
      this.firmLicense.firmPhysicalZip = physicalAddress.address.zip;

      this.firmLicense.firmMailingCity = mailingAddress.address.city;
      this.firmLicense.firmMailingCounty = mailingAddress.address.county;
      this.firmLicense.firmMailingState = mailingAddress.address.state;
      this.firmLicense.firmMailingStreet = mailingAddress.address.street;
      this.firmLicense.firmMailingZip = mailingAddress.address.zip;
    },error=>{console.error("An error occurred getting the firm addressess: ", error)})

    this.firmService.getFirmContactInformation(firm.id).subscribe(result=>{
      this.firmLicense.firmPhone = result.contactInformation.phone;
      this.firmLicense.firmAlternatePhone = result.contactInformation.cell;
      this.firmLicense.firmFaxPhone = result.contactInformation.fax;
      this.firmLicense.firmEmail = result.contactInformation.email;
      this.firmLicense.firmConfirmEmail = result.contactInformation.email;
      this.firmLicense.firmWebsite = result.contactInformation.website;            
    },error=>{console.error("An error occurred getting the firm contact information: ", error)})

    this.personService.GetAllFirmPOCPeopleContactInformation(firm.id).subscribe(result=>{      
      this.firmLicense.contactTitle = result[0].person.title;
      this.firmLicense.contactFirstName = result[0].person.firstName;
      this.firmLicense.contactMiddleName = result[0].person.middleName;
      this.firmLicense.contactLastName = result[0].person.lastName;      

      this.firmLicense.contactPhone = result[0].contactInformation.phone;
      this.firmLicense.contactAlternatePhone = result[0].contactInformation.cell;
      this.firmLicense.contactFaxPhone = result[0].contactInformation.fax;
      this.firmLicense.contactEmail = result[0].contactInformation.email;
      this.firmLicense.contactConfirmEmail = result[0].contactInformation.email;
    },error=>{console.error("An error occurred getting the firm person contact information: ", error)})
  }

  async onFileSelected(event: any, row: any) {   
    try{ 
      let dirtyFile = (event.target as HTMLInputElement).files[0];
      let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, '').replace(/.txt$/, ''));

      if (file) {
        const fileData = await this.getFileSerializedData(dirtyFile);
      
        var docFile: IDocumentFile = {
          id: 0,
          file: fileData.serializedData,
        }

        if (row.hasOwnProperty('Documents') && row['Documents'] !== undefined){
          row['Documents'].push(file.name);
        }
        else{
          row['Documents'] = [file.name];
        }
        const applicationDocument: IFirmLicenseDocument = {
          id: 0,
          licenseId: this.firmLicense.id,
          license: this.firmLicense,
          document: docFile,
          fileName: file.name,
          fileType: fileData.fileType,
          licensePersonId: row.id,
          licensePerson: null
        }
        
        this.saveAppDocument(applicationDocument)
      }   
    } catch (error) {
      console.error("An error occurred while processing the file:", error);
    } 
  }

  saveAppDocument(applicationDocument: IFirmLicenseDocument){    
    this.commonService.saveDocumentFile(applicationDocument.document).subscribe(result=>{
      applicationDocument.document = result;
      this.firmService.saveFirmLicenseDocument(applicationDocument).subscribe(result=>{
        applicationDocument.id = result.id
        this.firmLicenseDocuments.push(applicationDocument);
        this.firmLicenseDocuments = this.firmLicenseDocuments.filter(x => x.fileName !== '');
      },error => {
        console.error("An error occurred while processing the file: ", error);
      });
    },error=>{
      console.error("An error occurred while processing the file: ", error);
    })
  }

  clearFile(row: any, documentName: string) {      
    const dialogRef = this.commonService.openDialog('<strong>You are about to remove a file.</strong>\n\nAre you sure you want to remove the file: \n<strong>' + documentName + '</strong> ? \n\n', 'Remove File Confirmation')

    dialogRef.afterClosed().subscribe(result => {
      if (result) {             
        const licenseDocument = this.firmLicenseDocuments.filter(x=>x.fileName === documentName)[0];

        this.firmService.deleteFirmLicenseDocument(licenseDocument.id).subscribe(result=>{
          this.commonService.deleteDocumentFile(licenseDocument.document.id).subscribe(result=>{}, error=>{console.error("An error occurred while deleting the file: ", error);});

          this.firmLicenseDocuments = this.firmLicenseDocuments.filter(x => x.fileName !== documentName);
          this.individualTable.layout.data.filter(x=>x.id === row.id)[0]['Documents'] = this.individualTable.layout.data.filter(x=>x.id === row.id)[0]['Documents'].filter(x => x !== documentName);         
        },
        error=>{
          console.error("An error occurred while deleting the file: ", error);
        });           
      }
    })             
  }

  setLicenseFormData(title: string){    
    if(title === this.applyHeader)
    {
      this.licenseApplicationDataForm = new UntypedFormGroup({
        firmName: new UntypedFormControl("", [Validators.required]),
        ccb: new UntypedFormControl(""),
        renewal: new UntypedFormControl(false),
        previousLicense: new UntypedFormControl(""),        
        isPublic: new UntypedFormControl(false), 
        inspections: new UntypedFormControl(false), 
        clearanceTesting: new UntypedFormControl(false), 
        leadHazardScreening: new UntypedFormControl(false), 
        riskAssessments: new UntypedFormControl(false), 
        abatement: new UntypedFormControl(false),
        mailingIsPhysical: new UntypedFormControl(false),
        contactTitle: new UntypedFormControl(""),
        contactFirstName: new UntypedFormControl("", [Validators.required]),
        contactMiddleName: new UntypedFormControl(""),
        contactLastName: new UntypedFormControl("", [Validators.required]),  
        contactPhone: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
        contactAlternatePhone: new UntypedFormControl("", [Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
        contactFaxPhone: new UntypedFormControl("", [Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
        contactEmail: new UntypedFormControl("", [Validators.required, Validators.email]),
        contactConfirmEmail: new UntypedFormControl("", [Validators.required, Validators.email]), 
        firmPhysicalState: new UntypedFormControl("", [Validators.required]),
        firmPhysicalStreet: new UntypedFormControl("", [Validators.required]),
        firmPhysicalCity: new UntypedFormControl("", [Validators.required]),
        firmPhysicalZip: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\d{5}(-\d{4})?$/)]),
        firmPhysicalCounty: new UntypedFormControl(""),        
        firmMailingStreet: new UntypedFormControl(""),
        firmMailingCity: new UntypedFormControl(""),
        firmMailingState: new UntypedFormControl(""),
        firmMailingZip: new UntypedFormControl("", [Validators.pattern(/^\d{5}(-\d{4})?$/)]),
        firmMailingCounty: new UntypedFormControl(""),
        firmPhone: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
        firmAlternatePhone: new UntypedFormControl("", [Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
        firmFaxPhone: new UntypedFormControl("", [Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
        firmEmail: new UntypedFormControl("", [Validators.required, Validators.email]),
        firmConfirmEmail: new UntypedFormControl("", [Validators.required, Validators.email]),    
        firmWebsite: new UntypedFormControl(""),
        firmServices: new UntypedFormControl(""),
      }, {
        validators: [
          this.requiredFieldValidator('firmMailingStreet'),
          this.requiredFieldValidator('firmMailingCity'),
          this.requiredFieldValidator('firmMailingState'),
          this.requiredFieldValidator('firmMailingZip'),
          this.firmService.emailValidator('contactEmail', 'contactConfirmEmail'),
          this.firmService.emailValidator('firmEmail', 'firmConfirmEmail'), 
        ]
      });      
    }
    else{
      this.licenseApplicationDataForm = new UntypedFormGroup({
        firmName: new UntypedFormControl("", [Validators.required]),
        //firmCcbPml: new UntypedFormControl("", [Validators.required]),
        //firmCertification: new UntypedFormControl("", [Validators.required]),        
      });

      Object.values(this.licenseApplicationDataForm.controls).forEach((control: AbstractControl) => {
        control.valueChanges.subscribe(() => {
          this.markFirmToLinkDirty();
        });
      });      
    }

    this.licenseApplicationAttestationForm = new UntypedFormGroup({
      attestation: new UntypedFormControl(false, [Validators.requiredTrue]),
      esignature: new UntypedFormControl("", [Validators.required])
    });
  }

  markFirmToLinkDirty(): void {
    this.firmToLinkValidated = false;
  }

  changeForm(title: string){   
    //this.canEdit = true;

    this.firmToLink = null;
    this.firmToLinkValidated = false;

    this.setLicenseFormData(title);
    if(title !== this.linkHeader)    
      this.loadFirmLicenseFormData()
    this.updateIndividualTable();     
    this.setUserId();

    this.currentHeader = title;
    this.applyLicense = title === this.applyHeader ? true : false;
    this.selectedType = true;
    //this.selectedType = !this.selectedType;        
  }

  assignApplicationPersonsToIData() {    
    const iData = [];
    this.firmLicensePersons.forEach((applicationPerson) => {        
      iData.push({
        id: applicationPerson.id,
        IDNumber: applicationPerson.certificateId,
        Name: applicationPerson.firstName + ' ' + applicationPerson.lastName,
        ActiveDisciplines: applicationPerson.discipline,
        Atp: applicationPerson.atp,
        TrainingDate: applicationPerson.trainingDate,
        FirmIsPaying: applicationPerson.firmIsPaying,
        ['Documents']: applicationPerson['Documents'] 
      })
    });
    
    return iData;
  }
  

  updateIndividualTable(){    

    this.individualTable = this.licenseType == this.firmApplicationTypes.lbpa ?
    {
      icon: 'staff',
      title:'Certified Individuals',
      layout:{
        columns:['Name', 'ActiveDisciplines','FirmIsPaying'],
        container:[
          //{displayName:'ID Number',columnName:'IDNumber', type:'string'},
          {displayName:'Name',columnName:'Name', type:'string'},
          {displayName:'Active Disciplines',columnName:'ActiveDisciplines', type:'string'},          
          {displayName:'Cover Fee',columnName:'FirmIsPaying', type:'boolean'},          
        ],
        data: []
      }
    } :
    {
      icon: 'staff',
      title:'Certified Renovators',
      layout:{
        columns:['Name', 'Atp','TrainingDate'],
        container:[
          //{displayName:'Certificate Number',columnName:'IDNumber', type:'string'},
          {displayName:'Name',columnName:'Name', type:'string'},
          {displayName:'Accredited Training Provider',columnName:'Atp', type:'string'},          
          {displayName:'Training Date',columnName:'TrainingDate', type:'date'},                   
        ],
        data: []
      }
    }  
  }

  startFirmLicenseApplication(): void {        
    this.showStartButton = false;           
    setTimeout(() => {this.firmStepper.next();},200)          
  }

  setUserId(): void {
    this.userService.getUserInfo().subscribe(res=>{      
      this.firmLicense.userId = res.email;                
    })    
  }

  mailingIsSame(): void {
    this.licenseApplicationDataForm.get('firmMailingStreet').updateValueAndValidity();
    this.licenseApplicationDataForm.get('firmMailingCity').updateValueAndValidity();
    this.licenseApplicationDataForm.get('firmMailingState').updateValueAndValidity();
    this.licenseApplicationDataForm.get('firmMailingZip').updateValueAndValidity();
  }

  requiredFieldValidator(fieldName: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const fieldValue = control.value[fieldName];
      const sameFieldValue = control.value.mailingIsPhysical;
      if (!sameFieldValue) {
        return (fieldValue !== null && fieldValue !== '' && fieldValue !== undefined) ? null : { [`${fieldName}Required`]: true };
      }
      return null;
    };
  }

  updateFirmApplicationData(): void {
    this.firmLicense.licenseType = this.applyLicense ? this.licenseType : 'link';
    for (const controlName in this.firmLicense) {
      if (this.licenseApplicationDataForm.controls.hasOwnProperty(controlName)) {
        this.firmLicense[controlName] = this.licenseApplicationDataForm.controls[controlName].value;        
      } else if (this.licenseApplicationAttestationForm.controls.hasOwnProperty(controlName)) {
        this.firmLicense[controlName] = this.licenseApplicationAttestationForm.controls[controlName].value;
      }
    } 
  }

  changeStep(step: StepperSelectionEvent): void {      
    this.saveApplication();
    if (step.previouslySelectedIndex === 1) {
      var formFirmName = this.licenseApplicationDataForm.get('firmName').value;      
      if(formFirmName !== null && formFirmName !== "" && this.canEdit && this.existingFirmAccountAffiliation == null)   
      {
        this.firmService.getSimilarFirmNames(formFirmName).subscribe(res=>{
          if (res !== null && res.length)
          {
            const dialogRef = this.commonService.openDialog('<strong>Firms with a similar name already exist.</strong>\n\n' + res.map(firm => firm.name).join('\n') + '\n\n<strong>Is your firm listed above?</strong> (clicking yes will take you to the Firm linking application)', 'Firm Confirmation')

            dialogRef.afterClosed().subscribe(result => {
              if (result) {        
                this.changeForm(this.linkHeader);                
              } 
            });
          }
        },err=>{})       
      }
      this.licenseApplicationDataForm.markAllAsTouched();
      this.licenseApplicationDataForm.markAsDirty();      
    }
    if (step.selectedIndex === 3) {
      this.licenseApplicationDataForm.markAsTouched();
      this.licenseApplicationDataForm.markAsDirty();
      this.licenseApplicationAttestationForm.markAsDirty();
    }
    if (step.selectedIndex === 2) {
      this.licenseApplicationDataForm.patchValue({
        identification: false,
        applicantInfo: false
      });
    }
    if (step.previouslySelectedIndex === 3) {
      //this.saveApplication();
    }
    if (step.selectedIndex === 4) {
      this.licenseApplicationDataForm.markAsDirty();
      this.licenseApplicationAttestationForm.markAsDirty();
    }
  }
  
  onApplicationTypeChanged(): void {    
    this.updateIndividualTable();     
    this.individualTable.layout.data = this.assignApplicationPersonsToIData()
  }

  changeLinkStep(step: StepperSelectionEvent): void {   
    if(step.selectedIndex == 2){       
      //this.firmToLinkAndAddress = null;
      if (this.licenseApplicationDataForm.valid && !this.firmToLinkValidated){
        this.innerLoading = true;                
       
        this.firmService.getFirmByName(this.licenseApplicationDataForm.controls['firmName'].value).subscribe(
          firm =>{              
            this.firmToLink = firm;  // Remove once firm linking process is ready
            if(firm){
              this.firmService.getFirmAddresses(firm.id).subscribe(
                address=>{                  
                  this.firmToLinkAndAddress = address[0];                                                       
              },err=>{});
              this.personService.GetAllFirmPOCPeopleContactInformation(firm.id).subscribe(result=>{                
                this.firmPOCs = result;
                this.innerLoading = false;
              },error=>{console.error("There was an error getting firm POCs: ", error)})           
            }
            else
              this.innerLoading = false;
          },
          error => {}
        )
      }
      else{
        this.innerLoading = false;
      }
    }
  }

  validateFirmToLink(): void {
    this.firmToLinkValidated = true;
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  async downloadFile(data: any) {    
    var rawData;
    var fileName;
    var fileType;
        
    var licenseDoc = this.firmLicenseDocuments.filter(x=>x.fileName === data)[0];
    rawData = licenseDoc.document.file;
    fileName = licenseDoc.fileName;
    fileType = licenseDoc.fileType;      
    
    try {
      const file = await this.undoFileSerialization(rawData, fileName, fileType);
  
      if (file) {        
        const fileUrl = URL.createObjectURL(file);
          
        const a = document.createElement('a');
        a.href = fileUrl;
        a.download = file.name; 
        a.style.display = 'none';
  
        document.body.appendChild(a);
        a.click();
          
        document.body.removeChild(a);
        URL.revokeObjectURL(fileUrl);
      } else {
        console.error("File object is null; unable to download.");
      }
    } catch (error) {
      console.error("Error undoing serialization:", error);
    }
  }  

  async undoFileSerialization(serializedData: string, fileName: string, fileType: string): Promise<File> {
    return new Promise<File>((resolve, reject) => {
      try {
        const byteCharacters = atob(serializedData);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: fileType });
        const file = new File([blob], fileName, { type: fileType });
        resolve(file);
      } catch (error) {
        reject(error);
      }
    });
  }

  async getFileSerializedData(file: File): Promise<{ serializedData: string, fileType: string }> {
    return new Promise<{ serializedData: string, fileType: string }>((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = (event: any) => {
        if (event.target) {
          const arrayBuffer = event.target.result;
          if (arrayBuffer instanceof ArrayBuffer) {
            const serializedData = this.base64ArrayBuffer(arrayBuffer);
            resolve({ serializedData, fileType: file.type });
          } else {
            reject(new Error("Failed to read the file."));
          }
        } else {
          reject(new Error("Failed to read the file."));
        }
      };
  
      reader.readAsArrayBuffer(file);
    });
  }  

  base64ArrayBuffer(arrayBuffer: ArrayBuffer): string {
    const byteArray = new Uint8Array(arrayBuffer);
    const byteCharacters = Array.from(byteArray);
    return btoa(byteCharacters.map(charCode => String.fromCharCode(charCode)).join(''));
  }
  
  async uploadApplicantDocument(event: Event): Promise<void> {
    try {
      let dirtyFile = (event.target as HTMLInputElement).files[0];
      let file = new File([dirtyFile], dirtyFile.name.replace(/[^A-Za-z0-9.]/g, '').replace(/.txt$/, ''));
      
      const fileData = await this.getFileSerializedData(dirtyFile);
      
      var docFile: IDocumentFile = {
        id: 0,
        file: fileData.serializedData,
      }

      const applicationDocument: IFirmLicenseDocument = {
        id: 0,
        licenseId: this.firmLicense.id,
        license: this.firmLicense,
        document: docFile,
        fileName: file.name,
        fileType: fileData.fileType,
        licensePersonId: null,
        licensePerson: null
      };
  
      this.saveAppDocument(applicationDocument);
    } catch (error) {
      console.error("An error occurred while processing the file:", error);
    }  
  }

  submitApplication(): void {
    const dialogRef = this.commonService.openDialog('<strong>You are about to submit your Firm Application.</strong>\n\nAre you sure you want to submit your \n<strong>Firm Application</strong> ?', 'Firm Application Submission Confirmation')

    dialogRef.afterClosed().subscribe(result => {
      if (result) {        
        this.showConfirmed=true;
        this.firmLicense.status = 'Submitted'
        this.saveApplication();         
      } 
    });    
  }

  submitLinkApplication(): void {
    if(this.canContinueLicense){
      const dialogRef = this.commonService.openDialog('You have a firm application that is in progress and needs to be cancelled to link this account. Would you like to cancel your pending application?', 'Cancel Confirmation')

      dialogRef.afterClosed().subscribe(result => {
        if (result) {        
          var applicationToCancel = this.firmService.firmLicenses.filter(x => x.status !== APPLICATION_STATUS.cancelled && x.status !== APPLICATION_STATUS.approved)[0]
          applicationToCancel.status = APPLICATION_STATUS.cancelled
          this.firmService.saveFirmLicense(applicationToCancel).subscribe(res=>{
            this.linkAccountToFirm()
          },err=>{})          
        } 
      });
    }
    else{
      this.linkAccountToFirm()
    }
  }

  linkAccountToFirm(): void{
    this.showConfirmed=true;

    this.firmLicense.status = APPLICATION_STATUS.approved    // Remove once firm linking process is ready  (all below this line)
    this.saveApplication(); 
    
    this.newAccountAffiliation.userId = this.firmLicense.userId;
    this.newAccountAffiliation.status = 'Active';
    this.newAccountAffiliation.firm = this.firmToLink;
    
    this.commonService.saveAccountAffiliation(this.newAccountAffiliation).subscribe(
      result => {
        this.commonService.loadAccountAffiliations();
      },
      error => {

      }
    )
  }

  saveApplication(): void {
    if(this.canEdit){
      this.updateFirmApplicationData();    
      this.firmService.saveFirmLicense(this.firmLicense).subscribe(
        result => {
          this.firmLicense.id = result.id        
          this.firmService.firmLicense = this.firmLicense;       
          this.firmService.loadFirmLicenses();
        },
        error => {
          this.toastr.error("Unable to save application");
        }
      )
    }
  }

  continue(): void {    
    this.router.navigate(['/firm'])  
    var headerToUse = this.existingFirmAccountAffiliation ? this.affiliationHeader : this.genericHeader
    this.changeForm(headerToUse);
    this.resetAll();    
  }

  pageTitle() {
    return "Firm Application Submitted!";
  }

  ngOnDestroy(): void {
  }
}
