import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LEAD_BASED_PAINT, getStatusColor } from '@shared/utils/app-static-data';
import { IPayment } from 'app/components/payments/models/payment';
import { IFirm } from 'app/models/firm/firm';
import { IFirmLicense } from 'app/models/firm/firm-license';
import { SharedService } from 'app/services/core/shared.service';

@Component({
  selector: 'app-firm-status-card',
  templateUrl: './firm-status-card.component.html',
  styleUrls: ['./firm-status-card.component.scss']
})
export class FirmStatusCardComponent implements OnInit {
  public uiData = LEAD_BASED_PAINT; 
  public statusColor = getStatusColor;

  applyData:{width:number, cols:number, rows:number, color:string, link:string, icon:string, title:string, description:string[], action:string} = 
    { 
      width:6, cols:2, rows:1, color:this.uiData.firm.color, link:'/firm/apply', icon:this.uiData.firm.icon, title:'Firm',
      description:['Apply for Lead Based Paint Activities','Apply for Renovator, Repair, and Painting Certification','Link your account to an existing Firm\'s Certifications'],
      action:'Apply or Link to a Firm'
    };

  @Input() displayData: {firmLicense:IFirmLicense,payment:IPayment,firmData: IFirm};

  displayFields: {dataName:string,displayName:string,dataType:string}[] = [
    {dataName:'id',displayName:'Firm ID: ',dataType:'string'},
    {dataName:'ccb',displayName:'CCB: ',dataType:'string'},
    {dataName:'ccbExpirationDate',displayName:'CCB Expiration: ',dataType:'date'},
    {dataName:'inactive',displayName:'Firm Status: ',dataType:'string'},
    {dataName:'isPrivate',displayName:'Private Firm: ',dataType:'bool'},
    {dataName:'servicesAvailable',displayName:'Services Avalable: ',dataType:'string'},
  ]

  constructor(public sharedService: SharedService, private router: Router) { }

  ngOnInit(): void {
  }

  applicationClicked(){
    this.router.navigate(['/firm/apply']);
  }
}