import { Inject, Injectable, OnInit } from "@angular/core";
import {
  MsalService,
  MSAL_GUARD_CONFIG,
  MsalGuardConfiguration,
} from "@azure/msal-angular";
import {
  AuthenticationResult,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from "@azure/msal-browser";
import { environment } from "@env/environment";
import { Observable, Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { IAzureAdUser } from "app/models/user-info/azure-ad-user";

@Injectable({
  providedIn: "root",
})

export class AuthService implements OnInit {
  public loading: boolean = false;
  private readonly _destroying$ = new Subject<void>();
  roles: string[] = [];
  userProfile: IAzureAdUser = null;
  private rolesUpdated: Subject<string[]> = new Subject<string[]>();

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
              private authService: MsalService,
              private httpClient: HttpClient) { }

  ngOnInit(): void { 
    if(this.isAuthenticated()){
      this.loadRoles();
    }
  }

  isAuthenticated(): boolean {
    return this.authService.instance.getAllAccounts().length > 0;
  }

  public get username(): string {
    let account = this.authService.instance.getAllAccounts()[0];
    if (account) {
      return this.formatName(account.idTokenClaims["given_name"] as string)
             + " " +
             this.formatName(account.idTokenClaims["family_name"] as string);
    }
    return null;
  }

  formatName(name: string): string{
    return name[0].toUpperCase() + name.slice(1).toLowerCase();
  }

  login(userFlowRequest?: RedirectRequest | PopupRequest) {
    this.loading = true;
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService
          .loginPopup({
            ...this.msalGuardConfig.authRequest,
            ...userFlowRequest,
          } as PopupRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            this.loading = false;
          });
      } else {
        this.authService
          .loginPopup(userFlowRequest)
          .subscribe((response: AuthenticationResult) => {
            this.authService.instance.setActiveAccount(response.account);
            this.loading = false;
          });
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({
          ...this.msalGuardConfig.authRequest,
          ...userFlowRequest,
        } as RedirectRequest);
      } else {
        this.authService.loginRedirect(userFlowRequest);
      }
    }
  }

  logout() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: "/",
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  public get firstName(): string {
    let account = this.authService.instance.getActiveAccount();
    if (account) {
      return this.formatName(account.idTokenClaims["given_name"]);
    }
    return null;
  }

  public get lastName(): string {
    let account = this.authService.instance.getActiveAccount();
    if (account) {
      return this.formatName(account.idTokenClaims["family_name"]);
    }
    return null;
  }

  public get displayName(): string {
    let account = this.authService.instance.getActiveAccount();
    if (account) {
      return this.formatName(account.idTokenClaims["given_name"])
             + " " +
             this.formatName(account.idTokenClaims["family_name"]);
    }
    return null;
  }

  // public get email(): string {
  //   let account = this.authService.instance.getActiveAccount();
  //   console.log(JSON.stringify(account));
  //   if (account) {
  //     if(account.idTokenClaims["emails"] != null && account.idTokenClaims["emails"].length > 0)
  //     {
  //       return this.formatName(account.idTokenClaims["emails"][0]);
  //     }
  //   }
  //   return null;
  // }


  editProfile() {
    let editProfileFlowRequest: PopupRequest = {
      scopes: ["openid"],
      authority: environment.b2c.authority,
      prompt: "login",
    };

    this.login(editProfileFlowRequest);
  }

  loadRoles() {
    this.loading = true;
    this.getRoles().subscribe(
      (response) => { 
        this.roles = response;
        this.rolesUpdated.next(response);
        this.loading = false;
      },
      error => {
        console.log(error);
        this.loading = false;
       }
    );
  }

  

  getRoles(): Observable<string[]>{
    return this.httpClient.get<string[]>(environment.privateApi + "AzureIdentity/roles" );
  }

  loadUserProfile() {
    this.loading = true;
    this.getUserProvife().subscribe(
      (response) => { 
        this.userProfile = response;
        this.loading = false;
      },
      error => {
        console.log(error);
        this.loading = false;
       }
    );
  }

  getUserProvife(): Observable<IAzureAdUser>{
    return this.httpClient.get<IAzureAdUser>(environment.privateApi + "AzureIdentity/GetUserIdentity" );
  }
  
  rolesChanged$ = this.rolesUpdated.asObservable();

  public isAuthorized(roles?: string[]): boolean {
    if (roles != null && roles.length > 0) {
      return (this.isAuthenticated() && this.userHasAtLeastOneRole(roles));
    }
    return this.isAuthenticated();
  }

  public userHasAtLeastOneRole(roles: string[]): boolean {
    if (roles == null || roles.length == 0 || this.roles.length == 0) {
      return false;
    }
    return roles.some(role => this.roles.includes(role));
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }
}
