<div *ngIf="!loading" fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
    <div fxFlex="50.00" fxFlex.lt-sm="100" class="matero-col" fxLayoutAlign="start">
      <h2 class="noMarginHeader" *ngIf="personTrainingData.id > 0">
        <mat-icon>edit</mat-icon> Update Training Entry
      </h2>
      <h2 class="noMarginHeader" *ngIf="personTrainingData.id == 0">
        <mat-icon>add</mat-icon> Add Training Entry
      </h2>
    </div>
    
    <div fxFlex="50.00" fxFlex.lt-sm="100" class="matero-col" fxLayoutAlign="end">
      <button type="submit" (click)="onSubmit()" mat-raised-button color="primary"
        title="{{buttonText}}">{{buttonText}}</button>
      &nbsp;&nbsp;
      <button mat-button mat-dialog-close="Cancel" aria-label="Cancel" mat-raised-button color="basic"
        title="Close">Close</button>
        &nbsp;&nbsp;
    </div>
  </div>
  <mat-dialog-content *ngIf="!loading">
    <form #form="ngForm" [formGroup]="personTrainingForm" class="form-field-full">
      <br />
      <span *ngIf="postErrorMessages != ''" class="error">{{postErrorMessages}}<br /><br /></span>
      <div fxLayout="row wrap" class="matero-row">
        <div fxFlex="100" fxFlex.lt-sm="100" class="matero-col">
          <mat-card fxFlex="100">
            <div fxLayout="row wrap" class="matero-row" fxLayoutGap="8px grid" fxWrap>
              <div fxFlex="33.33%" fxFlex.lt-md="50%" fxFlex.lt-sm="100%" fxLayout="column" *ngFor="let field of objectKeys(formFields)">
                <ng-container [ngSwitch]="formFields[field].type">
                    <mat-form-field fxFlex *ngSwitchCase="'string'">
                      <input matInput [formControlName]="field" [placeholder]="formFields[field].name">
                    </mat-form-field>
                    <mat-form-field fxFlex *ngSwitchCase="'date'">
                        <input matInput [matDatepicker]="picker" [formControlName]="field" [placeholder]="formFields[field].name" (focus)="picker.open()">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>                      
                    <mat-form-field fxFlex *ngSwitchCase="'option'">
                      <mat-select [formControlName]="field" [placeholder]="formFields[field].name" class="matInput">
                        <mat-option *ngFor="let option of formFields[field].data" [value]="option">{{option}}</mat-option>
                      </mat-select>
                    </mat-form-field>
                    <mat-checkbox *ngSwitchCase="'boolean'" [formControlName]="field">{{ formFields[field].name }}</mat-checkbox>
                  </ng-container>
                  
              </div>              
            </div>              
          </mat-card>
        </div>
      </div>
    </form>
  </mat-dialog-content>