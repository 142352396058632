import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatStepper } from '@angular/material/stepper';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { ANIMATIONS, FIRM_SERVICES, LEAD_BASED_PAINT, FIRM_APPLICATION_TYPES, APPLICATION_STATUS, FIRM_SERVICE_FOR } from '@shared/utils/app-static-data';
import { StaffAddEditComponent } from 'app/components/common/staff/add-edit/staff-add-edit.component';
import { PaymentService } from 'app/components/payments/service/payment.service';
import { IAccountAffiliation } from 'app/models/common/account-affiliation';
import { IDocumentFile } from 'app/models/document/document-file';
import { IFirm } from 'app/models/firm/firm';
import { IFirmLicense } from 'app/models/firm/firm-license';
import { IFirmLicenseDeficiency } from 'app/models/firm/firm-license-deficiency';
import { IFirmLicenseDocument } from 'app/models/firm/firm-license-document';
import { IFirmLicensePerson } from 'app/models/firm/firm-license-person';
import { IPersonLicense } from 'app/models/people/person-license';
import { IPersonLicenseDeficiency } from 'app/models/people/person-license-deficiency';
import { IPersonContactInformation } from 'app/models/people/personContactInformation';
import { CommonService } from 'app/services/common/common.service';
import { SharedService } from 'app/services/core/shared.service';
import { FirmService } from 'app/services/firm/firm.service';
import { PersonService } from 'app/services/person/person.service';
import { UserInformationService } from 'app/services/user-info/user-info.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AddEditTrainingComponent } from './add-edit-training/add-edit-training.component';
import { AddEditTestingComponent } from './add-edit-testing/add-edit-testing.component';
import { IPersonTraining } from 'app/models/people/personTraining';

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-individual-license',
  templateUrl: './individual-license.component.html',
  styleUrls: ['./individual-license.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }],
  animations: ANIMATIONS
})
export class IndividualLicenseComponent implements OnInit {  
  public uiData: any = LEAD_BASED_PAINT;  
  public applyHeader: string = "Apply for an Individual Certificate"
  public linkHeader: string = "Link to an existing Individual"
  public genericHeader: string = "Apply for a new Individual Certification or Link to an existing Individual Certification"  
  public affiliationHeader: string = "Apply for a new Individual Certificate or Renew an existing Individual Certificate"
  public currentHeader: string = this.genericHeader

  public loading: boolean = true;
  public canEdit: boolean = true;
  public applicationTypeSelected: boolean = false;
  public personToLinkValidated: boolean = false;
  public showConfirmed: boolean = false;

  public personLicenseDeficiencies: IPersonLicenseDeficiency[] = [];
  public personLicense: IPersonLicense = null; 
  public existingPersonAccountAffiliation: IAccountAffiliation;

  @ViewChild('firmStepper') firmStepper: MatStepper;
  public step: number = 0;

  public licenseApplicationDataForm = null;
  public licenseApplicationAttestationForm = null;

  public trainingTable =
  {
    layout:{
      columns:['codeType', 'stage', 'trainingDate', 'expirationDate'],
      container:[        
        {displayName:'Training Type',columnName:'codeType', type:'string'},
        {displayName:'Training Category',columnName:'stage', type:'string'},                        
        {displayName:'Training Date',columnName:'trainingDate', type:'date'},                        
        {displayName:'Training Expiration Date',columnName:'expirationDate', type:'date'},                        
      ],
      data: []
    }
  };
  public personTrainingEntries: IPersonTraining[];  
  public trainingPageSize: number = 5;

  public testingTable =
  {
    layout:{
      columns:['TestLocation', 'Stage', 'CodeType', 'TestVersion', 'TestDate', 'TestSerial', 'TestScore'],
      container:[        
        {displayName:'Test Location',columnName:'TestLocation', type:'string'},
        {displayName:'Stage',columnName:'Stage', type:'string'}, 
        {displayName:'Test Type',columnName:'CodeType', type:'string'},
        {displayName:'Test Version',columnName:'TestVersion', type:'string'},                       
        {displayName:'Test Date',columnName:'TestDate', type:'date'},                        
        {displayName:'Test Serial',columnName:'TestSerial', type:'string'},
        {displayName:'Test Score',columnName:'TestScore', type:'string'},
      ],
      data: []
    }
  };
  public personTestingEntries: [];  
  public testingPageSize: number = 5;
  
  constructor(
    public sharedService: SharedService,
    public firmService: FirmService,  // CHANGE put the validators required into the common servive
    public commonService: CommonService,
    private personService: PersonService,
    private toastr: ToastrService,
    public userService: UserInformationService, 
    private dialog: MatDialog, 
    private router: Router,
  ) {

  }

  ngOnInit(): void {     
    this.checkIfPersonServiceLoaded()        
  }

  checkIfPersonServiceLoaded(){    
    if(!this.personService.loaded){
      setTimeout(() => {
        this.checkIfPersonServiceLoaded();    
      }, 1500);
    }
    else
      this.loadPersonLicenses(); 
  }

  loadPersonLicenses(){
    this.initializePersonLicense();
    this.loading = false;
  }

  changeForm(selection: string){
    this.currentHeader = selection;
    this.initializeLicenseFormData(selection);
    this.loadLicenseFormData();
    this.applicationTypeSelected = true;
  }

  initializePersonLicense(){
    this.personLicense = this.personService.personLicense ??    
    {
      id: 0,
      status: APPLICATION_STATUS.inProgress,
      userId: '',
      person: null,      
      licenseDate: null,
      isPublic: false,
      ssn: '',
      dateOfBirth: null,
      title: '',
      firstName: '',
      middleName: '',
      lastName: '',
      phone: '',
      alternatePhone: '',
      faxPhone: '',
      email: '',
      confirmEmail: '',
      website: '',
      mailingCity: '',
      mailingCounty: '',
      mailingState: '',
      mailingStreet: '',
      mailingZip: '',
      attestation: false,      
      esignature: '',  
    } 
    
    this.personLicense.userId === '' ? this.setUserId() : undefined;

    this.updateLicenseTrainingList();
  }

  updateLicenseTrainingList(){
    if(this.personLicense.id > 0){
      this.personService.getPersonLicenseTrainings(this.personLicense.id).subscribe(result=>{                
        this.trainingTable.layout.data = result;        
      },error=>{this.toastr.error('Error getting license trainings')})
    }
  }

  initializeLicenseFormData(title: string){    
    if(title === this.applyHeader)
    {
      this.licenseApplicationDataForm = new UntypedFormGroup({
        isPublic: new UntypedFormControl(false), 
        ssn: new UntypedFormControl(""),
        dateOfBirth: new UntypedFormControl(""),
        title: new UntypedFormControl(""),
        firstName: new UntypedFormControl("", [Validators.required]),
        middleName: new UntypedFormControl(""),
        lastName: new UntypedFormControl("", [Validators.required]),  
        phone: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
        alternatePhone: new UntypedFormControl("", [Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
        faxPhone: new UntypedFormControl("", [Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
        email: new UntypedFormControl("", [Validators.required, Validators.email]),
        confirmEmail: new UntypedFormControl("", [Validators.required, Validators.email]),         
        mailingStreet: new UntypedFormControl(""),
        mailingCity: new UntypedFormControl(""),
        mailingState: new UntypedFormControl(""),
        mailingZip: new UntypedFormControl("", [Validators.pattern(/^\d{5}(-\d{4})?$/)]),
        mailingCounty: new UntypedFormControl(""),        
        website: new UntypedFormControl(""),        
      }, {
        validators: [
          this.firmService.emailValidator('email', 'confirmEmail'),          
        ]
      });      
    }
    else{
      // this.licenseApplicationDataForm = new UntypedFormGroup({
      //   firmName: new UntypedFormControl("", [Validators.required]),      
      // });

      // Object.values(this.licenseApplicationDataForm.controls).forEach((control: AbstractControl) => {
      //   control.valueChanges.subscribe(() => {
      //     this.markFirmToLinkDirty();
      //   });
      // });      
    }

    this.licenseApplicationAttestationForm = new UntypedFormGroup({
      attestation: new UntypedFormControl(false, [Validators.requiredTrue]),
      esignature: new UntypedFormControl("", [Validators.required])
    });
  }

  loadLicenseFormData(): void {                
    for (const controlName in this.personLicense) {
      if (this.licenseApplicationDataForm.controls.hasOwnProperty(controlName)) {
        this.licenseApplicationDataForm.controls[controlName].setValue(this.personLicense[controlName]);
        if(!this.canEdit)
          this.licenseApplicationDataForm.controls[controlName].disable(); 
      } else if (this.licenseApplicationAttestationForm.controls.hasOwnProperty(controlName)) {
        this.licenseApplicationAttestationForm.controls[controlName].setValue(this.personLicense[controlName]);
        if(!this.canEdit)
          this.licenseApplicationAttestationForm.controls[controlName].disable(); 
      }
    }      
  }

  markFirmToLinkDirty(): void {
    this.personToLinkValidated = false;
  }

  startPersonLicenseApplication(){
    setTimeout(() => {this.firmStepper.next();},200) 
  }

  exitApplication(isDirty: boolean){
    const dialogRef = this.commonService.openDialog(
      isDirty ? 'You are about to exit this application.\n <strong>Any changes you have made to this page will not be saved.</strong> \n\n Are you sure you want to exit?':
      '<strong>You are about to exit this application.</strong>\n\n Are you sure you want to exit?',
      'Are You Sure?');    

    dialogRef.afterClosed().subscribe(result => {
      if (result) {        
        this.resetAll()              
      } 
    });
  }

  resetAll(){
    this.currentHeader = this.genericHeader;
    this.applicationTypeSelected = false;
  }

  saveApplication(): void {
    if(this.canEdit){      
      this.updatePeopleApplicationData();          
      this.personService.savePersonLicense(this.personLicense).subscribe(
        result => {
          this.personLicense.id = result.id        
          //this.firmService.firmLicense = this.firmLicense;       
          //this.firmService.loadFirmLicenses();
        },
        error => {
          this.toastr.error("Unable to save application");
        }
      )      
      this.licenseApplicationDataForm.markAsPristine();
    }
  }

    updatePeopleApplicationData(): void {
      //this.firmLicense.licenseType = this.applyLicense ? this.licenseType : 'link';
      for (const controlName in this.personLicense) {
        if (this.licenseApplicationDataForm.controls.hasOwnProperty(controlName)) {
          this.personLicense[controlName] = this.licenseApplicationDataForm.controls[controlName].value;        
        } else if (this.licenseApplicationAttestationForm.controls.hasOwnProperty(controlName)) {
          this.personLicense[controlName] = this.licenseApplicationAttestationForm.controls[controlName].value;
        }
      } 
    }
  
    setUserId(): void {
      this.userService.getUserInfo().subscribe(res=>{      
        this.personLicense.userId = res.email;                
      })    
    }

  changeStep(event: any){
    this.step = event.selectedIndex;
    if(event.previouslySelectedIndex == 1 && this.licenseApplicationDataForm.dirty){
      this.saveApplication();      
    }
  }

  editTrainingEntry(event: any, row: any){
    const dialogRef = this.dialog.open(AddEditTrainingComponent, {
      width: '90%',
      data: {personTrainingData: null, personLicense: this.personLicense},
    });
    dialogRef.afterClosed().subscribe(result => {
      this.updateLicenseTrainingList();
    });
  }

  editTestEntry(event: any, row: any){
    const dialogRef = this.dialog.open(AddEditTestingComponent, {
      width: '90%',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      // ++this.loadCount 
      // this.updateApplicationPeople();
    });
  }

  onApplicationTypeChanged(){}
  cancelApplication(){}

  filterTrainingTable(event: any){}
  filterTestTable(event: any){}

  downloadTrainingFile(docName: any){}
  clearTrainingFile(row: any, docName: any){}
  deleteTraining(row: any){    
    this.personService.deletePersonTraining(row.id).subscribe(result=>{
      this.updateLicenseTrainingList();
    },error=>{this.toastr.error("There was an error removing the training")})
  }
  onTrainingFileSelected(event: any, row: any){}

  downloadTestFile(docName: any){}
  clearTestFile(row: any, docName: any){}
  deleteTesting(row: any){}
  onTestFileSelected(event: any, row: any){}

  submitApplication(){
    this.personLicense.status = APPLICATION_STATUS.submitted;
    this.saveApplication();
    this.showConfirmed = true;
    this.toastr.success("Individual Application Submitted");
  }

  continue(): void {    
    this.router.navigate(['/individual/manage'])      
    this.changeForm(this.genericHeader);
    this.resetAll();    
  }
}
