import { Component, OnInit } from '@angular/core';
import { IApplicationInfo } from 'app/models/app-info/app-info';
import { AppService } from 'app/services/app-info/app-info.service';
import { SharedService } from 'app/services/core/shared.service';

@Component({
  selector: 'app-app-info',
  templateUrl: './app-info.component.html',
  styleUrls: ['./app-info.component.scss']
})
export class AppInfoComponent implements OnInit {

  appInfo: IApplicationInfo = {
    name: '',
    version: '',
    environment: '',
    build: '',
    release: ''
  };

  constructor(public sharedService: SharedService, private service: AppService) { }

  ngOnInit(): void {
    this.service.getAppInfo().subscribe(
      (response) => this.appInfo = response,
      (error) => console.log('error', error)
    );
  }

}
