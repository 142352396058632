<!--Header -->
<mat-card class="sticky-header p-y-0" [style.background-color]="uiData.firm.color" *ngIf="!showConfirmed">
  <mat-card-content>  
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
      <mat-icon class="medium-icon m-24">{{uiData.firm.icon}}</mat-icon>
      <span class="f-s-20">{{currentHeader}}</span>
    </div>    
  </mat-card-content>   
</mat-card>
<!--End of Header -->
<mat-card class="sticky-header p-y-0" [style.background-color]="'red'" *ngIf="!showConfirmed">
  <mat-card-content>  
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center start" *ngIf="selectedType && !showConfirmed && !showSubmitted && applyLicense && firmLicenseDeficiencies && firmLicenseDeficiencies.length > 0">
      <ul>
        <li class="f-s-20" style="color: white" *ngFor="let deficiency of firmLicenseDeficiencies">{{ deficiency.description }}</li>
      </ul>
    </div>       
  </mat-card-content>   
</mat-card>


  
  <div fxLayout="column" fxLayoutGap="8px" [ngStyle]="{'--primary-color': '#007bff'}">
    <!-- Application Type -->
    <div fxLayout="column" fxLayoutAlign="start center" *ngIf="!selectedType && !loading">
      <div style="width: 95%" fxLayout="column" fxLayoutGap="8px">
        <span>
          Detailed information regarding Lead Paint Regulations can be found online.
        </span>
        <span>
          Please visit
          <a href="https://www.oregon.gov/oha/PH/HEALTHYENVIRONMENTS/HEALTHYNEIGHBORHOODS/LEADPOISONING/Pages/Lead-BasedPaintRegulations.aspx"
             target="_blank"
             rel="noopener"
             style="word-break: break-all;
                    white-space: normal;">
            https://www.oregon.gov/oha/PH/HEALTHYENVIRONMENTS/HEALTHYNEIGHBORHOODS/LEADPOISONING/Pages/Lead-BasedPaintRegulations.aspx
          </a>
          to access this information.
        </span>
      </div>
            
      <div fxFlex fxLayout="row" fxLayoutGap="8px" style="margin-top: 25px">
        <div fxFlex="50">
          <mat-card fxLayout="column">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <div fxLayout="row" fxLayoutAlign="center">
              <button mat-flat-button type="button" color="primary" (click)="changeForm(applyHeader)">
                {{this.firmLicense ? (this.firmLicense.id !== 0 && !existingFirmAccountAffiliation) ? (this.canEdit ? "Continue certificate application" : 'View certificate application') : "Apply for a new certificate" : "Apply for a new certificate"}}
              </button>
            </div>
          </mat-card>
        </div>
        <div *ngIf="!existingFirmAccountAffiliation" fxFlex="50">
          <mat-card fxLayout="column">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            <div fxLayout="row" fxLayoutAlign="center">
              <button mat-flat-button type="button" color="primary" (click)="changeForm(linkHeader)">
                Link to an existing firm
              </button>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
    <!-- End Application Type -->

































    <mat-stepper [orientation]="sharedService.isMobile? 'vertical' : 'horizontal'" #firmStepper [selectedIndex]="step" *ngIf="selectedType && !showConfirmed && !showSubmitted && !applyLicense"
    (selectionChange)="changeLinkStep($event)">
      <mat-step>
        <ng-template matStepLabel>
          <span>Start Here</span>          
        </ng-template>      
        <div fxLayout="column" fxLayoutGap="8px" class="p-b-8">   
          <div style="display: flex; justify-content: center;">
            <div style="text-align: center;">
              <h2>Link your account to an existing firm</h2>
            </div>
          </div>

          <div fxLayout="column" fxLayoutGap="8px" style="padding-top: 2em">
            <span>
              <strong>When you click the continue button at the bottom of this page, you will proceed to link an existing Firm to your account.</strong>               
            </span>
          </div>
          <div fxLayout="column" fxLayoutGap="8px" style="padding-top: 2em;">
            <span>
              <strong>DISCLAIMER:</strong> The information you provide in your form, is subject to disclosure under Oregon's public records laws.
            </span>
          </div>

          <div fxLayout="row">
            <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="start center" fxLayoutGap="4px">
              <button mat-raised-button matStepperNext color="primary" *ngIf="firmLicense !== null">Continue</button>            
            </div>
          
            <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="end center" fxLayoutGap="4px">
              <button mat-raised-button (click)="exitApplication(false)">Exit</button>
            </div>
          </div> 
        </div>
      </mat-step>



      <form [formGroup]="licenseApplicationDataForm">
      <mat-step>
        <ng-template matStepLabel>
          <span class="text-red-400" *ngIf="!licenseApplicationDataForm.valid && licenseApplicationDataForm.touched">
            Firm Information Incomplete</span>
          <span *ngIf="licenseApplicationDataForm.valid || !licenseApplicationDataForm.touched">Firm Information</span>                   
        </ng-template>      
        <div fxLayout="column" fxLayoutGap="8px" class="p-b-8"> 
          
          <mat-card fxFlex="50" fxFlex.lt-lg="100">
            <mat-card-title>
              <div fxLayout="row" fxLayout.lt-lg="column">
                <span fxFlex>Firm Information</span>
              </div>
            </mat-card-title>
            
            <mat-card-content>              
              <div fxLayout="column" fxLayoutGap="8px">
                <span><strong>Please enter the name of the Firm to link to your account</strong></span>
                <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-lg="">
                  <mat-form-field fxFlex="100%" fxFlex.lt-md="100">
                    <input matInput placeholder="Firm Name" formControlName="firmName" [matAutocomplete]="auto" (ngModelChange)="onFirmNameChanged($event)">
                    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                      <mat-option *ngFor="let firm of filteredFirmNames" [value]="firm.name">
                        {{ firm.name }}
                      </mat-option>
                    </mat-autocomplete>
                    <mat-error *ngIf="licenseApplicationDataForm.get('firmName').invalid">
                      Please enter the Firm Name
                    </mat-error>
                  </mat-form-field>
                                       
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        
          
          <div fxLayout="row">
            <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="start center" fxLayoutGap="4px">
              <button mat-raised-button matStepperPrevious>Back</button>
              <button mat-raised-button matStepperNext color="primary" [disabled]="!isFirmLinkNameValid">Continue</button>
            </div>
          
            <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="end center" fxLayoutGap="4px">
              <button mat-raised-button (click)="exitApplication(false)">Exit</button>
            </div>
          </div>          
          
        </div>
      </mat-step>
    </form>

    <mat-step>
      <ng-template matStepLabel>
        <span class="text-red-400" *ngIf="!firmToLinkValidated">
          Firm Confirmation Incomplete</span>
        <span *ngIf="firmToLinkValidated">Firm Confirmation</span>                   
      </ng-template>      
      <div fxLayout="column" fxLayoutGap="8px" class="p-b-8"> 
        
        <mat-card>
          <mat-card-title>
            <div fxLayout="row" fxLayout.lt-lg="column">
              <span fxFlex>Firm Confirmation</span>
            </div>
          </mat-card-title>
          
          <mat-card-content>              
            <section *ngIf="innerLoading">
              <h2>Loading...</h2>
              <mat-progress-bar mode="indeterminate" color="primary" aria-label="Loading...">
              </mat-progress-bar>
            </section>
            <div *ngIf="!innerLoading">
              <div *ngIf="firmToLinkAndAddress !== null" fxLayout="column" style="display: flex; flex-direction: column;" style="font-size: 18px;">
                
                <p><strong>Firm Name:</strong> {{firmToLinkAndAddress.firm.name}}</p>

                <div fxLayout="row" fxFlex>
                  <div *ngIf="firmPOCs.length > 0" fxFlex="100">
                    <strong>Primary Contact:</strong>
                    <div>Name: {{firmPOCs[0].person.firstName + ' ' + firmPOCs[0].person.lastName}}</div>
                    <div>Email: {{firmPOCs[0].contactInformation.email}}</div>
                  </div>
                </div>

                <div style="display: flex; flex-direction: column;" *ngIf="firmToLinkValidated">
                  <strong>You have already Confirmed the Firm listed above.</strong>                  
                </div>
                <div style="display: flex; flex-direction: column;" *ngIf="!firmToLinkValidated">
                  <div style="padding-top: 15px;">
                    <strong>Is the Firm listed above correct?</strong>
                  </div>
                  <div style="display: flex; flex-direction: row; gap: 4px;">
                    <button mat-raised-button matStepperPrevious>No</button>
                    <button mat-raised-button color="primary" matStepperNext (click)="validateFirmToLink()">Yes</button>
                  </div>
                </div>                                                          
              </div>
              <div *ngIf="firmToLinkAndAddress === null" fxLayout="column">
                <div>
                  <strong>Sorry, No Firm was found that matched the Information provided.  Please return to Firm Information and Try Again.</strong>
                </div>
                <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16">
                  <button mat-raised-button matStepperPrevious>Return to Firm Information</button>            
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
              
        <div fxLayout="row">
          <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="start center" fxLayoutGap="4px">
            <button mat-raised-button matStepperPrevious>Back</button> 
            <button *ngIf="firmToLinkValidated" mat-raised-button matStepperNext color="primary">Continue</button>           
          </div>
        
          <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="end center" fxLayoutGap="4px">
            <button mat-raised-button (click)="exitApplication(false)">Exit</button>
          </div>
        </div>          
        
      </div>
    </mat-step>

      <mat-step>
        <form [formGroup]="licenseApplicationAttestationForm">
        <ng-template matStepLabel>
          <span class="text-red-400" *ngIf="!firmToLinkValidated || !licenseApplicationAttestationForm.valid">
            Review/Submit Incomplete</span>
          <span *ngIf="firmToLinkValidated && licenseApplicationAttestationForm.valid">Review/Submit</span>          
        </ng-template>    

        <div fxLayout="column">
          <mat-card>
            <mat-card-title>
              Review and Submit
            </mat-card-title>
            <mat-card-content>
              <div fxLayout="column" *ngIf="firmToLinkAndAddress !== null">
                <p><strong>Firm Name:</strong> {{firmToLinkAndAddress.firm.name}}</p>

                <div fxLayout="row" fxFlex>

                  <div *ngIf="firmPOCs.length > 0" fxFlex="25">
                    <strong>Primary Contact:</strong>
                    <div>Name: {{firmPOCs[0].person.firstName + ' ' + firmPOCs[0].person.lastName}}</div>
                    <div>Email: {{firmPOCs[0].contactInformation.email}}</div>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>

          <mat-card fxFlex="50" fxFlex.lt-lg="100">
            <mat-card-title>
              Signature and Acknowledgments
            </mat-card-title>
            <mat-card-content>
              <div fxLayout="column" fxLayoutGap="16px">
                <div fxLayout="column" fxLayoutGap="16px" class="p-t-24">
                  <span class="p-l-24" *ngIf="licenseType === firmApplicationTypes.lbpa">
                        <strong>• </strong>I attest that I am an officer, legal owner or authorized agent of the above listed firm.
                  </span>
                  <span class="p-l-24" *ngIf="licenseType === firmApplicationTypes.lbpa">
                    <strong>• </strong>An email will be sent to the current primary contact for the firm. Once the primary contact has granted approval
                    your account will have access to the firm. If you belive that the current primary contact for the firm is invalid or out of date, please contact
                    leadprogram@odhsoha.oregon.gov.
                </span>

                  <mat-checkbox [checked]="licenseApplicationAttestationForm.get('attestation').value" formControlName="attestation">
                    <strong>I understand and agree with the information provided.</strong>
                  </mat-checkbox>
                </div>

                <div fxLayout="column" fxLayoutGap="8px">
                  <span><strong>Name or Initials</strong></span>
                  <div fxLayout="row">
                    <mat-form-field fxFlex="60" fxFlex.lt-lg="100">
                      <input matInput placeholder="Signature" formControlName="esignature">
                    </mat-form-field>
                  </div>

                  <div fxLayout="row">
                    <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="start center" fxLayoutGap="4px">
                      <button mat-raised-button matStepperPrevious>Back</button>
                      <button mat-raised-button color="primary" type="button" (click)="submitLinkApplication()" [disabled]="!licenseApplicationAttestationForm.valid || !licenseApplicationDataForm.valid || !firmToLinkValidated">Submit Application</button>
                    </div>
                  
                    <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="end center" fxLayoutGap="4px">
                      <button mat-raised-button (click)="exitApplication(false)">Exit</button>
                    </div>
                  </div>  
                  <span class="text-red-400" *ngIf="!licenseApplicationDataForm.valid">Firm Information is incomplete</span>
                  <span class="text-red-400" *ngIf="!firmToLinkValidated">Firm Confirmation is incomplete</span>
                  <span class="text-red-400" *ngIf="!licenseApplicationAttestationForm.valid">Understanding and Signature is incomplete</span>
                </div>                  
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </form>
      </mat-step>
    </mat-stepper>
































    <mat-stepper [orientation]="sharedService.isMobile? 'vertical' : 'horizontal'" #firmStepper [selectedIndex]="step" *ngIf="selectedType && !showConfirmed && !showSubmitted && applyLicense"
      (selectionChange)="changeStep($event)">
      <!-- Start here section -->
      <mat-step>
        <ng-template matStepLabel>
          <span>Start Here</span>          
        </ng-template>      
        <div fxLayout="column" fxLayoutGap="8px" class="p-b-8">          
          <div style="display: flex; justify-content: center;">
            <div style="text-align: center;">
              <h2>Select a Firm Certificate Application Type</h2>
            </div>
          </div>
          
          <div style="display: flex; justify-content: center;">
            <div>
              <mat-radio-group
                id="application-type"
                fxLayout="row"
                [disabled]="!canEdit"
                fxLayout.lt-lg="column"
                class="radio-group"
                style="font-family: Oswald, sans-serif; font-size: large; justify-content: center;"
                [(ngModel)]="licenseType"
                aria-labelledby="application-type"
                fxLayoutGap="8px"   
                (ngModelChange)="onApplicationTypeChanged()"                                            
              >
                <mat-card [style.background]="uiData.lbpa.color">
                  <mat-radio-button class="radio-button" [value]="entityTypes[0]">
                    <mat-icon alt="LBPA icon">{{uiData.lbpa.icon}}</mat-icon>
                    Lead-Based Paint Activities (LBPA)
                  </mat-radio-button>
                </mat-card>
                <mat-card [style.background]="uiData.rrp.color">
                  <mat-radio-button class="radio-button" [value]="entityTypes[1]">
                    <mat-icon alt="RRP icon">{{uiData.rrp.icon}}</mat-icon>
                    Renovation Repair and Painting (RRP)
                  </mat-radio-button>
                </mat-card>
              </mat-radio-group>
            </div>
          </div>
          
          <br>
          <div fxLayout="column" fxLayoutGap="8px">
            <span>
              More detailed information regarding {{licenseType === firmApplicationTypes.lbpa ? 'Lead Based Paint Activities' : 'Renovation, Repair, and Painting'}} can be found online
            </span>
            <span *ngIf="licenseType === firmApplicationTypes.lbpa">
              Please visit <a href="https://www.oregon.gov/oha/PH/HEALTHYENVIRONMENTS/HEALTHYNEIGHBORHOODS/LEADPOISONING/INSPECTIONABATEMENTPROFESSIONALS/Pages/index.aspx#eparegs"
              target="_blank" rel="noreferrer noopener"
              style="word-break: break-all;
              white-space: normal;">https://www.oregon.gov/oha/PH/HEALTHYENVIRONMENTS/HEALTHYNEIGHBORHOODS/LEADPOISONING/INSPECTIONABATEMENTPROFESSIONALS/Pages/index.aspx#eparegs</a>
              to access this information.
            </span>
            <span *ngIf="licenseType === firmApplicationTypes.rrp">
                Please visit <a href="https://www.oregon.gov/oha/PH/HEALTHYENVIRONMENTS/HEALTHYNEIGHBORHOODS/LEADPOISONING/CONTRACTORSPAINTERS/Pages/index.aspx"
                target="_blank" rel="noreferrer noopener"
                style="word-break: break-all;
                white-space: normal;">https://www.oregon.gov/oha/PH/HEALTHYENVIRONMENTS/HEALTHYNEIGHBORHOODS/LEADPOISONING/CONTRACTORSPAINTERS/Pages/index.aspx.</a>
              </span>
          </div>
        
          <div fxLayout="row" fxLayout.lt-lg="column" style="padding-top: 1em" fxLayoutGap="12px">              
          </div>
          <div fxLayout="column" fxLayoutGap="8px" style="padding-top: 1em">
            <strong>The completed application packet contains the following::</strong>
            <span class="p-l-24">
              <strong>• </strong>Completed and signed Lead-Based Paint Activities application              
            </span>
            <span class="p-l-24">
                <strong>• </strong>{{licenseType === firmApplicationTypes.lbpa ? 'Non-refundable three-year certification application fee: $255' : 'Non-refundable fiver-year certification application fee: $250'}}
            </span>
            <span class="p-l-24" *ngIf="licenseType === firmApplicationTypes.rrp">
                <strong>• </strong>Digital copy of current renovation training certificate for each Certified Renovator.
            </span>
          </div>
          <div fxLayout="row" style="padding-top: 2em">
            <div fxLayout="column" fxLayoutGap="8px">
              <strong>Important Notes: </strong>
              <span class="p-l-24">
                <strong>• </strong>Please review each section of the application to ensure it is complete
              </span>
              <span class="p-l-24">
                <strong>• </strong>A fee page will describe when and how to submit the application fee which may be paid
                online or in-person
              </span>
              <span class="p-l-24">
                <strong>• Incomplete and Insufficient Application Statuses:</strong> Please review the application guide
                for more detailed information on all application statuses.
              </span>
              <div fxLayout="column" style="padding-left: 1em;" fxLayoutGap="8px">
                <span class="p-l-24">
                  <strong>• </strong>Incomplete: An application is considered incomplete if the application is missing
                  required information (e.g., the applicant did not submit fingerprints).
                  If the application is incomplete, we will notify you by email to give you an opportunity to make your
                  application complete. You have 90 days from the initial date you are notified to complete your
                  application. If you fail to do so, the application will be closed.
                </span>
                <span class="p-l-24">
                  <strong>• </strong>Insufficient: An application is considered insufficient if the complete application
                  or site inspection does not meet requirements outlined in statute or rule. If the application is
                  insufficient,
                  we will notify you by email to give you an opportunity to meet requirements. You will have 90 days from
                  the initial date you are notified to meet requirements, or your application will be denied.
                </span>
              </div>
            </div>
          </div>
        
          <div fxLayout="column" fxLayoutGap="8px" style="padding-top: 2em">
            <span>
              <strong>When you click the continue button at the bottom of this page, you will proceed to the Lead-Based Paint Activities application.</strong> 
              The application automatically saves. You may exit the application and come back later to complete and submit the application, if needed.
            </span>
          </div>
          <div fxLayout="column" fxLayoutGap="8px" style="padding-top: 2em;">
            <span>
              <strong>DISCLAIMER:</strong> The information you provide in your application
              materials, including applicant name, is subject to disclosure under Oregon's public records laws.
            </span>
          </div>

          <div fxLayout="row">
            <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="start center" fxLayoutGap="4px">
              <button mat-raised-button matStepperNext color="primary" *ngIf="firmLicense.id !== 0">Continue</button>
              <button mat-raised-button type="button" color="primary" *ngIf="firmLicense.id === 0" (click)="startFirmLicenseApplication()">Start Application</button>
            </div>
          
            <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="end center" fxLayoutGap="4px">
              <button mat-raised-button color="warn" *ngIf="firmLicense.id !== 0" (click)="cancelApplication()">{{firmLicense.status !== "Submitted" ? "Cancel Application" : "Withdraw Application"}}</button>
              <button mat-raised-button (click)="exitApplication(false)">Exit</button>
            </div>
          </div> 
        </div>
    </mat-step>
      <!-- End of Start here section -->
  
      <!-- Start of Personal Information -->      
      <mat-step [stepControl]="licenseApplicationDataForm" *ngIf="firmLicense !== null">        
          <ng-template matStepLabel>
            <span class="text-red-400" *ngIf="!licenseApplicationDataForm.valid && licenseApplicationDataForm.touched">
              Firm Information Incomplete</span>
            <span *ngIf="licenseApplicationDataForm.valid || !licenseApplicationDataForm.touched">Firm Information</span>
            
  
          </ng-template>
          <div fxLayout="column">
            <form [formGroup]="licenseApplicationDataForm">
            <div fxLayout="column">
                <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                  <mat-card fxFlex="50" fxFlex.lt-lg="100">
                    <mat-card-title>
                      <div fxLayout="row" fxLayout.lt-lg="column">
                        <span fxFlex>Firm Information</span>
                      </div>
                    </mat-card-title>
                    <mat-card-content>
                      <div fxLayout="column" fxLayoutGap="8px">
                        <span><strong>Firm Name</strong></span>
                        <span>(Legal, active name registered with the Oregon Secretary of State - Corporation Division, unless otherwise exempted from their regulations. 
                            Examples include legal names registered as sole proprietor, general partnership, LLC, business corporation, non-profit corporation, etc.)</span>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-lg="">
                          <mat-form-field fxFlex="100%" fxFlex.lt-md="100">
                            <input matInput placeholder="Name" formControlName="firmName" (ngModelChange)="onFirmNameChanged($event)">
                            <mat-error *ngIf="licenseApplicationDataForm.get('firmName').invalid">
                              Please enter your Firm Name
                            </mat-error>
                          </mat-form-field>                      
                        </div>
            
                        <span><strong>{{licenseType === firmApplicationTypes.lbpa ? 'CCB License #' : 'Property Management License #'}}</strong></span>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-lg="">
                          <mat-form-field fxFlex="100%" fxFlex.lt-md="100">
                            <input matInput placeholder="{{licenseType === firmApplicationTypes.lbpa ? 'CCB License #' : 'Property Management License #'}}" formControlName="ccb">
                          </mat-form-field>
                        </div>
            
                        <div *ngIf="existingFirmAccountAffiliation" fxLayout="column">
                            <span class="f-2-20"><strong>Renewal</strong></span>
                            <mat-checkbox class="mat-checkbox" formControlName="renewal">
                              <span class="mat-checkbox-label">Check this box if this application is a renewal.</span>
                            </mat-checkbox>
                            <div fxLayout="column" class="m-t-8" *ngIf="this.licenseApplicationDataForm.get('renewal').value">
                              <span>Please provide the license id that you are applying to renew.</span>
                              <mat-form-field>
                                <input matInput formControlName="previousLicense">
                                <mat-placeholder style="color:red" *ngIf="licenseApplicationDataForm.get('previousLicense').value == ''">Affiliated License#*</mat-placeholder>
                                <mat-placeholder style="color:grey" *ngIf="licenseApplicationDataForm.get('previousLicense').value != ''">Affiliated License#</mat-placeholder>
                              </mat-form-field>
                            </div>
                          </div>
                          
                      </div>
                    </mat-card-content>
                  </mat-card>
            
                  <mat-card>
                    <mat-card-title>Firm Address</mat-card-title>
                    <mat-card-content>
                      <div fxLayout="column">
                        <span><strong>Physical Address</strong></span>
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                          <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                            <input matInput placeholder="Street Apt/Unit/Suite" formControlName="firmPhysicalStreet">
                            <mat-error *ngIf="licenseApplicationDataForm.get('firmPhysicalStreet').invalid">
                              Please provide a street
                            </mat-error>
                          </mat-form-field>
            
                          <mat-form-field fxFlex="25" fxFlex.lt-md="100">
                            <input matInput placeholder="City" formControlName="firmPhysicalCity">
                            <mat-error *ngIf="licenseApplicationDataForm.get('firmPhysicalCity').invalid">
                              Please provide a city
                            </mat-error>
                          </mat-form-field>
            
                          <mat-form-field fxFlex="10" fxFlex.lt-md="100">
                            <mat-label style="color:red" *ngIf="licenseApplicationDataForm.get('firmPhysicalState').value == ''">State</mat-label>
                            <mat-label *ngIf="licenseApplicationDataForm.get('firmPhysicalState').value != ''">State</mat-label>
                            <mat-select formControlName="firmPhysicalState">
                              <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                            </mat-select>
                            <mat-error *ngIf="licenseApplicationDataForm.get('firmPhysicalState').invalid">
                              Please select a state
                            </mat-error>
                          </mat-form-field>
            
                          <mat-form-field fxFlex="15" fxFlex.lt-md="100">
                            <input matInput placeholder="Zip" formControlName="firmPhysicalZip">
                            <mat-error *ngIf="licenseApplicationDataForm.get('firmPhysicalZip').hasError('required')">
                              Please provide a zipcode
                            </mat-error>
                            <mat-error *ngIf="licenseApplicationDataForm.get('firmPhysicalZip').hasError('pattern')">
                              Zipcode should match this format: 99999 or 99999-1234
                            </mat-error>
                          </mat-form-field>
            
                          <mat-form-field fxFlex="20" fxFlex.lt-md="100" *ngIf="licenseApplicationDataForm.get('firmPhysicalState').value === 'OR'">
                            <mat-label>County</mat-label>
                            <mat-select formControlName="firmPhysicalCounty">
                              <mat-option>-- None --</mat-option>
                              <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}</mat-option>
                            </mat-select>
                          </mat-form-field>
                        </div>
                      </div>
            
                      <div fxLayout="column" class="m-t-8">
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="24px">
                          <span><strong>Mailing Address</strong></span>
                          <mat-checkbox formControlName="mailingIsPhysical" (change)="mailingIsSame()">
                            Same as physical
                          </mat-checkbox>
                        </div>
                        <div fxLayout="column" [@slideRight] *ngIf="!licenseApplicationDataForm.get('mailingIsPhysical').value">
                          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                            <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                              <input matInput placeholder="Street Apt/Unit/Suite" formControlName="firmMailingStreet"
                                (blur)="firmService.validateFieldRequired(licenseApplicationDataForm,'firmMailingStreet')">
                              <mat-error *ngIf="firmService.validateFieldRequired(licenseApplicationDataForm,'firmMailingStreet')">
                                Please provide a street
                              </mat-error>
                            </mat-form-field>
            
                            <mat-form-field fxFlex="25" fxFlex.lt-md="100">
                              <input matInput placeholder="City" formControlName="firmMailingCity" (blur)="firmService.validateFieldRequired(licenseApplicationDataForm,'firmMailingCity')">
                              <mat-error *ngIf="firmService.validateFieldRequired(licenseApplicationDataForm,'firmMailingCity')">
                                Please provide a city
                              </mat-error>
                            </mat-form-field>
            
                            <mat-form-field fxFlex="10" fxFlex.lt-md="100">
                              <mat-label>State</mat-label>
                              <mat-select formControlName="firmMailingState" (blur)="firmService.validateFieldRequired(licenseApplicationDataForm,'firmMailingState')">
                                <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                              </mat-select>
                              <mat-error *ngIf="firmService.validateFieldRequired(licenseApplicationDataForm,'firmMailingState')">
                                Please provide a state
                              </mat-error>
                            </mat-form-field>
            
                            <mat-form-field fxFlex="15" fxFlex.lt-md="100">
                                <input matInput placeholder="Zip" formControlName="firmMailingZip" (blur)="firmService.validateFieldRequired(licenseApplicationDataForm,'firmMailingZip')">
                                <mat-error *ngIf="firmService.validateFieldRequired(licenseApplicationDataForm,'firmMailingZip')">
                                  Please provide a zipcode
                                </mat-error>
                                <mat-error *ngIf="licenseApplicationDataForm.get('firmMailingZip').hasError('pattern')">
                                  Zipcode should match this format: 99999 or 99999-1234
                                </mat-error>
                              </mat-form-field>
            
                              <mat-form-field fxFlex="20" fxFlex.lt-md="100"
                                *ngIf="licenseApplicationDataForm.get('firmMailingState').value === 'OR'">
                                <mat-label>County</mat-label>
                                <mat-select formControlName="firmMailingCounty">
                                  <mat-option>-- None --</mat-option>
                                  <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}
                                  </mat-option>
                                </mat-select>
                              </mat-form-field>
                          </div>
                        </div>
                      </div>
          
                    </mat-card-content>
                  </mat-card>
            
                 
                </div>
                <mat-card fxFlex="50" fxFlex.lt-lg="100" *ngIf="licenseType === firmApplicationTypes.lbpa">
                    <mat-card-title>
                      Firm Services
                    </mat-card-title>
                    <mat-card-content>
                      <div fxLayout="column" fxLayoutGap="8px">
            
            
                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                          <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                            <span class="f-2-20"><strong>My Firm provides the following services</strong></span>
                            <div fxLayout="column">
                              <ng-container *ngFor="let service of firmServices | keyvalue">
                                <mat-checkbox [formControlName]="service.key">
                                  <span class="mat-checkbox-label">{{ service.value }}</span>
                                </mat-checkbox>
                              </ng-container>
                            </div>  
                          </div>
                          <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                            <span class="f-2-20"><strong>My Firm Provides Services For</strong></span>
                            <mat-form-field>
                                <mat-label>Select an option</mat-label>
                                <mat-select formControlName="firmServices">
                                  <mat-option *ngFor="let type of getServiceForTypes()" [value]="type">
                                    {{ type }}
                                  </mat-option>
                                </mat-select>
                            </mat-form-field>
                          </div>
                          <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                            <span class="f-2-20"><strong>Public Firm</strong></span>
                            <mat-checkbox class="mat-checkbox" formControlName="isPublic">
                              <span class="mat-checkbox-label">
                                Check this box if you want your firm listed on our Web site as a lead-based paint activities firm.
                              </span>
                            </mat-checkbox>
                          </div>                          
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
            
                <mat-card fxFlex="50" fxFlex.lt-lg="100">
                    <mat-card-title>
                      Firm Contact Information
                    </mat-card-title>
                    <mat-card-content>
                      <div fxLayout="column" fxLayoutGap="8px">                        
                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                          <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                            <span class="f-2-20"><strong>Primary Phone</strong></span>
                            <mat-form-field>
                              <input matInput placeholder="Phone" formControlName="firmPhone" mask="(000) 000-0000 000000">
                                <!-- [validation]="false" -->
                              <mat-error *ngIf="licenseApplicationDataForm.get('firmPhone').hasError('required')">
                                A primary phone number is required
                              </mat-error>
                              <mat-error *ngIf="licenseApplicationDataForm.get('firmPhone').hasError('pattern')">
                                Phone should match this format: 999-999-9999 x1234
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                            <span class="f-2-20"><strong>Alternate Phone</strong></span>
                            <mat-form-field>
                              <input matInput placeholder="Alt Phone" formControlName="firmAlternatePhone">
                                <!-- mask="(000) 000-0000 000000" [validation]="false" -->
                              <mat-error *ngIf="licenseApplicationDataForm.get('firmAlternatePhone').hasError('pattern')">
                                Phone should match this format: 999-999-9999 x1234
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                            <span class="f-2-20"><strong>Fax Phone</strong></span>
                            <mat-form-field>
                              <input matInput placeholder="Fax Phone" formControlName="firmFaxPhone">
                                <!-- mask="(000) 000-0000 000000" [validation]="false" -->
                              <mat-error *ngIf="licenseApplicationDataForm.get('firmFaxPhone').hasError('pattern')">
                                Phone should match this format: 999-999-9999 x1234
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
            
                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                          <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                            <span class="f-2-20"><strong>Primary Email Address</strong></span>
                            <mat-form-field>
                              <input matInput placeholder="Email" formControlName="firmEmail">
                              <mat-error *ngIf="licenseApplicationDataForm.get('firmEmail').hasError('required')">
                                Please enter an email adress
                              </mat-error>
                              <mat-error *ngIf="licenseApplicationDataForm.get('firmEmail').hasError('firmEmail')">
                                This doesn't look like a valid email address
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                            <span class="f-2-20"><strong>Confirm Email Address</strong></span>
                            <mat-form-field>
                              <input matInput placeholder="Confirm Email" formControlName="firmConfirmEmail">
                              <mat-error *ngIf="firmService.validateEmail('firmEmail',licenseApplicationDataForm)">
                                Email does not match
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
            
                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                          <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                            <span class="f-2-18"><strong>Website</strong></span>
                            <mat-form-field>
                              <input matInput placeholder="Website" formControlName="firmWebsite">
                            </mat-form-field>
                          </div>
                        </div>
            
                    
                      </div>
                    </mat-card-content>
                  </mat-card>
            
            
                <mat-card fxFlex="50" fxFlex.lt-lg="100">
                    <mat-card-title>
                      {{licenseType === firmApplicationTypes.lbpa ? 'Contact Information (Officer, Owner, or Authorized Agent)' : 'Contact Person'}}
                    </mat-card-title>
                    <mat-card-content>
                      <div fxLayout="column" fxLayoutGap="8px">
                        <span><strong>Legal Name</strong></span>
                            
                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-lg="">
                          <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                            <input matInput placeholder="First" formControlName="contactFirstName">
                            <mat-error *ngIf="licenseApplicationDataForm.get('contactFirstName').invalid">
                              Please enter your first name
                            </mat-error>
                          </mat-form-field>
            
                          <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                            <input matInput placeholder="Last" formControlName="contactLastName">
                            <mat-error *ngIf="licenseApplicationDataForm.get('contactLastName').invalid">
                              Please enter your last name
                            </mat-error>
                          </mat-form-field>
            
                          <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                            <input matInput placeholder="Middle" formControlName="contactMiddleName">
                          </mat-form-field>
            
                          <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                            <input matInput placeholder="Title" formControlName="contactTitle">
                          </mat-form-field>
                        </div>
            
                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                          <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                            <span class="f-2-20"><strong>Primary Phone</strong></span>
                            <mat-form-field>
                              <input matInput placeholder="Phone" formControlName="contactPhone" mask="(000) 000-0000 000000">
                                <!-- [validation]="false" -->
                              <mat-error *ngIf="licenseApplicationDataForm.get('contactPhone').hasError('required')">
                                A primary phone number is required
                              </mat-error>
                              <mat-error *ngIf="licenseApplicationDataForm.get('contactPhone').hasError('pattern')">
                                Phone should match this format: 999-999-9999 x1234
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                            <span class="f-2-20"><strong>Alternate Phone</strong></span>
                            <mat-form-field>
                              <input matInput placeholder="Alt Phone" formControlName="contactAlternatePhone">
                                <!-- mask="(000) 000-0000 000000" [validation]="false" -->
                              <mat-error *ngIf="licenseApplicationDataForm.get('contactAlternatePhone').hasError('pattern')">
                                Phone should match this format: 999-999-9999 x1234
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                            <span class="f-2-20"><strong>Fax Phone</strong></span>
                            <mat-form-field>
                              <input matInput placeholder="Fax Phone" formControlName="contactFaxPhone">
                                <!-- mask="(000) 000-0000 000000" [validation]="false" -->
                              <mat-error *ngIf="licenseApplicationDataForm.get('contactFaxPhone').hasError('pattern')">
                                Phone should match this format: 999-999-9999 x1234
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>
            
                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                          <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                            <span class="f-2-20"><strong>Primary Email Address</strong></span>
                            <mat-form-field>
                              <input matInput placeholder="Email" formControlName="contactEmail">
                              <mat-error *ngIf="licenseApplicationDataForm.get('contactEmail').hasError('required')">
                                Please enter an email adress
                              </mat-error>
                              <mat-error *ngIf="licenseApplicationDataForm.get('contactEmail').hasError('email')">
                                This doesn't look like a valid email address
                              </mat-error>
                            </mat-form-field>
                          </div>
                          <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                            <span class="f-2-20"><strong>Confirm Email Address</strong></span>
                            <mat-form-field>
                              <input matInput placeholder="Confirm Email" formControlName="contactConfirmEmail">
                              <mat-error *ngIf="firmService.validateEmail('contactEmail',licenseApplicationDataForm)">
                                Email does not match
                              </mat-error>
                            </mat-form-field>
                          </div>
                        </div>                      
                      </div>
                    </mat-card-content>
                  </mat-card>
               
              </div>
            </form>
            
            <div fxLayout="row">
              <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="start center" fxLayoutGap="4px">
                <button mat-raised-button matStepperPrevious>Back</button>
                <button mat-raised-button color="{{canEdit ? 'primary' : ''}}" matStepperNext>{{canEdit ? "Save and Continue" : "Continue"}}</button>
              </div>

              <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="end center" fxLayoutGap="4px">
                <button mat-raised-button color="warn" *ngIf="firmLicense.id !== 0" (click)="cancelApplication()">{{firmLicense.status !== "Submitted" ? "Cancel Application" : "Withdraw Application"}}</button>
                <button mat-raised-button (click)="exitApplication(true)">Exit</button>
              </div>
            </div> 
          </div>
      </mat-step>
      <!-- End of Personal Information -->
  
        <!-- Start of Certified Individuals -->
        <mat-step *ngIf="firmLicense !== null">
            <ng-template matStepLabel>
              <span>{{licenseType === firmApplicationTypes.lbpa ? 'Certified Individuals' : 'Certified Renovators'}}</span>
            </ng-template>
            <div fxLayout="column">
              <div fxLayout="column" fxLayoutGap="8px" fxFlex="100" fxFlex.lt-lg="100">
                  <span *ngIf="sharedService.tablet" class="f-s-18">{{licenseType === firmApplicationTypes.lbpa ? 'Certified Individuals' : 'Certified Renovators'}}</span>
              
                  <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                    <div fxFlex="50" fxFlex.lt-sm="100" class="matero-col" fxLayoutAlign="start">
                      <h2 class="noMarginHeader">
                        {{licenseType === firmApplicationTypes.lbpa ? 'Certified Individuals' : 'Certified Renovators'}}
                      </h2>
                    </div>
                  </div>                    
                  <ul>
                    <li>Add your firm's certified individuals if any.</li>
                    <li>A training certificate and a passport style photo is required for each individual.</li>
                    <li>Fees PLACEHOLDER TEXT</li>
                  </ul>
                  <br />
                  <mat-card>
                    <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;" fxLayout="column">
                        <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                            <div>
                                <strong class="noMarginHeader">Results ({{individualTable.layout.data.length}})</strong> 
                                &nbsp;&nbsp;&nbsp;&nbsp;     
                                <mat-form-field style="width:200px;">
                                <input (keyup)="filterTable($event)" placeholder="Filter Results" matInput />
                                <input placeholder="Filter Courses" matInput />
                                </mat-form-field>      
                            </div>
                        <button mat-raised-button color="primary" *ngIf="canEdit" (click)="editIndividual($event,null)">
                            <mat-icon>add</mat-icon> {{licenseType === firmApplicationTypes.lbpa ? 'Certified Individuals' : 'Certified Renovators'}}
                        </button>      
                    </div>
                    <br />
              
                    <div [fxFlex]="100" style="overflow-x: auto;">
                      <mat-table [dataSource]="individualTable.layout.data" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                        <mat-header-row *matHeaderRowDef="individualTable.layout.columns.concat('Documents','Actions')"></mat-header-row>
                        <mat-row [ngClass]="sharedService.isMobile ? 'mobile-row':''" *matRowDef="let row; index as i; columns: individualTable.layout.columns.concat('Documents','Actions');"
                        (click)="editIndividual($event,firmLicensePersons[i])">
                        </mat-row>
                    
                        <ng-container *ngFor="let cellData of individualTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                          <mat-header-cell *matHeaderCellDef>{{cellData.displayName}}</mat-header-cell>
                          <mat-cell *matCellDef="let row">
                            <ng-container *ngIf="cellData.type === 'date'">
                              {{row[cellData.columnName] | date:'shortDate'}}
                            </ng-container>
                            <ng-container *ngIf="cellData.type === 'boolean'">
                              <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="!canEdit" (click)="onCoverFee(row)"></mat-checkbox>
                            </ng-container>
                            <ng-container *ngIf="cellData.type !== 'date' && cellData.type !== 'boolean'">
                              {{row[cellData.columnName]}}
                            </ng-container>
                          </mat-cell>
                        </ng-container>                                           
                    
                        <ng-container matColumnDef="Documents">
                          <mat-header-cell *matHeaderCellDef fxFlex.lt-sm="100" >Documents</mat-header-cell>
                          <mat-cell *matCellDef="let row" fxFlex.lt-sm="100" fxLayout="column" class="left-align">
                            <div class="button-container" *ngFor="let documentName of row['Documents']">
                              <div (click)="$event.stopPropagation()">
                                <button mat-icon-button class="stop-propagation" color="primary" aria-label="Download File" (click)="downloadFile(documentName)">
                                  <mat-icon>save_alt</mat-icon>
                                </button>                              
                                <span>{{documentName | fileDisplayName:20}}</span>                              
                                <button *ngIf="canEdit" mat-icon-button class="stop-propagation" color="warn" aria-label="Delete" (click)="clearFile(row,documentName)">
                                  <mat-icon>delete</mat-icon>
                                </button> 
                              </div>
                            </div>
                          </mat-cell>
                        </ng-container>
                        
                        <ng-container matColumnDef="Actions">
                          <mat-header-cell *matHeaderCellDef fxFlex="12">Actions</mat-header-cell>
                          <mat-cell *matCellDef="let row; let i = index" fxFlex="12">
                            <div (click)="$event.stopPropagation()">
                              <button [disabled]="!canEdit" mat-raised-button color="primary" class="stop-propagation" aria-label="Add Document" (click)="fileUpload.click()">
                                Attach <mat-icon>attach_file</mat-icon>
                              </button>
                            </div>
                            <div (click)="$event.stopPropagation()">
                              <button mat-icon-button color="warn" class="stop-propagation" aria-label="Delete" *ngIf="canEdit" (click)="deleteIndividual(row)">
                                <mat-icon>delete</mat-icon>
                              </button>
                            </div>
                            <!-- The class for the input html prevents the click propagation.  editInvidual() is still called on the parent element but the class is checked to prevent propagation-->
                            <input type="file" #fileUpload style="display:none" class="stop-propagation" (change)="onFileSelected($event, row)"> 
                          </mat-cell>
                        </ng-container>
                      </mat-table>
                    </div>                    
                                      
                      <br />
                      <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 50, individualTable.layout.data.length]" #TablePaginator="matPaginator"
                        showFirstLastButtons></mat-paginator>
                    </div>
                  </mat-card>
              
                </div>
                
                <div fxLayout="row">
                  <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="start center" fxLayoutGap="4px">
                    <button mat-raised-button matStepperPrevious>Back</button>
                    <button mat-raised-button matStepperNext color="primary">Continue</button>
                  </div>
                
                  <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="end center" fxLayoutGap="4px">
                    <button mat-raised-button color="warn" *ngIf="firmLicense.id !== 0" (click)="cancelApplication()">{{firmLicense.status !== "Submitted" ? "Cancel Application" : "Withdraw Application"}}</button>
                    <button mat-raised-button (click)="exitApplication(false)">Exit</button>
                  </div>
                </div>
              </div>
          </mat-step>

      <!-- Start of Documents -->      
      <!-- <mat-step [stepControl]="licenseApplicationDataForm" *ngIf="firmLicense !== null">        
          <ng-template matStepLabel>
            <span>Application Documents</span>
          </ng-template>
          <div fxLayout="column">
            <form [formGroup]="licenseApplicationDataForm">
                <div fxLayout="column" fxLayoutGap="8px">                    
                  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">

                    <div fxLayout="column" fxLayoutGap="8px" fxFlex="35">
                      <span class="f-s-20" style="font-weight: bold;">Upload Document</span>
                      <div fxLayout="column" fxLayoutGap="8px" class="p-t-8">
                        <input type="file" class="file-input" (change)="uploadApplicantDocument($event)" #fileUpload style="display:none;"/>
                        <div fxLayout="row" fxLayoutGap="8px">
                          <div class="file-upload">                            
                            <button *ngIf="canEdit" mat-raised-button color="primary" class="upload-btn" (click)="fileUpload.click()">
                              Browse
                              <mat-icon>attach_file</mat-icon>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div fxLayout="column" fxLayoutGap="8px" fxFlex.lt-md="100">
                      <span class="f-s-20" style="font-weight: bold;">Current Documents</span>                      
                      <div fxLayout="column">
                        <div fxLayout="column" fxLayoutGap="8px" *ngFor="let document of firmLicenseDocuments">
                          <div fxLayout="row" fxLayoutAlign="start center" *ngIf="!document.licensePersonId">
                            <button mat-icon-button class="m-8" color="primary" aria-label="Download File" (click)="downloadFile(document)">
                              <mat-icon>save_alt</mat-icon>
                            </button>
                            <span>{{document.fileName | fileDisplayName:20}}</span>
                            <button *ngIf="canEdit" mat-icon-button class="m-8" color="warn" aria-label="Delete" (click)="clearFile(document)">
                              <mat-icon>delete</mat-icon>
                            </button>                          
                          </div>
                        </div>
                      </div>
                    </div> 

                  </div>
                </div>
              </form>
              <div fxLayout="row">
                <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="start center" fxLayoutGap="4px">
                  <button mat-raised-button matStepperPrevious>Back</button>
                  <button mat-raised-button matStepperNext color="primary">Continue</button>
                </div>
              
                <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="end center" fxLayoutGap="4px">
                  <button mat-raised-button color="warn" *ngIf="firmLicense.id !== 0" (click)="cancelApplication()">{{firmLicense.status !== "Submitted" ? "Cancel Application" : "Withdraw Application"}}</button>
                  <button mat-raised-button (click)="exitApplication(false)">Exit</button>
                </div>
              </div>
            </div>
      </mat-step> -->
      <!-- End of Documents -->
  
      <!-- Start of Fees-->
      <mat-step *ngIf="firmLicense !== null">
        <ng-template matStepLabel>
          <span>Fee Information</span>
        </ng-template>
        <div fxLayout="column">
          <div fxLayout="column" fxLayoutGap="8px" fxFlex="50" fxFlex.lt-lg="100">
              <span class="f-s-18">Fees</span>
      
              <app-payment-notice [canPay]="false" [entityType]="paymentService.FirmEntityType" [feeType]="licenseType"></app-payment-notice>
              
          </div>

          <div fxLayout="row">
            <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="start center" fxLayoutGap="4px">
              <button mat-raised-button matStepperPrevious>Back</button>
              <button mat-raised-button matStepperNext color="primary">Continue</button>
            </div>
          
            <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="end center" fxLayoutGap="4px">
              <button mat-raised-button color="warn" *ngIf="firmLicense.id !== 0" (click)="cancelApplication()">{{firmLicense.status !== "Submitted" ? "Cancel Application" : "Withdraw Application"}}</button>
              <button mat-raised-button (click)="exitApplication(false)">Exit</button>
            </div>
          </div>
      </div>
      </mat-step>
      <!-- End of Fees-->
  
      <!-- Start of Review-->
      <!-- *ngIf="workerLicenses.length >= 1" -->
      <mat-step [stepControl]="licenseApplicationAttestationForm" *ngIf="firmLicense !== null">        
          <ng-template matStepLabel>Review and Submit</ng-template>
          <form [formGroup]="licenseApplicationAttestationForm">
            <mat-card>
              <mat-card-title>
                <span>Review Your Application</span>
              </mat-card-title>
              <mat-card-content>
                <div fxLayout="column">
                  <div>
                    <mat-card fxFlex="100" fxFlex.lt-lg="100">
                      <mat-card-title>
                        Certificate Type
                      </mat-card-title>
                      <mat-card-content>
                        <div fxLayout="column" fxLayoutGap="8px">
                          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                            <span fxFlex.lt-lg=""><strong>Selection:</strong></span>
                              <span>{{licenseType === firmApplicationTypes.lbpa ? 'Lead-Based Paint Activities' : 'Renovation Repair and Painting'}}</span>
                          </div>
                          
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                  <div fxLayout="row">                    
                    <mat-card fxFlex="100">
                      <mat-card-title>
                        Firm Information
                      </mat-card-title>
                      <mat-card-content>
                        <div fxLayout="column" fxLayoutGap="8px">
                          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                            <span fxFlex.lt-lg=""><strong>Firm Name:</strong></span>
                              <span>{{firmLicense.firmName}}</span>
                          </div>
                          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                            <span fxFlex.lt-lg=""><strong>CCB#:</strong></span>
                              <span>{{firmLicense.ccb}}</span>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card> 
                    <mat-card fxFlex="100">
                      <mat-card-title>
                        Firm Address
                      </mat-card-title>
                      <mat-card-content>
                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                          <span fxFlex="100" fxFlex.lt-lg=""><strong>Physical Address</strong>
                            <div fxLayout="row" fxLayoutGap="4px">
                              <span>{{firmLicense.firmPhysicalStreet}},</span>
                              <span>{{firmLicense.firmPhysicalCity}},</span>
                              <span>{{firmLicense.firmPhysicalState}}</span>
                            </div>
                            <div fxLayout="row" fxLayoutGap="4px">
                              <span>{{firmLicense.firmPhysicalZip}}</span>
                              <span>{{firmLicense.firmPhysicalCounty}}</span>
                              <span *ngIf="firmLicense.firmPhysicalCounty">County</span>
                            </div>
                          </span>
                        </div>
                        
                        <div style="margin-top: 8px;" fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px" *ngIf="!firmLicense.mailingIsPhysical">
                          <span fxFlex="100" fxFlex.lt-lg=""><strong>Mailing Address</strong>
                            <div fxLayout="row" fxLayoutGap="4px">
                              <span>{{firmLicense.firmMailingStreet}},</span>
                              <span>{{firmLicense.firmMailingCity}},</span>
                              <span>{{firmLicense.firmMailingState}}</span>
                            </div>
                            <div fxLayout="row" fxLayoutGap="4px">
                              <span>{{firmLicense.firmMailingZip}}</span>
                              <span>{{firmLicense.firmMailingCounty}}</span>
                              <span *ngIf="firmLicense.firmMailingCounty">County</span>
                            </div>
                          </span>
                        </div>                          
                        <div style="margin-top: 8px;" fxLayout="column" fxLayout.lt-lg="column" fxLayoutGap="8px" *ngIf="firmLicense.mailingIsPhysical">
                          <span fxFlex="100" fxFlex.lt-lg=""><strong>Mailing Address</strong></span>
                          <div fxLayout="row" fxLayoutGap="4px">
                            <span>Same As Physical</span>         
                          </div>                 
                        </div>
                      </mat-card-content>
                    </mat-card> 
                    <mat-card fxFlex="100" *ngIf="licenseType === firmApplicationTypes.lbpa">
                      <mat-card-title>
                        Firm Services
                      </mat-card-title>
                      <mat-card-content>
                        <div fxLayout="column" fxLayoutGap="8px">
                          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                            <span fxFlex.lt-lg=""><strong>Firm Provides:</strong>

                              <div *ngFor="let service of firmServices | keyvalue">
                                <div *ngIf="firmLicense[service.key]">{{ service.value }},</div>
                              </div>
                            </span>
                          </div>
                          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                            <span fxFlex.lt-lg=""><strong>Firm Provides Services For:</strong></span>
                              <span>{{firmLicense.firmServices}}</span>
                          </div>
                          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                            <span fxFlex.lt-lg=""><strong>Public Firm:</strong></span>
                            <mat-checkbox [disabled]="true" [checked]="firmLicense.isPublic"></mat-checkbox>                              
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>                                        
                  </div>
                  <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                    <mat-card fxFlex="50" fxFlex.lt-lg="100">
                      <mat-card-title>
                        Firm Contact Information
                      </mat-card-title>
                      <mat-card-content>
                        <div fxLayout="column" fxLayoutGap="8px">
                          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">                            
                            <div fxLayout="column">
                              <div fxLayout="row" fxLayoutGap="8px">
                                <span><strong>Email:</strong></span>
                                <span>{{firmLicense.firmEmail}}</span>
                              </div>
                              <div fxLayout="row" fxLayoutGap="8px">
                                <span><strong>Website:</strong></span>
                                <span>{{firmLicense.firmWebsite}}</span>
                              </div>
                              <div style="margin-top: 8px;" fxLayout="row" fxLayoutGap="8px">
                                <span><strong>Primary Phone:</strong></span>                                 
                                <span>{{firmLicense.firmPhone}}</span>
                              </div>                              
                              <div fxLayout="row" fxLayoutGap="8px">
                                <span><strong>Alternate Phone:</strong></span>                              
                                <span>{{firmLicense.firmAlternatePhone}}</span>
                              </div>
                              <div fxLayout="row" fxLayoutGap="8px">
                                <span><strong>Fax Phone:</strong></span>                              
                                <span>{{firmLicense.firmFaxPhone}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
        
                    <mat-card fxFlex="50" fxFlex.lt-lg="100">
                      <mat-card-title>
                        Contact Information (Officer, Owner, or Authorized Agent)
                      </mat-card-title>
                      <mat-card-content>
                        <div fxLayout="column" fxLayoutGap="8px">
                          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                            <span><strong>Name:</strong></span>
                            <span>{{firmLicense.contactTitle}} {{firmLicense.contactFirstName}} {{firmLicense.contactMiddleName}} {{firmLicense.contactLastName}}</span>                            
                          </div>

                          <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">                            
                            <div fxLayout="column">
                              <div fxLayout="row" fxLayoutGap="8px">
                                <span><strong>Email:</strong></span>
                                <span>{{firmLicense.firmEmail}}</span>
                              </div>
                              <div style="margin-top: 8px;" fxLayout="row" fxLayoutGap="8px">
                                <span><strong>Primary Phone:</strong></span>                                 
                                <span>{{firmLicense.contactPhone}}</span>
                              </div>                              
                              <div fxLayout="row" fxLayoutGap="8px">
                                <span><strong>Alternate Phone:</strong></span>                              
                                <span>{{firmLicense.contactAlternatePhone}}</span>
                              </div>
                              <div fxLayout="row" fxLayoutGap="8px">
                                <span><strong>Fax Phone:</strong></span>                              
                                <span>{{firmLicense.contactFaxPhone}}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                  <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                    <mat-card fxFlex="100" fxFlex.lt-lg="100">
                      <mat-card-title>
                        Certified Individuals and Documents
                      </mat-card-title>
                      <mat-card-content>
                        <div fxLayout="column">
                          <div [fxFlex]="100" style="overflow-x: auto;">
                            <mat-table [dataSource]="individualTable.layout.data" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                              <mat-header-row *matHeaderRowDef="individualTable.layout.columns.concat('Documents')"></mat-header-row>
                              <mat-row *matRowDef="let row; index as i; columns: individualTable.layout.columns.concat('Documents');"></mat-row>
                          
                              <ng-container *ngFor="let cellData of individualTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                <mat-header-cell *matHeaderCellDef>{{cellData.displayName}}</mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                  <ng-container *ngIf="cellData.type === 'date'">
                                    {{row[cellData.columnName] | date:'shortDate'}}
                                  </ng-container>
                                  <ng-container *ngIf="cellData.type === 'boolean'">
                                    <mat-checkbox [checked]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                  </ng-container>
                                  <ng-container *ngIf="cellData.type !== 'date' && cellData.type !== 'boolean'">
                                    {{row[cellData.columnName]}}
                                  </ng-container>
                                </mat-cell>
                              </ng-container>                                           
                          
                              <ng-container matColumnDef="Documents">
                                <mat-header-cell *matHeaderCellDef fxFlex.lt-sm="100" >Documents</mat-header-cell>
                                <mat-cell *matCellDef="let row" fxFlex.lt-sm="100" fxLayout="column" class="left-align">
                                  <div class="button-container" *ngFor="let documentName of row['Documents']">
                                    <div (click)="$event.stopPropagation()">
                                      <button mat-icon-button class="stop-propagation" color="primary" aria-label="Download File" (click)="downloadFile(documentName)">
                                        <mat-icon>save_alt</mat-icon>
                                      </button>                              
                                      <span>{{documentName | fileDisplayName:20}}</span>                              
                                    </div>
                                  </div>
                                </mat-cell>
                              </ng-container>                                                        
                            </mat-table>
                          </div>   
                          <mat-paginator [pageSizeOptions]="[5, 10, 20]" #documentPaginator="matPaginator"></mat-paginator>
                        </div>
                      </mat-card-content>                        
                    </mat-card>
                  </div>
                  <div fxLayout="row">
                    <mat-card fxFlex="50" fxFlex.lt-lg="100">
                      <mat-card-title>
                        Signature and Acknowledgments
                      </mat-card-title>
                      <mat-card-content>
                        <div fxLayout="column" fxLayoutGap="16px">
                          <div fxLayout="column" fxLayoutGap="16px" class="p-t-24">
                            <span class="p-l-24" *ngIf="licenseType === firmApplicationTypes.lbpa">
                                  <strong>• </strong>I attest that I am an officer, legal owner or authorized agent of the above listed firm and that the firm will 
                                  employ only appropriately certified employees to conduct lead-based paint activities, and that the firm and its 
                                  employees will follow the work practice standards set forth in OAR 333-069 for conducting lead-based paint 
                                  activities. 
                            </span>
                            <span class="p-l-24" *ngIf="licenseType === firmApplicationTypes.lbpa">
                              <strong>• </strong>I certify that I have read and shall comply with ORS 431A.355, 431A.358, 431A.363 and the provisions therein, 
                              and that the information and documentation given in this application is complete and accurate to the best of my 
                              knowledge
                          </span>
                            <span class="p-l-24" *ngIf="licenseType !== firmApplicationTypes.lbpa">
                              <strong>• </strong>I certify that I have read and shall comply with ORS 431A.350, ORS 431A.358, ORS 431A.363, OAR 
                              333-070, and that the information and documentation given in this application is complete and
                              accurate to the best of my knowledge
                            </span>
        
                            <mat-checkbox [checked]="licenseApplicationAttestationForm.get('attestation').value" formControlName="attestation">
                              <strong>I understand the application fee is non-refundable.</strong>
                            </mat-checkbox>
                          </div>
        
                          <div fxLayout="column" fxLayoutGap="8px">
                            <span><strong>Name or Initials</strong></span>
                            <div fxLayout="row">
                              <mat-form-field fxFlex="60" fxFlex.lt-lg="100">
                                <input matInput placeholder="Signature" formControlName="esignature">
                              </mat-form-field>
                            </div>

                            <div fxLayout="row">
                              <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="start center" fxLayoutGap="4px">
                                <button mat-raised-button matStepperPrevious>Back</button>
                                <button mat-raised-button color="primary" *ngIf="canEdit" type="button" (click)="submitApplication()" [disabled]="!(licenseApplicationAttestationForm.valid && licenseApplicationDataForm.valid)">Submit Application</button>
                              </div>
                            
                              <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="end center" fxLayoutGap="4px">
                                <button mat-raised-button color="warn" *ngIf="firmLicense.id !== 0" (click)="cancelApplication()">{{firmLicense.status !== "Submitted" ? "Cancel Application" : "Withdraw Application"}}</button>
                                <button mat-raised-button (click)="exitApplication(true)">Exit</button>
                              </div>
                            </div>
                          </div>
                          <span class="text-red-400" *ngIf="!licenseApplicationDataForm.valid">Name and address is incomplete</span>
                          <!-- ngIf="workerLicense.applicants[0].applicantDocuments.length < 1" -->
                          <span class="text-red-400" *>You need to upload proof of identity</span>
                          <span class="text-red-400" *ngIf="!licenseApplicationAttestationForm.valid">You need to complete the signature and acknowledgments section</span>
                        </div>
                      </mat-card-content>
                    </mat-card>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </form>
      </mat-step>
      <!-- End of Review-->
    </mat-stepper>
  </div>
  
  <!-- After Submit Confirmation-->
  <div *ngIf="showConfirmed">
    <mat-card class="sticky-header p-y-0" [style.background]="bgColor">
      <mat-card-content>
        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
          <mat-icon class="medium-icon m-24">badge</mat-icon>
          <span class="f-s-20" fxFlex>{{pageTitle()}}</span>
        </div>
      </mat-card-content>
    </mat-card>
  
    <mat-card>
      <mat-card-content *ngIf="currentHeader === applyHeader">
        <span class="f-s-16">Thank you for submitting the application. You can review your application status
          and make updates as needed. Please be sure to monitor your email for follow-up communication from the Oregon Lead Based Paint Program. <br /><br />
          Your application will not be processed until payment is made.  Please see payment instructions below.<br /><br />
          </span>
          <span>
            <app-payment-notice [canPay]="true" [entityType]="paymentService.FirmEntityType" [feeType]="licenseType"></app-payment-notice>
          </span>
      </mat-card-content>
      <mat-card-content *ngIf="currentHeader === linkHeader">
        <span class="f-s-16">Thank you for submitting the application. An approval email was sent to INSERT PRIMARY CONTACT for the firm INSERT FIRM NAME. If the primary contact listed can not be reached or your request is extremely urgent you can contact the Oregon Lead Based Paint Program at <strong>leadprogram@odhsoha.oregon.gov</strong>. Please be sure to monitor your email for follow-up communications.
          </span>
      </mat-card-content>
    </mat-card>
    
    <mat-card>
    
      <mat-card-content>
        <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center center">
          <!-- <app-payment-notice [canPay]="false" [entityType]="paymentService.WorkerPermitPaymentOption"
            [feeType]="paymentService.applicationFeeType" [id]="workerLicense.id"></app-payment-notice> -->
    
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
            <button mat-raised-button type="button" color="primary" (click)="continue()">Continue</button>
          </div>
        </div>
      </mat-card-content>
    </mat-card>
  </div>

  <!-- After Submit Lists-->
  <div *ngIf="showSubmitted">
    <div fxLayout="column" fxLayoutGap="8px">
      Placeholder
    </div>
  </div>
  
  <div *ngIf="loading">
    <div [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
      <h1>Loading</h1>
    </div>
  </div>
  