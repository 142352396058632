import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { FirmService } from 'app/services/firm/firm.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-firm-credential-list',
  templateUrl: './firm-credential-list.component.html',
  styleUrls: ['./firm-credential-list.component.scss']
})
export class FirmCredentialListComponent implements OnInit {
  processing: boolean = false;
  loading: boolean = true;
  pageSize = 50;  

  firmCredentials: any[] = [];

  uiData: any = LEAD_BASED_PAINT.credential;

  displayedColumns: string[] = ['firmLeadCredentialType'];//,'actions'];
  dataSource = new MatTableDataSource<any>(this.firmCredentials);
  @ViewChild('TablePaginator') paginator: MatPaginator;
  @ViewChild('TableSort') sort: MatSort;

  filterTable(event: any) {
    //this.dataSource.filter = event.target.value.trim().toLowerCase();
  }

  constructor(public dialog: MatDialog, 
              private firmService: FirmService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.loadFirms();
  }

  loadFirms() {
    this.firmCredentials = this.firmService.firmCredentials;
    this.dataSource = new MatTableDataSource<any>(this.firmCredentials);    
    this.loading = false;
 
  }

}
