import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-edit-testing',
  templateUrl: './add-edit-testing.component.html',
  styleUrls: ['./add-edit-testing.component.scss']
})
export class AddEditTestingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
