import { NgModule } from "@angular/core";
import { SharedModule } from "@shared/shared.module";
import { RoutesRoutingModule } from "./routes-routing.module";

import { HomeComponent } from "./components/home/home.component";
import { UserInfoComponent } from "./components/core/user-info/user-info.component";
import { AppInfoComponent } from "./components/core/app-info/app-info.component";
import { PaymentsModule } from "./components/payments/payment.module";

const COMPONENTS = [HomeComponent, UserInfoComponent, AppInfoComponent];
const COMPONENTS_DYNAMIC = [];

@NgModule({
    imports: [SharedModule, RoutesRoutingModule, PaymentsModule],
    declarations: [...COMPONENTS, ...COMPONENTS_DYNAMIC]
})
export class RoutesModule {}
