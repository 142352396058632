<mat-card [style.background]="cardColor">
    <mat-card-content>
      <div fxLayout="column" fxLayoutGap="16px">
        <h2>Non-Refundable Fee:
          <span *ngIf="entityType != null && entityType.length > 0">
            ${{paymentService.getPaymentTypeAmount(entityType, feeType)}}
          </span>
        </h2>

        <span>
          {{paymentService.getPaymentTypeNotice(entityType, feeType)}}
        </span>

        <span>Payment method options:</span>
        <ul>
          <li><span *ngIf="!canPay">Online</span> <span *ngIf="canPay"><a (click)="pay()" >Pay online </a></span></li>
          <li>Money Order</li>
          <li>Cashier's Check</li>
          <li>Personal/Business Check (personal and business checks are held for 21 days for clearance)</li>
          <li>Cash* <strong>Please Do Not Mail Cash</strong></li>
        </ul>

        <div fxLayout="row" fxLayoutAlign="center start">
          <span><strong>Instructions for Submitting the Application and Fee Payment by Mail or In-Person</strong></span>
        </div>

        <span>
          Money orders and checks are payable to: <strong>???????</strong>
          Money orders and cashier's checks take less time to clear than personal and business checks.
          Personal and business checks are held for 21 days for clearance. Applications will be reviewed
          once payment has been cleared. Application fees are non-refundable.
        </span>

        <div fxLayout="column">
          <span>
            <strong>Mailing the application packet, money order or check, and remittance form:</strong>
          </span>
          <span>Oregon Health Authority - Public Health Division - Fiscal Services</span>
          <span>?????</span>
          <span>?????</span>
          <span>Portland, OR 97293-0260</span>
        </div>

        <div fxLayout="column">
          <span><strong>In-person payment with a money order or check:</strong></span>
          <span>
            To make an in-person fee payment with a money order or check, print the remittance form and proceed to
            Cashier's Office on the second floor of the Portland State Office Building at 800 NE Oregon St, Suite 200,
            Portland, OR 97232 - Hours: 9am to 4pm, Mon to Fri
          </span>
        </div>

        <span>
          Additional instructions: The Cashier's Office window is located on the second floor next to the Vital Health Statistics window.
          If security asks if you have an appointment, you may let them know you are dropping off a payment for Oregon Psilocybin Services
          at the Cashier's Office window which does not require an appointment. Please include the remittance form with the payment.
        </span>

        <div fxLayout="column">
          <span><strong>To make cash payment arrangements or for payment questions, please contact:</strong></span>
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center" style="word-break: break-all; white-space: normal;">
            <a href="mailto:??????? ">
              <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap="8px" fxLayoutAlign="start center" fxLayoutAlign.lt-sm="start start">
                <span>
                  ??????? </span>
                <mat-icon>email</mat-icon>
              </div>
            </a>
            <span>or call ???????</span>
          </div>
        </div>

        <div fxLayout="column" fxFlex="15" fxFlex.lt-lg="" fxLayoutGap="8px" *ngIf="canPay">
          <div>
            <button *ngIf="!sharedService.mobile" mat-raised-button color="primary" type="button" (click)="viewRemittanceSlip()">View Remittance Slip</button>
          </div>
          <div>
            <button mat-stroked-button type='button' (click)="pay()" color="primary" *ngIf="canPay">Pay Online Now</button>
          </div>
          <span *ngIf="sharedService.mobile"><strong>Remittance Slip Not Available on Mobile</strong></span>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

