<mat-card
  fxFlex="30"
  fxFlex.lt-sm="100"
  [ngClass]="{
    'bg-gray-700': sharedService.darkMode,
    'bg-indigo-50': !sharedService.darkMode
  }"
>
  <mat-card-title>
    Application Information
  </mat-card-title>
  <mat-card-content>
    <div fxLayout="column" fxLayoutGap="8px">
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start end" >
        <span style="font-size: larger">Name</span>
        <span fxFlex></span>
        <span>{{appInfo.name}}</span>
      </div>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start end" >
        <span style="font-size: larger">Version</span>
        <span fxFlex></span>
        <span>{{appInfo.version}}</span>
      </div>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start end" >
        <span style="font-size: larger">Environment</span>
        <span fxFlex></span>
        <span>{{appInfo.environment}}</span>
      </div>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start end" >
        <span style="font-size: larger">Build</span>
        <span fxFlex></span>
        <span>{{appInfo.build}}</span>
      </div>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start end" >
        <span style="font-size: larger">Release</span>
        <span fxFlex></span>
        <span>{{appInfo.release}}</span>
      </div>
    </div>
  </mat-card-content>
</mat-card>
