<section *ngIf="loading">
    <h2>Loading...</h2>
    <mat-progress-bar mode="indeterminate" color="primary" aria-label="Loading...">
    </mat-progress-bar>
  </section>
  

    <br />
  <mat-card>
    <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
        <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
            <div>
                <strong class="noMarginHeader">Results ({{staff.length}})</strong> 
                &nbsp;&nbsp;&nbsp;&nbsp;     
                <mat-form-field style="width:200px;">
                <input (keyup)="filterTable($event)" placeholder="Filter Staff" matInput />
                <input placeholder="Filter Staff" matInput />
                </mat-form-field>      
            </div>
            <button mat-raised-button color="primary" title="Add Staff">
              <mat-icon>add</mat-icon> Add Staff
          </button>       
    </div>
    <br />
    <mat-table [dataSource]="dataSource" matSort #TableSort="matSort" class="mat-elevation-z8"
    style="overflow-x: auto;">
    <mat-row>Results ({{staff.length}})</mat-row>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

    <ng-container matColumnDef="CertificateNumber">
      <mat-header-cell *matHeaderCellDef mat-sort-header  fxFlex.lt-sm="100">Certificate Number</mat-header-cell>
      <mat-cell *matCellDef="let result"  fxFlex.lt-sm="100">
        <span>{{result.CertificateNumber}}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Name">
      <mat-header-cell *matHeaderCellDef mat-sort-header  fxFlex.lt-sm="100">Name</mat-header-cell>
      <mat-cell *matCellDef="let result"  fxFlex.lt-sm="100">
        <span>{{result.Name}}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="Discipline">
      <mat-header-cell *matHeaderCellDef mat-sort-header  fxFlex.lt-sm="100">Discipline</mat-header-cell>
      <mat-cell *matCellDef="let result"  fxFlex.lt-sm="100">
        <span>{{result.Discipline}}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="ExpirationDate">
      <mat-header-cell *matHeaderCellDef mat-sort-header  fxFlex.lt-sm="100">Expiration Date</mat-header-cell>
      <mat-cell *matCellDef="let result"  fxFlex.lt-sm="100">
        <span>{{result.ExpirationDate | date:'shortDate'}}</span>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef fxFlex="7" fxFlex.lt-sm="100" style="justify-content: center !important;">
        Actions</mat-header-cell>
      <mat-cell *matCellDef="let result" fxFlex="7" fxFlex.lt-sm="100" style="justify-content: center !important;">
        <a href="javascript:void(0)" title="Delete {{result.ControlNumber}}">
          <mat-icon>delete</mat-icon>
        </a>
      </mat-cell>
    </ng-container>

  </mat-table>
      <br />
      <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 50, staff.length]" #TablePaginator="matPaginator"
        showFirstLastButtons></mat-paginator>
    </div>
  </mat-card>