import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { IFirmLicense } from 'app/models/firm/firm-license';
import { IPerson } from 'app/models/people/person';
import { IPersonLicense } from 'app/models/people/person-license';
import { IPersonTraining } from 'app/models/people/personTraining';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class PersonService {
  private api: string = environment.privateApi;
  private date = new Date();
  public loaded: boolean = false;
  public personLicense : IPersonLicense;
  public personLicenses: IPersonLicense[];
  
  constructor(private toastr: ToastrService,
    private http?: HttpClient) 
    { 
      this.loadPersonLicenses()    
    }

    loadPersonData(person: IPerson): Observable<any> {      
      //Load person data to be displayed both on status card and manager
      return;
    }

  GetAllFirmPOCPeopleContactInformation(firmId: number): Observable<any> {    
    return this.http.get<any>(this.api + 'Person/GetAllFirmPOCPeopleContactInformation/' + firmId, httpOptions);
  }

  savePersonLicense(application: IPersonLicense): Observable<IPersonLicense> {
    application.licenseDate = application.licenseDate ?? this.date.toISOString();
    return this.http.post<IPersonLicense>(this.api + 'Person/SavePersonLicense', application, httpOptions);
  }

  savePersonTraining(personTraining: IPersonTraining): Observable<IPersonTraining> {    
    return this.http.post<IPersonTraining>(this.api + 'Person/SavePersonTraining', personTraining, httpOptions);
  }

  getPersonLicenseTrainings(licenseId: number): Observable<IPersonTraining[]> {    
    return this.http.get<IPersonTraining[]>(this.api + 'Person/GetPersonTrainingsByLicenseId/' + licenseId, { withCredentials: true });
  }

  getUserPersonLicenses(): Observable<IPersonLicense[]> {    
    return this.http.get<IPersonLicense[]>(this.api + 'Person/GetPersonLicenses/', { withCredentials: true });
  }

  deletePersonTraining(id: number): Observable<any> {    
    return this.http.get(this.api + 'Person/DeletePersonTraining/' + id, httpOptions);
  }

  loadPersonLicenses(){
    this.personLicenses = null;
    this.personLicense = null;
    this.loaded = false;

    this.getUserPersonLicenses().subscribe(
      res=>{               
        this.personLicenses = res
        this.loaded = true;

        if (this.personLicenses.length){          
          var result = this.personLicenses.filter(x=>x.status !== 'Cancelled' && x.status !== 'Approved' && x.person === null);          
          this.personLicense = result.length ? result[0] : null;                     
        }         
      },
      err=>{
        this.loaded = true;
        console.log(err);
        this.toastr.error("Unable to get user applications");            
      }
    )
  }
}
