import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ValidatorFn, FormGroup, ValidationErrors } from '@angular/forms';
import { environment } from '@env/environment';
import { IFirm } from 'app/models/firm/firm';
import { IFirmAddress } from 'app/models/firm/firm-address';
import { IFirmLicense } from 'app/models/firm/firm-license';
import { IFirmLicenseDeficiency } from 'app/models/firm/firm-license-deficiency';
import { IFirmLicenseDocument } from 'app/models/firm/firm-license-document';
import { IFirmLicensePerson } from 'app/models/firm/firm-license-person';
import { IPersonContactInformation } from 'app/models/people/personContactInformation';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError, switchMap, tap } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})

export class FirmService {
  private api: string = environment.privateApi;
  actionType: Array<String>[] = [];
  errorMessages = [];

  //userFirm: IFirm;
  firmCertificates: any[];
  firmCredentials: any[];
  //firmStaff: any[];
  //firmAddress: any;
  //firmContact: any;  

  userId: string = null;
  //applications: IFirmLicense[];  
  
  affiliatedFirm: IFirm;
  firmLicenses: IFirmLicense[];
  firmLicense: IFirmLicense = null;  
  loaded: boolean = false;

  constructor(private toastr: ToastrService, private http?: HttpClient) {
    this.loadFirmLicenses()    
  }

  loadFirmData(firm: IFirm): Observable<any> {
    this.affiliatedFirm = firm;
    return this.getFirmCertificates(firm.id).pipe(
      switchMap(certificates => {
        this.firmCertificates = certificates;
        return this.getFirmCredentials(firm.id).pipe(
          tap(credentials => {
            this.firmCredentials = credentials;
          }),
          map(() => certificates)
        );
      }),
    );
  }   

  getFirmContactInformation(firmId: number): Observable<any> {
    return this.http.get<any>(this.api + 'Firm/GetFirmContactInformation/' + firmId, httpOptions);
  }

  getFirmAddresses(firmId: number): Observable<any> {
    return this.http.get<any>(this.api + 'Firm/GetFirmAddresses/' + firmId, httpOptions);
  }

  saveFirmAddress(firmAddress: IFirmAddress): Observable<IFirmAddress> {          
    return this.http.post<IFirmAddress>(this.api + 'Firm/SaveFirmAddress', firmAddress, httpOptions);
  }

  getFirm(id: number): Observable<IFirm> {
    return this.http.get<IFirm>(this.api + 'Firm/GetFirm/' + id, { withCredentials: true });
  }

  getFirmByName(firmName: string): Observable<IFirm> {
    return this.http.get<IFirm>(this.api + 'Firm/GetFirmByName/' + firmName, httpOptions);
  }

  getSimilarFirmNames(firmName: string): Observable<Array<any>> {
    return this.http.get<Array<any>>(this.api + 'Firm/GetSimilarFirmNames/' + firmName, { withCredentials: true })
  }
  /*deleteFirm(id: number): Observable<any> {
    return this.http.get(environment.privateApi + 'Firms/DeleteFirm/' + id, httpOptions);
  }

  saveFirm(firm: IFirm): Observable<any> {
    return this.http.post(environment.privateApi + 'Firms/SaveFirm', firm, httpOptions);
  }*/

  getFirmCertificates(id: number): Observable<any> {
    return this.http.get<any>(this.api + 'Firm/AllFirmCertificates/' + id, { withCredentials: true });
  }

  getFirmCredentials(id: number): Observable<any> {
    return this.http.get<any>(this.api + 'Firm/AllFirmLeadCredentials/' + id, { withCredentials: true });
  }

  //region FirmLicenses
  loadFirmLicenses(){
    this.firmLicenses = null;
    this.firmLicense = null;
    this.loaded = false;

    this.getUserFirmLicenses().subscribe(
      res=>{               
        this.firmLicenses = res
        this.loaded = true;

        if (this.firmLicenses.length){          
          var result = this.firmLicenses.filter(x=>x.status !== 'Cancelled' && x.status !== 'Approved' && x.firm === null);          
          this.firmLicense = result.length ? result[0] : null;                     
        }                        
      },
      err=>{
        this.loaded = true;
        console.log(err);
        this.toastr.error("Unable to get user applications");            
      }
    )
  }

  getUserFirmLicenses(): Observable<IFirmLicense[]> {    
    return this.http.get<IFirmLicense[]>(this.api + 'Firm/GetFirmLicenses/', { withCredentials: true });
  }

  // getApplication(id: number): Observable<IApplication> {
  //   return this.http.get<IApplication>(this.api + 'Firm/GetApplicationById/' + id, { withCredentials: true });
  // }

  // deleteApplication(id: number): Observable<any> {
  //   return this.http.get(this.api + 'Firm/DeleteApplication/' + id, httpOptions);
  // }

  saveFirmLicense(application: IFirmLicense): Observable<IFirmLicense> {        
    return this.http.post<IFirmLicense>(this.api + 'Firm/SaveFirmLicense', application, httpOptions);
  }

  getFirmLicensePeople(id:number): Observable<IFirmLicensePerson[]> {    
    return this.http.get<IFirmLicensePerson[]>(this.api + 'Firm/GetAllFirmLicensePeople/' + id, { withCredentials: true });
  }

  getFirmLicensePerson(id: number): Observable<IFirmLicensePerson> {
    return this.http.get<IFirmLicensePerson>(this.api + 'Firm/GetFirmLicensePersonById/' + id, { withCredentials: true });
  }

  deleteFirmLicensePerson(id: number): Observable<any> {    
    return this.http.get(this.api + 'Firm/DeleteFirmLicensePerson/' + id, httpOptions);
  }

  saveFirmLicensePerson(applicationPerson: IFirmLicensePerson): Observable<any> {        
    return this.http.post(this.api + 'Firm/SaveFirmLicensePerson', applicationPerson, httpOptions);
  }

  getFirmLicenseDocuments(id:number): Observable<IFirmLicenseDocument[]> {    
    return this.http.get<IFirmLicenseDocument[]>(this.api + 'Firm/GetAllFirmLicenseDocuments/' + id, { withCredentials: true });
  }

  // getApplicationDocument(id: number): Observable<IApplicationDocument> {
  //   return this.http.get<IApplicationDocument>(this.api + 'Firm/GetApplicationDocumentById/' + id, { withCredentials: true });
  // }

  deleteFirmLicenseDocument(id: number): Observable<any> {    
    return this.http.get(this.api + 'Firm/DeleteFirmLicenseDocument/' + id, httpOptions);
  }

  saveFirmLicenseDocument(applicationDocument: IFirmLicenseDocument): Observable<any> { 
    return this.http.post(this.api + 'Firm/SaveFirmLicenseDocument', applicationDocument, httpOptions);
  }

  // getFirmLicenseData(id: number): Observable<IFirmLicense> {
  //   return this.http.get<IFirmLicense>(this.api + 'Firm/GetFirmLicenseData/' + id, { withCredentials: true });
  // }

  // deleteFirmApplicationData(id: number): Observable<any> {
  //   return this.http.get(this.api + 'Firm/DeleteFirmApplicationData/' + id, httpOptions);
  // }

  // saveFirmLicenseData(firmApplicationData: IFirmLicense): Observable<any> {        
  //   return this.http.post(this.api + 'Firm/SaveFirmLicenseData', firmApplicationData, httpOptions);
  // }

  getFirmLicenseDeficiencies(id:number): Observable<IFirmLicenseDeficiency[]> {    
    return this.http.get<IFirmLicenseDeficiency[]>(this.api + 'Firm/GetAllFirmLicenseDeficiencies/' + id, { withCredentials: true });
  }

  GetFirmPeople(id:number): Observable<IPersonContactInformation[]> {    
    return this.http.get<IPersonContactInformation[]>(this.api + 'Firm/GetFirmPeople/' + id, { withCredentials: true });
  }

  emailValidator(emailFieldName: string, confirmEmailFieldName: string): ValidatorFn {
    return (group: FormGroup): ValidationErrors | null => {
      const email = group.get(emailFieldName)?.value;
      const confirmEmail = group.get(confirmEmailFieldName)?.value;      

      if (email !== confirmEmail) {
        return { mismatchedEmails: 'Emails do not match' };
      }
  
      return null;
    };
  }

  validateEmail(formControlName: string, formGroup: FormGroup): boolean {
    // const emailControl = formGroup.get(formControlName);
    // const confirmEmailControl = formGroup.get(formControlName.replace('Email', 'ConfirmEmail'));
    
    // if (confirmEmailControl.errors && confirmEmailControl.touched) {
    //   emailControl.setErrors(['emailMismatch']);
    //   return true;
    // }
    
    // emailControl.clearValidators();
    // emailControl.updateValueAndValidity();
    return false;
  }
  
  validateFieldRequired(form: FormGroup, fieldName: string): boolean {
    if (form.hasError(`${fieldName}Required`) && form.get(fieldName).touched) {
      form.get(fieldName).setErrors(['required']);
      return true;
    }
    form.get(fieldName).clearValidators();
    form.get(fieldName).updateValueAndValidity();
    return false;
  }
}