<section *ngIf="loading">
    <h2>Loading...</h2>
    <mat-progress-bar mode="indeterminate" color="primary" aria-label="Loading...">
    </mat-progress-bar>
  </section>
<div (window:resize)="onResize($event)">
  <br />
  <mat-card>
    <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
        <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
            <div>
                <strong class="noMarginHeader">Results ({{firmCertificates.length}})</strong> 
                &nbsp;&nbsp;&nbsp;&nbsp;     
                <mat-form-field style="width:200px;">
                  <input (keyup)="filterTable($event)" placeholder="Filter Certificates" matInput />
                  <input placeholder="Filter Certificates" matInput />
                </mat-form-field>      
            </div>     
    </div>
    <br />
      <mat-table [dataSource]="dataSource" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
        <mat-row>Results ({{firmCertificates.length}})</mat-row>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row [ngClass]="sharedService.isMobile ? 'mobile-row':''" *matRowDef="let row; columns: displayedColumns;"></mat-row>
  
        <ng-container matColumnDef="controlNumber">
          <mat-header-cell *matHeaderCellDef mat-sort-header  fxFlex.lt-sm="100">Control Number</mat-header-cell>
          <mat-cell *matCellDef="let result"  fxFlex.lt-sm="100">      
            <span  title="Edit {{result.controlNumber}}" *ngIf="result.controlNumber != ''">{{result.controlNumber}}</span>              
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="codeType">
          <mat-header-cell *matHeaderCellDef mat-sort-header  fxFlex.lt-sm="100">Class</mat-header-cell>
          <mat-cell *matCellDef="let result"  fxFlex.lt-sm="100">
            <span>{{result.codeType}}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="issueDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header  fxFlex.lt-sm="100">Issue Date</mat-header-cell>
          <mat-cell *matCellDef="let result"  fxFlex.lt-sm="100">
            <span>{{result.issueDate | date:'shortDate'}}</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="expirationDate">
          <mat-header-cell *matHeaderCellDef mat-sort-header  fxFlex.lt-sm="100">Expiration Date</mat-header-cell>
          <mat-cell *matCellDef="let result"  fxFlex.lt-sm="100">
            <span>{{result.expirationDate | date:'shortDate'}}</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef fxFlex="10" fxFlex.lt-sm="100" style="justify-content: center !important;">
            Actions</mat-header-cell>
          <mat-cell *matCellDef="let result" fxFlex="10" fxFlex.lt-sm="100" style="justify-content: center !important;">
            <button mat-raised-button color="primary" (click)="renewClicked()">Renew</button>
          </mat-cell>
        </ng-container>

      </mat-table>
      <br />
      <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 50, firmCertificates.length]" #TablePaginator="matPaginator"
        showFirstLastButtons></mat-paginator>
    </div>
  </mat-card>
</div>  