import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({providedIn: 'root'})
export class AppService {
  private api: string = environment.publicApi;
  constructor(private httpClient: HttpClient) { }

  getAppInfo(): Observable<any>{
    return this.httpClient.get<any>(this.api + 'Application/info');
  }

}
