import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ANIMATIONS, APPLICATION_STATUS, LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { IFirm } from 'app/models/firm/firm';
import { IFirmLicense } from 'app/models/firm/firm-license';
import { SharedService } from 'app/services/core/shared.service';
import { FirmService } from 'app/services/firm/firm.service';
import { UserInformationService } from 'app/services/user-info/user-info.service';
import { ToastrService } from 'ngx-toastr';
import { AddressComponent } from '../common/address/address.component';
import { ApplicationsComponent } from '../common/applications/applications.component';
import { ContactComponent } from '../common/contact/contact.component';
import { StaffComponent } from '../common/staff/staff.component';
import { FirmCertificateListComponent } from './certificate/firm-certificate-list.component';
import { FirmCredentialListComponent } from './credential/firm-credential-list.component';
import { CommonService } from 'app/services/common/common.service';
import { IAccountAffiliation } from 'app/models/common/account-affiliation';
import { AdminAccountsComponent } from '../common/admin-accounts/admin-accounts.component';
import { IPayment } from '../payments/models/payment';

@Component({
  selector: 'app-firm',
  templateUrl: './firm.component.html',
  styleUrls: ['./firm.component.scss'],
  animations: ANIMATIONS,
  encapsulation: ViewEncapsulation.None
})
export class FirmComponent implements OnInit {
  userId: string = null;
  firmLicense: IFirmLicense = null;  
  accountAffiliations: IAccountAffiliation[];
  firmAccountAffiliations: IAccountAffiliation[];

  firmData: IFirm = null;
  firmApplications: IFirmLicense[];
  firmCertificates: any[];
  firmCredentials: any[];
  firmStaff: any[];
  firmAddress: any;
  firmContact: any;
  payment: IPayment;
  loading: boolean = true;
  firmForm: FormGroup = null;
  uiData = LEAD_BASED_PAINT;  
  firmStatus: string = 'none';
  objectKeys = Object.keys;
  cycleValue = 0;

  tabData: {component:any, title:string, icon:string, count:string}[];

  headerData: {category:{icon:string,title:string,data:any,displayType:string}[]}[]; 

  constructor(public sharedService: SharedService,
    public firmService: FirmService,
    public commonService: CommonService,
    public userService: UserInformationService,
    private toastr: ToastrService,
    private router: Router) 
  {}

  ngOnInit(): void {    
    this.checkAfiiliationsLoading()      
  }

  checkAfiiliationsLoading(){
    this.loading = true;
    if(!this.commonService.loaded){
      setTimeout(() => {
        this.checkAfiiliationsLoading();    
      }, 2000);
    }
    else
      this.loadAffiliations()
  }  

  isBoolean(data: any){
    return (typeof data === 'boolean');
  }

  feeDate(date: string){
    const d = new Date(date);
    d.setMonth(d.getMonth() + 3);
    return d.toISOString();
  }

  loadAffiliations(){        
    this.accountAffiliations = this.commonService.accountAffiliations; 
    this.firmAccountAffiliations = this.commonService.firmAccountAffiliations;

    var firmAffiliations = this.accountAffiliations.filter(x=>x.firm !== null && x.status !== APPLICATION_STATUS.cancelled)
    this.firmData = firmAffiliations.length ? firmAffiliations[0].firm : null;        
    this.firmLicense = this.firmService.firmLicense    
    
    if(this.firmData){
      this.firmService.loadFirmData(this.firmData).subscribe(res=>{        
        this.loadManagementData();
        this.loading = false;
      },err=>{})      
    }
    else{                  
      this.getPaymentStatus();      
    }    
  }

  getPaymentStatus() { 
    if (this.firmLicense)
    {   
      this.userService.getUserLicenses().subscribe(result => {            
        this.payment = result.filter(x=>x.id===this.firmLicense.id)[0].payments[0];     
        this.loading = false;    
      }, error => {      
        this.toastr.error('Unable to get user payments');
      });
    }
    else
      this.loading = false;    
  }

  applicationClicked(){
    this.router.navigate(['/firm/apply']);
  }

  loadManagementData(){    
    this.firmCertificates = this.firmService.firmCertificates;
    this.firmApplications = this.firmService.firmLicenses.filter(x=>x.firm !== null && x.firm.id === this.firmService.affiliatedFirm);
    this.firmCredentials = this.firmService.firmCredentials;
    
    this.tabData =
    [
      {component:ApplicationsComponent, title:'Applications - ', icon:'', count: this.firmApplications.length.toString()},
      {component:FirmCertificateListComponent, title:'Certificates - ', icon:this.uiData.certificate.icon, count: this.firmCertificates.length.toString()},
      {component:FirmCredentialListComponent, title:'Credentials - ', icon:this.uiData.credential.icon, count: this.firmCredentials.length.toString()},
      {component:StaffComponent, title:'Staff - ', icon:this.uiData.staff.icon, count: '2'},
      {component:AddressComponent, title:'Address', icon:this.uiData.address.icon, count: ''},
      {component:ContactComponent, title:'Contact', icon:this.uiData.contact.icon, count: ''},
      {component:AdminAccountsComponent, title:'Administrators - ', icon:'', count: this.firmAccountAffiliations.length.toString()},
    ];

  this.headerData= [
    {category:[
        {icon:this.uiData.id.icon,title:'Firm Id: ',data:this.firmData.id, displayType:'string'},
        {icon:this.uiData.name.icon,title:'Firm Status: ',data:this.firmData.inactive? 'Inactive':'Active',displayType:'string'},
      ]
    },
    {category:[
        {icon:this.uiData.id.icon,title:'CCB: ',data:this.firmData.ccb,displayType:'string'},
        {icon:this.uiData.calendar.icon,title:'CCB Expiration: ',data:this.firmData.ccbExpirationDate,displayType:'date'},
      ]
    },
    {category:[        
        {icon:this.uiData.calendar.icon,title:'Next Certificate Expiration: ',data:'11/12/23',displayType:'date'},
        {icon:this.uiData.calendar.icon,title:'Next Staff Expiration: ',data:'11/12/23',displayType:'date'},
      ]
    },
    {category:[
        {icon:this.uiData.name.icon,title:'Public Firm: ',data:!this.firmData.isPrivate,displayType:'string'},
        {icon:this.uiData.id.icon,title:'Services Avalable: ',data:this.firmData.servicesAvailable,displayType:'string'},
      ]
    },
  ];
  }

  cancelFirmAffiliation(){
    const dialogRef = this.commonService.openDialog('Are you sure you want to remove your account\'s Firm Affiliation?', 'Remove Firm Confirmation')

    dialogRef.afterClosed().subscribe(result => {
      if (result) {        
        var affiliation = this.accountAffiliations.filter(x=>x.firm === this.firmData)[0]
        affiliation.status = APPLICATION_STATUS.cancelled

        this.commonService.saveAccountAffiliation(affiliation).subscribe(
          res=>{
            this.commonService.loadAccountAffiliations();
            this.checkAfiiliationsLoading();
          },
          err=>{}
        )
      } 
    });
  }
  
}