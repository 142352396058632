import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { APPLICATION_STATUS, LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { IAccountAffiliation } from 'app/models/common/account-affiliation';
import { IFirmLicense } from 'app/models/firm/firm-license';
import { CommonService } from 'app/services/common/common.service';
import { SharedService } from 'app/services/core/shared.service';
import { FirmService } from 'app/services/firm/firm.service';
import { UserInformationService } from 'app/services/user-info/user-info.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-admin-accounts',
  templateUrl: './admin-accounts.component.html',
  styleUrls: ['./admin-accounts.component.scss']
})
export class AdminAccountsComponent implements OnInit {
  public userID: string;
  processing: boolean = false;
  loading: boolean = true;
  pageSize = 50;
  firmAccountAffiliations: IAccountAffiliation[] = [];
  uiData: any = LEAD_BASED_PAINT.certificate;
  displayedColumns: string[] = ['id', 'userId', 'Actions'];
  dataSource = new MatTableDataSource<any>(this.firmAccountAffiliations);
  @ViewChild('TablePaginator') paginator: MatPaginator;
  @ViewChild('TableSort') sort: MatSort;  

  filterTable(event: any) {
    //this.dataSource.filter = event.target.value.trim().toLowerCase();
  }

  constructor(public dialog: MatDialog,              
             private commonService: CommonService,
             public sharedService: SharedService, 
             private userSerive: UserInformationService,            
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.loadFirmAdministrators();
  }

  loadFirmAdministrators() {
    this.firmAccountAffiliations = this.commonService.firmAccountAffiliations;        
    this.dataSource = new MatTableDataSource<any>(this.firmAccountAffiliations);  
    this.userSerive.getUserInfo().subscribe(result=>{      
      this.userID = result.email;
      this.loading = false;
    },error=>{console.log("There was an error getting your user info: ", error)})      
  }

  removeAdmin(toRemove: any){      
    const dialogRef = this.commonService.openDialog('<strong>You are about to remove the user ' + toRemove.userID + ' as an administrator for the firm: ' + toRemove.firm.name + '.</strong>\n\nAre you sure you want to remove ' + toRemove.userID + ' as an administrator?', 'Remove Administrator Confirmation')

    dialogRef.afterClosed().subscribe(result => {
      if (result) {                  
        toRemove.status = APPLICATION_STATUS.cancelled;
        this.commonService.saveAccountAffiliation(toRemove).subscribe(result=>{
          this.commonService.loadAccountAffiliations();
          this.firmAccountAffiliations = this.firmAccountAffiliations.filter(item => item !== toRemove);
          this.dataSource = new MatTableDataSource<any>(this.firmAccountAffiliations);
        },error=>{console.log("There was an error removing the firm admin: ", error)});
      } 
    }); 
  }

  onResize(event) {}
}
