import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { RoutesModule } from './routes.module';
import { SharedModule } from './shared/shared.module';
import { FormlyConfigModule } from './configuration/angular/formly-config.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ToastrModule } from 'ngx-toastr';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { DefaultLayoutComponent } from '../app/layout/default-layout/default-layout.component';
import { SidebarComponent } from '../app/layout/sidebar/sidebar.component';
import { AccordionDirective } from '../app/layout/sidebar/accordion.directive';
import { AccordionItemDirective } from '../app/layout/sidebar/accordionItem.directive';
import { AccordionAnchorDirective } from '../app/layout/sidebar/accordionanchor.directive';
import { NavbarComponent } from '../app/layout/navbar/navbar.component';
import { FooterComponent } from '../app/layout/footer/footer.component';
import { TranslateComponent } from '../app/layout/widgets/translate.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { AppTimeoutComponent, TimeoutDialog } from './layout/widgets/timeout.component';

import { environment } from '@env/environment';
import { BASE_URL } from '@core/interceptors/base-url-interceptor';
import { httpInterceptorProviders } from '@core/interceptors';
import { appInitializerProviders } from '@core/initializers';

import { IPublicClientApplication, PublicClientApplication, InteractionType, LogLevel, BrowserCacheLocation } from '@azure/msal-browser';
import {
  MsalGuard,
  MsalInterceptor,
  MsalBroadcastService,
  MsalInterceptorConfiguration,
  MsalModule, MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalGuardConfiguration,
  MsalRedirectComponent
} from '@azure/msal-angular';

import { b2cPolicies, protectedResources } from '../app/auth/auth-config';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { FirmComponent } from './components/firm/firm.component';
import { ComplaintsComponent } from './components/complaints/complaints.component';
import { FirmLicenseComponent } from './components/firm/license/firm-license.component';
import { FirmCredentialListComponent } from './components/firm/credential/firm-credential-list.component';
import { FirmCertificateListComponent } from './components/firm/certificate/firm-certificate-list.component';
import { StaffComponent } from './components/common/staff/staff.component';
import { AddressComponent } from './components/common/address/address.component';
import { ContactComponent } from './components/common/contact/contact.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { HealthyDashboardComponent } from './components/dashboard/healthy-dashboard/healthy-dashboard.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { StaffAddEditComponent } from './components/common/staff/add-edit/staff-add-edit.component';
import { ApplicationsComponent } from './components/common/applications/applications.component';
import { ConfirmationDialogueComponent } from './components/common/confirmation-dialogue/confirmation-dialogue.component';
import { PaymentsComponent } from './components/payments/components/payments/payments.component';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { PaymentsModule } from './components/payments/payment.module';
import { AdminAccountsComponent } from './components/common/admin-accounts/admin-accounts.component';
import { FirmStatusCardComponent } from './components/firm/status-card/firm-status-card.component';
import { AddressAddEditComponent } from './components/common/address/add-edit/address-add-edit.component';
import { IndividualComponent } from './components/individual/individual.component';
import { IndividualLicenseComponent } from './components/individual/license/individual-license.component';
import { IndividualStatusCardComponent } from './components/individual/status-card/individual-status-card.component';
import { AddEditTrainingComponent } from './components/individual/license/add-edit-training/add-edit-training.component';
import { AddEditTestingComponent } from './components/individual/license/add-edit-testing/add-edit-testing.component';
import { IndividualAddressComponent } from './components/individual/individual-address/individual-address.component';
import { IndividualInformationComponent } from './components/individual/individual-information/individual-information.component';
import { IndividualContactInformationComponent } from './components/individual/individual-contact-information/individual-contact-information.component';
import { IndividualCertificatesComponent } from './components/individual/individual-certificates/individual-certificates.component';
import { IndividualTrainingComponent } from './components/individual/individual-training/individual-training.component';
import { IndividualTestingComponent } from './components/individual/individual-testing/individual-testing.component';
import { IndividualFirmEmploymentComponent } from './components/individual/individual-firm-employment/individual-firm-employment.component';
import { IndividualApplicationsComponent } from './components/individual/individual-applications/individual-applications.component';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.b2c.clientId,
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      redirectUri: environment.b2c.redirectUri,
      postLogoutRedirectUri: environment.b2c.postLogoutRedirectUri,
      knownAuthorities: [b2cPolicies.authorityDomain]
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Warning,
        piiLoggingEnabled: true
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(protectedResources.endpoint, protectedResources.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...protectedResources.scopes]
    },
    loginFailedRoute: 'login-failed'
  };
}

// Required for AOT compilation
export function TranslateHttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    DashboardComponent,
    TimeoutDialog,
    AppTimeoutComponent,
    SidebarComponent,
    AccordionDirective,
    AccordionItemDirective,
    AccordionAnchorDirective,
    NavbarComponent,
    FooterComponent,
    TranslateComponent,
    ConfirmDialogComponent,
    FirmComponent,    
    ComplaintsComponent,
    FirmLicenseComponent,
    FirmCredentialListComponent,
    FirmCertificateListComponent,
    StaffComponent,
    AddressComponent,
    ContactComponent,
    HealthyDashboardComponent,
    StaffAddEditComponent,
    ApplicationsComponent,
    ConfirmationDialogueComponent,
    AdminAccountsComponent,
    FirmStatusCardComponent,
    AddressAddEditComponent,
    IndividualComponent,
    IndividualLicenseComponent,
    IndividualStatusCardComponent,
    AddEditTrainingComponent,
    AddEditTestingComponent,
    IndividualAddressComponent,
    IndividualInformationComponent,
    IndividualContactInformationComponent,
    IndividualCertificatesComponent,
    IndividualTrainingComponent,
    IndividualTestingComponent,
    IndividualFirmEmploymentComponent,
    IndividualApplicationsComponent,
  ],
  imports: [
    BrowserModule,
    PaymentsModule,
    NgIdleKeepaliveModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    RoutesModule,
    SharedModule,
    MsalModule,
    FormlyConfigModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateHttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    PDFExportModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    { provide: BASE_URL, useValue: environment.baseUrl },
    httpInterceptorProviders,
    appInitializerProviders
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
