<div *ngIf="loadingB2CProfile || authService.loading">
  <span>Loading</span><br />
  <mat-progress-bar mode="query"></mat-progress-bar>

</div>

<div fxLayout="column" fxLayoutGap="8px" *ngIf="!loadingB2CProfile && !authService.loading">
  <div fxLayout="row">
    <mat-card
    fxFlex="40"
    fxFlex.lt-sm="100"
    [ngClass]="{
      'bg-gray-700': sharedService.darkMode,
      'bg-indigo-50': !sharedService.darkMode
    }">
    <mat-card-title> User Profile </mat-card-title>
    <mat-card-subtitle>
      <div fxLayout="row" fxLayoutGap="8px">
        <span fxFlex></span>
        <!-- <button
          mat-raised-button
          *ngIf="isAuthenticated()"
          (click)="editProfile()"
          color="primary">
          Edit B2C Profile
        </button> -->
      </div>
    </mat-card-subtitle>
    <mat-card-content>
      <div fxLayout="column" fxLayoutGap="8px" *ngIf="!loadingB2CProfile">
        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start end">
          <span style="font-size: larger">Display Name</span>
          <span fxFlex></span>
          <span>{{authService.userProfile.firstName}} {{authService.userProfile.lastName}} </span>
        </div>
        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start end">
          <span style="font-size: larger">First Name</span>
          <span fxFlex></span>
          <span>{{authService.userProfile.firstName}}</span>
        </div>
        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start end">
          <span style="font-size: larger">Last Name</span>
          <span fxFlex></span>
          <span>{{authService.userProfile.lastName}}</span>
        </div>
        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start end">
          <span style="font-size: larger">Email</span>
          <span fxFlex></span>
          <span>{{authService.userProfile.email}}</span>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  </div>

  <!-- roles -->
  <div fxLayout="row" *ngIf="user.roles != undefined && user.roles != null && user.roles.length > 0">
    <mat-card
    fxFlex="40"
    fxFlex.lt-sm="100"
    [ngClass]="{
      'bg-gray-700': sharedService.darkMode,
      'bg-indigo-50': !sharedService.darkMode
    }">
    <mat-card-title> Roles </mat-card-title>
    <mat-card-content>
      <ul>
        <li *ngFor="let role of user.roles">{{role}}</li>
      </ul>
    </mat-card-content>
  </mat-card>
  </div>
</div>
