import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';


const httpOptionsFormData = {
  headers: new HttpHeaders({
    'Cache-Control': 'no-cache',
  }),
  withCredentials: true
};

@Injectable({providedIn: 'root'})

export class PaymentService {

  public applicationFeeType = "ApplicationFee";
  public licenseFeeType = "LicenseFee";
  public reducedLicenseFeeType = "ReducedLicenseFee";
  public CivilPenaltyFeeType = "CivilPenaltyFeeType"
  public FirmEntityType = "FirmLicense";
  public FirmLbpaPaymentOption = "FirmLicense-lbpa";
  public FirmRrpPaymentOption = "FirmLicense-rrp";
  public FirmWorkerPaymentOption = "FirmLicense-Worker";
  public FirmSupervisorPaymentOption = "FirmLicense-Supervisor";
  public FirmRiskAssessorPaymentOption = "FirmLicense-Risk Assessor";
  public FirmProjectDesignerPaymentOption = "FirmLicense-Project Designer";
  public FirmInspectorPaymentOption = "FirmLicense-Inspector";
  public PaymentIssuedCommentSearchText = "Online Payment issued";  
  public paymentAwaitingClearanceStatus = 'Awaiting Clearance';

  public paymentOptions = [
    {
      Text: "Firm (LBPA)",
      Value: this.FirmLbpaPaymentOption,
      LicenseFee: "255.00",
      ReducedLicenseFee: "250.00",
      ApplicationFeeNotice: "After the Firm License application has been submitted, you will receive a request to pay the license fee. A “Pay Fee” button will be displayed. By clicking this button, payment information below will be displayed along with a remittance form that can be downloaded and submitted with your payment if you prefer to pay in-person. A copy of this information will also be emailed to you after the application is submitted.",
      LicenseFeeNotice: "",
      CivilPenaltyNotice: ""
    },
    {
      Text: "Firm (RRP)",
      Value: this.FirmRrpPaymentOption,
      LicenseFee: "250.00",
      ReducedLicenseFee: "250.00",
      ApplicationFeeNotice: "After the Firm License application has been submitted, you will receive a request to pay the license fee. A “Pay Fee” button will be displayed. By clicking this button, payment information below will be displayed along with a remittance form that can be downloaded and submitted with your payment if you prefer to pay in-person. A copy of this information will also be emailed to you after the application is submitted.",
      LicenseFeeNotice: "",
      CivilPenaltyNotice: ""
    },
    {
      Text: "Firm Worker",
      Value: this.FirmWorkerPaymentOption,
      LicenseFee: "150.00",
      ReducedLicenseFee: "150.00",
      ApplicationFeeNotice: "After the Firm License application has been submitted, you will receive a request to pay the worker license fee. A “Pay Fee” button will be displayed. By clicking this button, payment information below will be displayed along with a remittance form that can be downloaded and submitted with your payment if you prefer to pay in-person. A copy of this information will also be emailed to you after the application is submitted.",
      LicenseFeeNotice: "",
      CivilPenaltyNotice: ""
    },
    {
      Text: "Firm Supervisor",
      Value: this.FirmSupervisorPaymentOption,
      LicenseFee: "255.00",
      ReducedLicenseFee: "255.00",
      ApplicationFeeNotice: "After the Firm License application has been submitted, you will receive a request to pay the Supervisor license fee. A “Pay Fee” button will be displayed. By clicking this button, payment information below will be displayed along with a remittance form that can be downloaded and submitted with your payment if you prefer to pay in-person. A copy of this information will also be emailed to you after the application is submitted.",
      LicenseFeeNotice: "",
      CivilPenaltyNotice: ""
    },
    {
      Text: "Firm Risk Assessor",
      Value: this.FirmRiskAssessorPaymentOption,
      LicenseFee: "255.00",
      ReducedLicenseFee: "255.00",
      ApplicationFeeNotice: "After the Firm License application has been submitted, you will receive a request to pay the Risk Assessor license fee. A “Pay Fee” button will be displayed. By clicking this button, payment information below will be displayed along with a remittance form that can be downloaded and submitted with your payment if you prefer to pay in-person. A copy of this information will also be emailed to you after the application is submitted.",
      LicenseFeeNotice: "",
      CivilPenaltyNotice: ""
    },
    {
      Text: "Firm Project Designer",
      Value: this.FirmProjectDesignerPaymentOption,
      LicenseFee: "255.00",
      ReducedLicenseFee: "255.00",
      ApplicationFeeNotice: "After the Firm License application has been submitted, you will receive a request to pay the Project Designer license fee. A “Pay Fee” button will be displayed. By clicking this button, payment information below will be displayed along with a remittance form that can be downloaded and submitted with your payment if you prefer to pay in-person. A copy of this information will also be emailed to you after the application is submitted.",
      LicenseFeeNotice: "",
      CivilPenaltyNotice: ""
    },
    {
      Text: "Firm Inspector",
      Value: this.FirmInspectorPaymentOption,
      LicenseFee: "255.00",
      ReducedLicenseFee: "255.00",
      ApplicationFeeNotice: "After the Firm License application has been submitted, you will receive a request to pay the Inspector license fee. A “Pay Fee” button will be displayed. By clicking this button, payment information below will be displayed along with a remittance form that can be downloaded and submitted with your payment if you prefer to pay in-person. A copy of this information will also be emailed to you after the application is submitted.",
      LicenseFeeNotice: "",
      CivilPenaltyNotice: ""
    }
  ]

  public paymentPendingStatus = 'Pending';
  public paymentApprovedAwaitingPayment = 'Approved, Pending Payment and Clearance'
  public paymentPaidStatus = 'Paid'
  public paymentClearedStatus = 'Cleared';
  public paymentStatuses = [this.paymentPendingStatus, this.paymentClearedStatus, this.paymentPaidStatus ];
  public paymentMethods = [
    'Online',
    'Money Order',
    'Personal/Business Check',
    'Cashier\'s Check',
    'Cash'];
  private api: string = environment.privateApi + 'Payments/';

  constructor(private http: HttpClient) { }

  public getPaymentTypeName(entityType: string): string {
    let option = this.paymentOptions.find(po => po.Value == entityType);
    if(option != null && option != undefined) {
      return option.Text;
    }
    return entityType;
  }

  public getPaymentTypeNotice(entityType: string, feeType: string): string {
    let option = this.paymentOptions.find(po => po.Value == entityType);
    if(option != null && option != undefined) {
      if(feeType == this.licenseFeeType  ) {
        return option.LicenseFeeNotice;
      }
      if(feeType == this.applicationFeeType) {
        return option.ApplicationFeeNotice;
      }
      if(feeType == this.CivilPenaltyFeeType) {
        return option.CivilPenaltyNotice;
      }

      return "";

    }
    return "";
  }

  public getPaymentTypeAmount(entityType: string, feeType: string): string {
    let value = entityType;
    if(feeType != null && feeType.length > 0) {
      value = entityType + '-' + feeType;
    }
    let option = this.paymentOptions.find(po => po.Value == value);
    if(option != null && option != undefined) {
        return option.LicenseFee;
    }
    return "";
  }

  public getPaymentTypeDescription(entityType: string, feeType: string): string {
    let value = entityType;
    if(feeType != null && feeType.length > 0) {
      value = entityType + '-' + feeType;
    }
    let option = this.paymentOptions.find(po => po.Value == value);
    if(option != null && option != undefined) {
      return option.Text
    }
    return "";
  }

  public negotiateToken(paymentId: number) : Observable<string> {
    return this.http.post<string>(this.api + 'NegotiateToken/' + paymentId, httpOptionsFormData);
  }

  public updatePaymentSuccess(token: string) : Observable<string> {
    return this.http.post<string>(this.api + 'UpdatePaymentSuccess/' + token, httpOptionsFormData);
  }

}
