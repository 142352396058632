<div *ngIf="!loading" [@slideRight] [ngStyle]="{'--entity-color' : uiData.firm.color}">

    <div *ngIf="!firmData">
        <app-firm-status-card [displayData]="{firmLicense,payment,firmData}"></app-firm-status-card>
    </div>

    <div *ngIf="firmData">
        <span>
            <mat-card [style.background-color]="uiData.firm.color">
                <h2 class="noMarginHeader">
                    <mat-icon>{{uiData.firm.icon}}</mat-icon> {{this.firmData.name}}
                </h2>
            </mat-card>
        </span>

        <mat-dialog-content>
            <br />
            <div fxFlex="100" fxFlex.lt-sm="100" class="matero-col">
              <mat-card fxFlex="100">
                <div fxLayout="row wrap" class="matero-row" fxLayoutGap="8px grid">
                  <div fxFlex="auto" fxFlex.lt-sm="100" *ngFor="let data of headerData">
                    <div style="margin: 5px;" *ngFor="let section of data.category">
                      <strong>{{ section.title }}</strong>
                      <ng-container *ngIf="isBoolean(section.data); then booleanData else nonBooleanData"></ng-container>
          
                      <ng-template #booleanData>
                        <mat-checkbox disabled [(ngModel)]="section.data"></mat-checkbox>
                      </ng-template>
          
                      <ng-template #nonBooleanData>
                        <span *ngIf="section.displayType === 'date'; else defaultData">{{ section.data | date:'shortDate' }}</span>
                        <ng-template #defaultData>{{ section.data }}</ng-template>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </mat-card>
            </div>
          </mat-dialog-content>
          
        <mat-card>
            <mat-tab-group dynamicHeight mat-align-tabs="center" mat-stretch-tabs="true">
                <mat-tab *ngFor="let tab of tabData">
                    <ng-template mat-tab-label>                
                        <mat-icon>{{tab.icon}}</mat-icon> &nbsp; {{tab.title + tab.count}}
                    </ng-template>
                    <ng-container *ngComponentOutlet="tab.component"></ng-container>
                </mat-tab>               
            </mat-tab-group>
        </mat-card>

        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <button mat-raised-button class="custom-color-button" (click)="applicationClicked()">Apply for/or Renew a Firm Certificate</button>
            <span fxFlex></span>
            <button mat-raised-button color="warn" (click)="cancelFirmAffiliation()">Remove Firm Affiliation</button>
        </div>        
    
    </div>    
</div>

<div *ngIf="loading">
    <div [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
      <h1>Loading</h1>
    </div>
</div>
