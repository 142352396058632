import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from '@env/environment';
import { ConfirmationDialogueComponent } from 'app/components/common/confirmation-dialogue/confirmation-dialogue.component';
import { IAccountAffiliation } from 'app/models/common/account-affiliation';
import { IDocumentFile } from 'app/models/document/document-file';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
  }),
  withCredentials: true
};

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private api: string = environment.privateApi;
  loaded: boolean = false;
  accountAffiliations: IAccountAffiliation[];
  firmAccountAffiliations: IAccountAffiliation[];

  constructor(private toastr: ToastrService, 
    private dialog: MatDialog,
    private http?: HttpClient,) 
  { 
    this.loadAccountAffiliations()
  }

  loadAccountAffiliations(){
    this.loaded = false;
    this.getAccountAffiliation().subscribe(res=>{
      this.accountAffiliations = res;
      this.getAllFirmAccountAffiliations().subscribe(res=>{
        this.firmAccountAffiliations = res;
        this.loaded = true;
      })      
    },err=>{})    
  }

  getAccountAffiliation(): Observable<IAccountAffiliation[]> {    
    return this.http.get<IAccountAffiliation[]>(environment.privateApi + 'Common/GetAccountAffiliation/', { withCredentials: true });
  }

  getAllFirmAccountAffiliations(): Observable<IAccountAffiliation[]> {    
    return this.http.get<IAccountAffiliation[]>(environment.privateApi + 'Common/GetAllFirmAccountAffiliations/', { withCredentials: true });
  }

  saveAccountAffiliation(accountAffiliation: IAccountAffiliation): Observable<IAccountAffiliation> {    
    return this.http.post<IAccountAffiliation>(this.api + 'Common/SaveAccountAffiliation', accountAffiliation, httpOptions);
  }

  saveDocumentFile(document: IDocumentFile): Observable<IDocumentFile> {    
    return this.http.post<IDocumentFile>(this.api + 'Common/SaveDocumentFile', document, httpOptions);
  }

  deleteDocumentFile(id: number): Observable<any> {    
    return this.http.get(this.api + 'Common/DeleteDocumentFile/' + id, httpOptions);
  } 

  openDialog(message: string, title: string): MatDialogRef<ConfirmationDialogueComponent> {
    return this.dialog.open(ConfirmationDialogueComponent, {
      width: '400px',
      data: {message,title},
    });
  }
}
