import { Component, OnInit } from '@angular/core';
import { APPLICATION_STATUS, LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { IAccountAffiliation } from 'app/models/common/account-affiliation';
import { IFirmAddress } from 'app/models/firm/firm-address';
import { CommonService } from 'app/services/common/common.service';
import { FirmService } from 'app/services/firm/firm.service';
import { AddressAddEditComponent } from './add-edit/address-add-edit.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {
  uiData: any = LEAD_BASED_PAINT
  public accountAffiliation: IAccountAffiliation;
  public currentPhysicalAddress: IFirmAddress;
  public currentMailingAddress: IFirmAddress;
  public firmAddresses: IFirmAddress[];
  public loading: boolean = true;
  
  constructor(
    private firmService: FirmService, 
    private commonService: CommonService,
    private dialog: MatDialog,) { }

  ngOnInit(): void {
    this.loadAddress();
  }

  loadAddress(){
    this.accountAffiliation = this.commonService.accountAffiliations.filter(x=>x.firm !== null && x.status !== APPLICATION_STATUS.cancelled)[0]
    this.firmService.getFirmAddresses(this.accountAffiliation.firm.id).subscribe(result=>{      
      this.firmAddresses = result;
      this.currentMailingAddress = this.firmAddresses.filter(x=>x.isCurrentMailing)[0];
      this.currentPhysicalAddress = this.firmAddresses.filter(x=>x.isCurrentPhysical)[0];
      this.loading=false;
    },error=>{console.error("There was an error getting the firm addresses: ",error)})
  }

  editAddress() {
    const dialogRef = this.dialog.open(AddressAddEditComponent, {
      width: '90%',
      data: {currentPhysical: this.currentPhysicalAddress, currentMailing: this.currentMailingAddress}
    });
    dialogRef.afterClosed().subscribe(result => {
      //this.loadFirms();
    });
  }
}
