
import { Component } from '@angular/core';
import { AuthService } from 'app/auth/auth.service';
import { PaymentService } from 'app/components/payments/service/payment.service';
import { SharedService } from 'app/services/core/shared.service';




@Component({
  selector: 'app-pay-online-duplicate',
  templateUrl: './pay-online-duplicate.component.html'
})

export class PayOnlineDuplicateComponent {

  public processing: boolean = false;
  public isValid: boolean = true;


  constructor(public paymentService: PaymentService,
              public sharedService: SharedService,
              public authService: AuthService) { }


}


