<div fxLayout="column" fxFlexFill>
  <div [@slideRight]  fxLayout="column" *ngIf="!showForm && !showSuccess" style="font-family: Oswald, sans-serif;">
    <div fxLayout="row" fxLayoutAlign="center start" class="m-x-8">
      <mat-card fxFlex="100%" fxLayoutAlign="center start" [style.background]= "this.uiData.complaint.color">
        <h1>
          <div>Register a Complaint</div>
          <div>Oregon Lead Based Paint Program</div>
        </h1>
      </mat-card>
    </div>

    <div fxLayout="column" fxLayoutGap="16px" class="m-t-16">
        <span>
            If you have a complaint or a concern about a Lead Based Paint licensee, please use this form. The information provided will be carefully reviewed.
            Oregon Lead Based Paint Program will determine if there are potential violations and whether the Oregon Health Authority
            is the agency with jurisdiction to take further action.
          </span>
          <span>
            Oregon Lead Based Paint Program will only investigate violations of ORS. If your complaint is related to criminal activity, please contact law enforcement.
          </span>
          <span>
            Complaints are subject to public disclosure under Oregon's Public Records Law.
            If you are concerned about your privacy or safety, Oregon Lead Based Paint Program will make effort to keep your identity confidential to the extent permitted by law.
          </span>
          <span>
            Thank you for sharing your concerns with the Oregon Lead Based Paint Program.
          </span>
    </div> &nbsp;

    <div fxLayout="column" fxLayoutAlign="start center">      
      <button mat-raised-button type="button" color="primary" [style.width]="sharedService.isMobile?'100%':'20%'" (click)="openForm()">Start</button>
    </div>
  </div>
  
  <div [@slideLeft] fxLayout="row" *ngIf="showForm">
    <div fxLayout="column" fxFlexOffset="25" fxFlex="50" fxFlexOffset.lt-lg="5" fxFlex.lt-lg="90" style="font-family: Oswald, sans-serif; font-size: x-large">

      <mat-card style="display: flex; flex-direction: column; justify-content: space-between;" [style.background]= "this.uiData.complaint.color"> 
        <div fxLayout="row" style="font-size:xx-large">           
          <mat-icon >{{uiData.complaint.icon}}</mat-icon>&nbsp;            
          <div fxLayout="column">        
            <strong >Complaint Form</strong>
          </div>
        </div>
        <button mat-stroked-button style="background-color: white; margin-top: 15px;" color="warn" type="button" (click)="cancel()" *ngIf="!showSuccess">Cancel</button>
      </mat-card>

      <form [formGroup]="complaintForm" *ngIf="!showSuccess">
        <mat-card>
        <div fxLayout="column" >
            
            <span class="f-s-18">We may reach out to you if further details are needed</span>
            <mat-card fxLayout="column">
              <mat-card fxLayout="column" [style.background]= "this.uiData.complaint.color"
              style="margin: -16px;">
                <span><strong>Contact Information</strong></span>                 
                <mat-checkbox (click)="updateValidators()">
                  <label class="mat-checkbox-label">Submit as Anonymous</label>
                </mat-checkbox>
              </mat-card>
                <div *ngIf="!this.anonymous" fxLayout="column"> &nbsp;
                    <span>Name <span class="f-s-20 text-red-700">*</span></span>          
                    
                      <div style="display: flex; flex-wrap:wrap; justify-content: space-between;">
                        <mat-form-field style="flex-basis: 0; flex-grow: 1; margin-right: 5px;">

                            <input matInput placeholder="First Name" formControlName="contactFirstName" aria-label="Complainant First Name">
                            <mat-error *ngIf="complaintForm.get('contactFirstName').invalid">
                                Please provide your first name
                            </mat-error>
                        </mat-form-field>

                        <mat-form-field style="flex-basis: 0; flex-grow: 1;">
                            <input matInput placeholder="Last Name" formControlName="contactLastName" aria-label="Complainant Last Name">
                            <mat-error *ngIf="complaintForm.get('contactLastName').invalid">
                                Please provide your last name
                            </mat-error>
                        </mat-form-field>
                        
                        </div>
                    
                    <span>Email <span class="f-s-20 text-red-700">*</span></span>
                    <mat-form-field fxFlex="50">
                      <input matInput placeholder="Email" formControlName="contactEmail" aria-label="Complainant Email">
                      <mat-error *ngIf="complaintForm.get('contactEmail').hasError('email')">
                        This doesn't look like a valid email address
                      </mat-error>
                      <mat-error *ngIf="complaintForm.get('contactEmail').hasError('required')">
                        Please provide your email
                      </mat-error>
                    </mat-form-field>
          
                    <span>Phone <span class="f-s-20 text-red-700">*</span></span>
                    <mat-form-field fxFlex="50">
                      <input matInput placeholder="Phone" formControlName="contactNumber" mask="(000) 000-0000 000000" aria-label="Complainant Phone">
                      <mat-error *ngIf="complaintForm.get('contactNumber').hasError('required')">
                        Please provide a phone number
                      </mat-error>
                      <mat-error *ngIf="complaintForm.get('contactNumber').hasError('pattern')">
                        Phone should match this format: (999) 999-9999 999999
                      </mat-error>
                    </mat-form-field>

                    <span>Address <span class="f-s-20 text-red-700">*</span></span>
                    <mat-form-field fxFlex="50">
                      <input matInput placeholder="Street" formControlName="contactStreet" aria-label="Complainant Street">
                      <mat-error *ngIf="complaintForm.get('contactStreet').hasError('required')">
                        Please provide a street.
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field fxFlex="50">
                        <input matInput placeholder="City" formControlName="contactCity" aria-label="Complainant City">
                        <mat-error *ngIf="complaintForm.get('contactCity').hasError('required')">
                          Please provide a city.
                        </mat-error>
                      </mat-form-field>
                      <div style="display: flex; flex-wrap:wrap; justify-content: space-between;">
                      <mat-form-field style="flex-basis: 0; flex-grow: 1; margin-right: 5px;">
                        <input matInput placeholder="State" formControlName="contactState" aria-label="Complainant State">
                        <mat-error *ngIf="complaintForm.get('contactState').hasError('required')">
                          Please provide a state.
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field style="flex-basis: 0; flex-grow: 1; margin-right: 5px;">
                        <input matInput placeholder="Zip" formControlName="contactZip" aria-label="Complainant Zip">
                        <mat-error *ngIf="complaintForm.get('contactZip').hasError('required')">
                          Please provide a zip.
                        </mat-error>
                      </mat-form-field>

                      <mat-form-field style="flex-basis: 0; flex-grow: 1; margin-right: 5px;">
                        <input matInput placeholder="County" formControlName="contactCounty" aria-label="Complainant County">
                        <mat-error *ngIf="complaintForm.get('contactCounty').hasError('required')">
                          Please provide a county.
                        </mat-error>
                      </mat-form-field>
                    </div>
                </div>
            </mat-card>

            <mat-card fxLayout="column">
              <mat-card [style.background]= "this.uiData.complaint.color"
              style="margin: -16px;">
                <span><strong>Complaint Information</strong></span> 
              </mat-card>&nbsp;
                <span>Offender Name</span>
                <span style="font-size: large;">(if known)</span>                          
                <mat-form-field fxFlex="100">
                    <input matInput placeholder="Name" formControlName="entityName" aria-label="Offender Name">
                </mat-form-field>

                <span>
                    Date of incident 
                  </span>
                  <span style="font-size: large;">(if unknown leave blank, if multiple dates, note the dates in the complaint section below)</span>
                  <mat-form-field>
                    <mat-label>Date of Incident</mat-label>
                    <input placeholder="mm/dd/yyyy"
                           formControlName="incidentDate"
                           matInput
                           [matDatepicker]="incidentDate"
                           [max]="today" />
                    <mat-datepicker-toggle matSuffix [for]="incidentDate"></mat-datepicker-toggle>
                    <mat-datepicker #incidentDate>
                      <mat-datepicker-actions>
                        <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                        <button mat-button matDatepickerCancel>Cancel</button>
                      </mat-datepicker-actions>
                    </mat-datepicker>
                    <mat-error *ngIf="complaintForm.get('incidentDate').value > today">
                      Please choose an earlier date.
                    </mat-error>
                  </mat-form-field>
        
                  <span>What is your complaint? <span class="f-s-20 text-red-700">*</span></span>
                  <span style="font-size: large;">
                    Please describe your complaint in detail. Include any persons involved, where the rules violation occurred, when
                    it occurred,
                    and any other pertinent information that will aid in the investigation of this complaint.
                  </span>
                  <mat-form-field fxFlex>
                    <textarea matInput rows="3" fxFlex="100" type="text" placeholder="Complaint" formControlName="complaintText" aria-label="Complaint"></textarea>
                  </mat-form-field>

                  <span>Incident Address <span class="f-s-20 text-red-700">*</span></span>
                  <mat-form-field fxFlex="50">
                    <input matInput placeholder="Street" formControlName="incidentStreet" aria-label="Incident Street">
                    <mat-error *ngIf="complaintForm.get('incidentStreet').hasError('required')">
                      Please provide a street.
                    </mat-error>
                  </mat-form-field>

                  <mat-form-field fxFlex="50">
                      <input matInput placeholder="City" formControlName="incidentCity" aria-label="Incident City">
                      <mat-error *ngIf="complaintForm.get('incidentCity').hasError('required')">
                        Please provide a city.
                      </mat-error>
                    </mat-form-field>
                    
                  <div style="display: flex; flex-wrap:wrap; justify-content: space-between;">
                    <mat-form-field style="flex-basis: 0; flex-grow: 1; margin-right: 5px;">
                      <input matInput placeholder="State" formControlName="incidentState" aria-label="Incident State">
                      <mat-error *ngIf="complaintForm.get('incidentState').hasError('required')">
                        Please provide a state.
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field style="flex-basis: 0; flex-grow: 1; margin-right: 5px;">
                      <input matInput placeholder="Zip" formControlName="incidentZip" aria-label="Incident Zip">
                      <mat-error *ngIf="complaintForm.get('incidentZip').hasError('required')">
                        Please provide a zip.
                      </mat-error>
                    </mat-form-field>

                    <mat-form-field style="flex-basis: 0; flex-grow: 1; margin-right: 5px;">
                      <input matInput placeholder="County" formControlName="incidentCounty" aria-label="Incident County">
                      <mat-error *ngIf="complaintForm.get('incidentCounty').hasError('required')">
                        Please provide a county.
                      </mat-error>
                    </mat-form-field>
                  </div>
            </mat-card>
          
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" class="m-b-24">
            <span fxFlex></span>
            <button mat-raised-button type="submit" [disabled]="!complaintForm.valid" color="primary" (click)="submitComplaint()" aria-label="Submit Complaint">Submit</button>
            <button mat-stroked-button color="warn" type="button" (click)="cancel()" aria-label="Cancel Submission">Cancel</button>
          </div>
        </div>
        </mat-card>
      </form>
    </div>
  </div>

  <div [@slideLeft]  fxLayout="row" *ngIf="showSuccess">
    <mat-card [style.background]="uiData.complaint.color" fxFlex="100">
    <div fxLayout="column" fxFlexOffset="25" fxFlex="50" fxFlexOffset.lt-lg="5" fxFlex.lt-lg="90">
      <div fxLayout="column" fxLayoutGap="16px" class="m-t-32" style="font-family: Oswald, sans-serif; font-size: x-large">
        <div fxLayout="row" fxLayoutAlign="center center">
          <span style="font-size:xx-large"><strong>Thank You For Your Feedback</strong></span>          
        </div>
        <span>
          Please be patient with your complaint processing. Complaint investigations can can take weeks or months to reach
          a conclusion.
        </span>
        <button mat-stroked-button style="background-color: white;" color="warn" type="button" (click)="cancel()" aria-label="Close">Close</button>
      </div>
    </div>
    </mat-card>
  </div>
</div>
