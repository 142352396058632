import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-individual-address',
  templateUrl: './individual-address.component.html',
  styleUrls: ['./individual-address.component.scss']
})
export class IndividualAddressComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
