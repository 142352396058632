import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ANIMATIONS, LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { SharedService } from 'app/services/core/shared.service';
import { Subscription } from 'rxjs';

const MOBILE_MEDIAQUERY = "screen and (max-width: 599px)";
const TABLET_MEDIAQUERY = "screen and (min-width: 600px) and (max-width: 959px)";

export const DateFormats = {
  parse: {
    dateInput: ['MM/DD/YYYY']
  },
  display: {
    dateInput: 'MM/DD/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-complaints',
  templateUrl: './complaints.component.html',
  styleUrls: ['./complaints.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: DateFormats }],
  animations: ANIMATIONS
})

export class ComplaintsComponent implements OnInit {

  ngOnInit(): void {}  
  public entityTypes: string[] = ['Accredited Training Provider','Firm']
  public showForm: boolean = false;
  public showSuccess: boolean = false;
  public anonymous: boolean = false;
  public uiData = LEAD_BASED_PAINT;

  private formFieldList: string[] = ['complaintText','entityName','incidentDate','incidentStreet',
  'incidentCity','incidentState','incidentZip','incidentCounty','contactFirstName','contactLastName',
  'contactEmail','contactNumber','contactStreet','contactCity','contactState','contactZip','contactCounty'];
  private optionalValidationList: string[] = ['contactFirstName','contactLastName','contactEmail','contactNumber',
  'contactStreet','contactCity','contactState','contactZip','contactCounty'];

  private layoutChangesSubscription: Subscription;
  public today: Date = new Date(Date.now());
  public complaintForm = new UntypedFormGroup({
    contactFirstName: new UntypedFormControl("", Validators.required),
    contactLastName: new UntypedFormControl("", Validators.required),
    contactEmail: new UntypedFormControl("", Validators.required),
    contactNumber: new UntypedFormControl("", [Validators.required, Validators.pattern(/^\(?([0-9]{3})\)?[-]?([0-9]{3})[-]?([0-9]{4}).*$/)]),
    contactStreet: new UntypedFormControl("", Validators.required),
    contactCity: new UntypedFormControl("", Validators.required),
    contactState: new UntypedFormControl("", Validators.required),
    contactZip: new UntypedFormControl("", Validators.required),
    contactCounty: new UntypedFormControl("", Validators.required),
    // preferredContact: new UntypedFormControl("email", [Validators.required]),
    complaintText: new UntypedFormControl("", [Validators.required]),
    entityName: new UntypedFormControl(""),
    incidentDate: new UntypedFormControl(""),
    incidentStreet: new UntypedFormControl("", [Validators.required]),
    incidentCity: new UntypedFormControl("", [Validators.required]),
    incidentState: new UntypedFormControl("", [Validators.required]),
    incidentZip: new UntypedFormControl("", [Validators.required]),
    incidentCounty: new UntypedFormControl("", [Validators.required]),
  });

  constructor(public sharedService: SharedService,
              private breakpointObserver: BreakpointObserver,
  ){
              //public complaintService: any) {
    this.layoutChangesSubscription = this.breakpointObserver
      .observe([MOBILE_MEDIAQUERY, TABLET_MEDIAQUERY])
      .subscribe((state) => {
        this.sharedService.mobile = state.breakpoints[MOBILE_MEDIAQUERY];
        this.sharedService.tablet = state.breakpoints[TABLET_MEDIAQUERY];
      });
  }

  openForm(): void {
      this.showForm = true;
  }

  cancel() {
    this.showSuccess = false;
    this.showForm = false;
    this.anonymous = false;
    this.clearForm();
  }

  submitComplaint(): void {
    this.showSuccess = true;        

    let form = this.complaintForm.value;
    let complaint: any = {
      id: 0,
      entityType: '',
      name: form.name,
      contactEmail: form.contactEmail,
      contactNumber: form.contactNumber,
      preferredContact: form.preferredContact,
      identityConcern: form.identityConcern,
      parentId: '',
      parentName: '',
      complaintText: form.complaintText,
      previousReporting: form.previousReporting,
      signature: form.signature,
      incidentDate: form.incidentDate,
      parentLicenseName: form.parentLicenseName
    }
    /*this.complaintService.submitComplaint(complaint).subscribe(
      () => {
        this.selectedParent = 'Training Program';
        this.showSuccess = true;
        this.showForm = false;
        this.clearForm();
      },
      error => console.log('error', error)
    );*/
  }

  clearForm(): void {
    this.complaintForm.patchValue({
      complaintText: '',
      entityName: '',
      incidentDate: '',
      incidentStreet: '',
      incidentCity: '',
      incidentState: '',
      incidentZip: '',
      incidentCounty: '',
      contactFirstName: '',
      contactLastName: '',
      contactEmail: '',
      contactNumber: '',
      contactStreet: '',
      contactCity: '',
      contactState: '',
      contactZip: '',
      contactCounty: ''
    });
  }

  ngOnDestroy() {
    this.layoutChangesSubscription.unsubscribe();
  }

  updateValidators(){
    this.anonymous = !this.anonymous    
    const validators = !this.anonymous ? [Validators.required] : null;
    this.complaintForm.get('')

    this.optionalValidationList.forEach(x=>{
      this.complaintForm.get(x).setValidators(validators);
      this.complaintForm.get(x).updateValueAndValidity();
    });
  }
}
