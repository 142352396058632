import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent implements OnInit {
  processing: boolean = false;
  loading: boolean = false;
  pageSize = 50;  
  staff: {CertificateNumber: string,Name: string,Discipline: string, ExpirationDate: string}[] = [
    {CertificateNumber: '1234',Name: 'John Smith',Discipline: 'RRP Renovator', ExpirationDate: '11-22-2025'},
    {CertificateNumber: '9876',Name: 'David Doe',Discipline: 'Inspector', ExpirationDate: '11-12-2023'}
  ];
    
  uiData: any = LEAD_BASED_PAINT.staff;
  displayedColumns: string[] = ['CertificateNumber', 'Name', 'Discipline','ExpirationDate','actions'];
  dataSource = new MatTableDataSource<any>(this.staff);
  @ViewChild('TablePaginator') paginator: MatPaginator;
  @ViewChild('TableSort') sort: MatSort;

  filterTable(event: any) {
    //this.dataSource.filter = event.target.value.trim().toLowerCase();
  }

  constructor(
    public dialog: MatDialog,              
    private toastr: ToastrService,) 
    {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }
}
