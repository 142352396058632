import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { IFirmLicense } from 'app/models/firm/firm-license';
import { SharedService } from 'app/services/core/shared.service';
import { FirmService } from 'app/services/firm/firm.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss']
})
export class ApplicationsComponent implements OnInit {
  processing: boolean = false;
  loading: boolean = true;
  pageSize = 50;
  
  
  firmLicenseApplications: IFirmLicense[]=[];

  uiData: any = LEAD_BASED_PAINT.certificate;

  displayedColumns: string[] = ['id', 'status', 'applicationDate','applicationType'];//,'actions'];
  dataSource = new MatTableDataSource<any>(this.firmLicenseApplications);
  @ViewChild('TablePaginator') paginator: MatPaginator;
  @ViewChild('TableSort') sort: MatSort;

  filterTable(event: any) {
    //this.dataSource.filter = event.target.value.trim().toLowerCase();
  }

  constructor(
    public dialog: MatDialog,     
    private firmService: FirmService,
    public sharedService: SharedService,
    private router: Router,
    private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.loadFirmLicenseApplications();
  }

  loadFirmLicenseApplications() {
    this.firmLicenseApplications = this.firmService.firmLicenses.filter(x=>x.firm !== null && x.firm.id === this.firmService.affiliatedFirm.id);
    this.dataSource = new MatTableDataSource<any>(this.firmLicenseApplications);    
    this.loading = false;
  }

  viewApplication(data:any){    
    if(data !== null)      
      this.router.navigate(['firm/apply'], { state: data });
  }

  onResize(event) {}
}

