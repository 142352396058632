export const environment = {
  production: false,
  baseUrl: '',
  useHash: false,
  hmr: false,
  showThemeToggle: true,
  translateText: false,
  collapsibleSidebar: false,
  useTimeout: false,
  idleInterval: 900,
  build: 'Test',
  version: '1.0.0.0',
  displaySidebar: true,
  theme: 'light',
  publicApi: 'https://test-healthy-environments.oregon.gov/api/public/',
  privateApi: 'https://test-healthy-environments.oregon.gov/api/private/',
  b2c: {
    clientId: '6380bdb5-6bf1-4aa7-acbd-2fac940f784b',
    authority: 'https://orhealthyenvdevb2c.b2clogin.com/',
    authorityDomain:'orhealthyenvdevb2c.b2clogin.com',
    domain: 'orhealthyenvdevb2c.onmicrosoft.com/',
    signUpSignInPolicy: 'B2C_1_OHA_PH_HEALTHYENV_DEV_SUSI',
    editProfilePolicy: 'B2C_1_Edit_Profile',
    scopes: ['openid', 'https://orhealthyenvdevb2c.onmicrosoft.com/6380bdb5-6bf1-4aa7-acbd-2fac940f784b/User.Access'],
    redirectUri: 'https://test-healthy-environments.oregon.gov/',
    postLogoutRedirectUri: 'https://test-healthy-environments.oregon.gov/',
  }
};

