import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '@env/environment';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from '../../service/payment.service';

@Component({
  selector: 'app-payment-slip',
  templateUrl: './payment-slip.component.html'
})

export class PaymentSlipDialog implements OnInit {
  public generating: boolean = false;
  public applicationSlipReference: string = 'AOBJ 2082';
  public annualSlipReference: string = 'AOBJ 2105';
  public individualType: string = '';
  public feeType: string = '';
  public application: boolean = false;
  public annual: boolean = false;
  public reduced: boolean = false;
  public applicationFee: string = '$500';
  public annualFee: string = '$10,000';
  public reducedFee: string = '$5,000';
  public responsiblePartyName: string = ''
  public responsiblePartyPhone: string = ''
  public responsiblePartyEmail: string = ''
  public showTrainingProgram: boolean = false;
  public showFacilitator: boolean = false;
  public showWorkerPermit: boolean = false;
  public showManufacturer: boolean = false;
  public showServiceCenter: boolean = false;
  public showTestingLab: boolean = false;
  public isEntityLicense: boolean = false;
  public pageTitle:string = "";
  public licenseName:string = "";
  public licenseId: string = "";
  public applicationDate: string = "";

  constructor(public dialogRef: MatDialogRef<PaymentSlipDialog>,
              public paymentService: PaymentService,
              private toastr: ToastrService,
              @Inject(MAT_DIALOG_DATA) public data: any){}

  ngOnInit(): void {
    this.determineFeeType();
    this.determineEntityType();
  }

  determineFeeType(): void{
    switch(this.data.feeType){
      case this.paymentService.applicationFeeType:
        this.feeType = 'Application Fee';
        this.application = true;
        break;
      case this.paymentService.licenseFeeType:
        this.feeType = 'Annual Fee';
        this.annual = true;
        break;
      case this.paymentService.reducedLicenseFeeType:
        this.feeType = 'Reduced Annual Fee';
        this.reduced = true;
        break;
    }
  }

  determineEntityType(): void{
    switch (this.data.entityType) {
      
    }
  }

  cancel(): void {
    this.dialogRef.close('cancel');
  }

}
