
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute} from '@angular/router';
import { AuthService } from 'app/auth/auth.service';
import { PaymentService } from 'app/components/payments/service/payment.service';
import { SharedService } from 'app/services/core/shared.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-pay-online-success',
  templateUrl: './pay-online-success.component.html'
})

export class PayOnlineSuccessComponent implements OnInit {

  public processing: boolean = false;
  public isValid: boolean = true;
  public token: string = '';


  constructor(public paymentService: PaymentService,
              public sharedService: SharedService,
              public authService: AuthService,
              private toastr: ToastrService,
              private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.token = params.token;
    });
    this.processSuccess();
  }

  processSuccess() {
    if(this.token != undefined && this.token != null && this.token.length > 0) {
      this.processing = true;
      this.paymentService.updatePaymentSuccess(this.token).subscribe(result => {
          this.processing = false;
      }, error => {
        this.processing = false;
        console.log(error.message);
        this.toastr.error(error.message);
      });
    }
  }
}


