import { Component, OnInit } from '@angular/core';
import { UserInformationService } from 'app/services/user-info/user-info.service';
import { IAzureAdUser } from 'app/models/user-info/azure-ad-user';
import { AuthService } from 'app/auth/auth.service';
import { SharedService } from 'app/services/core/shared.service';

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {
  loading: boolean = false;
  public user: IAzureAdUser = {
    principalName: null,
    firstName: null,
    lastName: null,
    email: null,
    roles: []
  };

  loadingB2CProfile: boolean = false;

   constructor(private service: UserInformationService,
               public sharedService: SharedService,
               public authService: AuthService) { }

   ngOnInit(): void {
     this.authService.loadUserProfile();
     this.setRoles()
   }

   isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

   editProfile(): void{
     this.authService.editProfile();
   }

   public setRoles() {
    this.authService.getRoles().subscribe((response: any) => {
      this.user.roles = response;
    });
   }

}
