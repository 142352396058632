import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-individual-certificates',
  templateUrl: './individual-certificates.component.html',
  styleUrls: ['./individual-certificates.component.scss']
})
export class IndividualCertificatesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
