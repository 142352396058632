import { Component, OnInit, 
    ChangeDetectionStrategy} from '@angular/core';
import { environment } from '@env/environment';
import { AuthService } from 'app/auth/auth.service';
import { SharedService } from 'app/services/core/shared.service';

@Component({
selector: 'app-dashboard',
templateUrl: './dashboard.component.html',
styles: [],
providers: [],
})
export class DashboardComponent implements OnInit {
public environment = environment;

constructor(
public sharedService: SharedService,
public authService: AuthService
) {}

ngOnInit(): void {
}

onResize(event: any){
    
}
}
