import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { UserLicense } from 'app/models/user-info/user-license';

@Injectable({providedIn: 'root'})
export class UserInformationService {
  private api: string = environment.privateApi;
  constructor(private httpClient: HttpClient) { }

  getUserInfo(): Observable<any>{
    return this.httpClient.get<any>(this.api + "AzureIdentity/GetUserIdentity");
  }

  getUserLicenses() : Observable<UserLicense[]> {
    return this.httpClient.get<UserLicense[]>(this.api + "UserProfile/GetUserLicenses");
  }
}
