import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@shared';
import { PaymentNoticeComponent } from './components/notice/payment-notice-component';
import { PayDialog } from './components/pay/pay-component';
import { PaymentSlipDialog } from './components/payment-slip/payment-slip.component';
import { PaymentsComponent } from './components/payments/payments.component';
import { PayOnlineComponent } from './components/pay-online/pay-online.component';
import { PayOnlineCancelComponent } from './components/pay-online/cancel/pay-online-cancel.component';
import { PayOnlineFailureComponent } from './components/pay-online/failure/pay-online-failure.component';
import { PayOnlineDuplicateComponent } from './components/pay-online/duplicate/pay-online-duplicate.component';
import { PayOnlineSuccessComponent } from './components/pay-online/success/pay-online-success.component';

const COMPONENTS = [
    PaymentNoticeComponent,
    PayDialog,
    PaymentSlipDialog,
    PaymentsComponent,
    PayOnlineComponent,
    PayOnlineCancelComponent,
    PayOnlineFailureComponent,
    PayOnlineDuplicateComponent,
    PayOnlineSuccessComponent
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports: [PaymentSlipDialog,PaymentNoticeComponent, PayDialog, PaymentsComponent, PayOnlineComponent, PayOnlineCancelComponent,PayOnlineFailureComponent,PayOnlineDuplicateComponent, PayOnlineSuccessComponent ]
})
export class PaymentsModule { }
