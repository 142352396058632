import { LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { BehaviorSubject } from 'rxjs';
import { IMenu } from './models/core/menu';

export const menu$ = new BehaviorSubject<IMenu[]>([
    {
      route: "home",
      name: "My Certificates",
      type: "link",
      icon: "home"
    },
    {
      route: "profile",
      name: "Admin (Auth & Role Protected)",
      type: "link",
      icon: "hive",
      roles: ["Admin"]
    },
    
    {
      route: "firm",
      name: "Firm",
      type: "sub",
      icon: LEAD_BASED_PAINT.firm.icon,
      auth: true,
      children: [
        {
          route: "/manage",
          name: "Manage",
          type: "link",
        },
        {
          route: "/apply",
          name: "Apply",
          type: "link"
        },
      ]
    },
    // {
    //   route: "atp",
    //   name: "Training Provider",
    //   type: "link",
    //   icon: LEAD_BASED_PAINT.atp.icon,
    //   auth: true
    // },
    {
      route: "individual",
      name: "Individual",
      type: "sub",
      icon: LEAD_BASED_PAINT.individual.icon,
      auth: true,
      children: [
        {
          route: "/manage",
          name: "Manage",
          type: "link",
        },
        {
          route: "/apply",
          name: "Apply",
          type: "link"
        },
      ]
    },
    // {
    //   route: "apply",
    //   name: "Apply",
    //   type: "link",
    //   icon: LEAD_BASED_PAINT.apply.icon,
    //   auth: true
    // },
    
    {
      route: "payments",
      name: "Payments",
      type: "link",
      icon: "attach_money"
    },
    {
      route: "complaints",
      name: "Complaint",
      type: "link",
      icon: LEAD_BASED_PAINT.complaint.icon,
    },
  ]);
