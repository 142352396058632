<div *ngIf="loading">
    <div [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
      <h1>Loading</h1>
    </div>
</div>

<div  *ngIf="!loading">
    <!--Header -->
    <mat-card class="sticky-header p-y-0" [style.background-color]="uiData.individual.color" *ngIf="!showConfirmed">
        <mat-card-content>  
        <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
            <mat-icon class="medium-icon m-24">{{uiData.firm.icon}}</mat-icon>
            <span class="f-s-20">{{currentHeader}}</span>
        </div>    
        </mat-card-content>   
    </mat-card>
    <!--End of Header -->

    <mat-card class="sticky-header p-y-0" [style.background-color]="'red'" *ngIf="false">
        <mat-card-content>  
            <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center start" >
                <ul>
                    <li class="f-s-20" style="color: white" *ngFor="let deficiency of personLicenseDeficiencies">{{ deficiency.description }}</li>
                </ul>
            </div>       
        </mat-card-content>   
    </mat-card>
    
    
        
    <div fxLayout="column" fxLayoutGap="8px" [ngStyle]="{'--primary-color': '#007bff'}">      
        <div fxLayout="column" fxLayoutAlign="start center" *ngIf="!applicationTypeSelected">
            <div style="width: 95%" fxLayout="column" fxLayoutGap="8px">
                <span>
                Detailed information regarding Lead Paint Regulations can be found online.
                </span>
                <span>
                    Please visit
                    <a href="https://www.oregon.gov/oha/PH/HEALTHYENVIRONMENTS/HEALTHYNEIGHBORHOODS/LEADPOISONING/Pages/Lead-BasedPaintRegulations.aspx"
                        target="_blank"
                        rel="noopener"
                        style="word-break: break-all; white-space: normal;">
                        https://www.oregon.gov/oha/PH/HEALTHYENVIRONMENTS/HEALTHYNEIGHBORHOODS/LEADPOISONING/Pages/Lead-BasedPaintRegulations.aspx
                    </a>
                    to access this information.
                </span>
            </div>            
            <div fxFlex fxLayout="row" fxLayoutGap="8px" style="margin-top: 25px">
                <div fxFlex="50">
                    <mat-card fxLayout="column">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        <div fxLayout="row" fxLayoutAlign="center">
                            <button mat-flat-button type="button" color="primary" (click)="changeForm(applyHeader)">
                                {{this.personLicense ? (this.personLicense.id !== 0 && !existingPersonAccountAffiliation) ? (this.canEdit ? "Continue Individual certificate application" : 'View Individual certificate application') : "Apply for a new Individual certificate" : "Apply for a new Individual certificate"}}
                            </button>
                        </div>
                    </mat-card>
                </div>
                <div *ngIf="!existingPersonAccountAffiliation" fxFlex="50">
                    <mat-card fxLayout="column">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                        <div fxLayout="row" fxLayoutAlign="center">
                            <button mat-flat-button type="button" color="primary" (click)="changeForm(linkHeader)">
                                Link to an existing Individual
                            </button>
                        </div>
                    </mat-card>
                </div>
            </div>
        </div>
            
        <mat-stepper [orientation]="sharedService.isMobile? 'vertical' : 'horizontal'" #firmStepper [selectedIndex]="step" *ngIf="applicationTypeSelected && !showConfirmed"
        (selectionChange)="changeStep($event)">
        
            <mat-step>
                <ng-template matStepLabel>
                    <span>Start Here</span>          
                </ng-template>      
                <div fxLayout="column" fxLayoutGap="8px" class="p-b-8">          
                    <div style="display: flex; justify-content: center;">
                    <div style="text-align: center;">
                        <h2>Select a Firm Certificate Application Type</h2>
                    </div>
                    </div>
                    
                    
                    <br>
                    <div fxLayout="column" fxLayoutGap="8px">
                    <span>
                        More detailed information regarding Individual Certifications can be found online
                    </span>
                    <span>
                        Please visit <a href="https://www.oregon.gov/oha/PH/HEALTHYENVIRONMENTS/HEALTHYNEIGHBORHOODS/LEADPOISONING/INSPECTIONABATEMENTPROFESSIONALS/Pages/index.aspx#eparegs"
                        target="_blank" rel="noreferrer noopener"
                        style="word-break: break-all;
                        white-space: normal;">https://www.oregon.gov/oha/PH/HEALTHYENVIRONMENTS/HEALTHYNEIGHBORHOODS/LEADPOISONING/INSPECTIONABATEMENTPROFESSIONALS/Pages/index.aspx#eparegs</a>
                        to access this information.
                    </span>
                    </div>
                
                    <div fxLayout="row" fxLayout.lt-lg="column" style="padding-top: 1em" fxLayoutGap="12px">              
                    </div>
                    <div fxLayout="column" fxLayoutGap="8px" style="padding-top: 1em">
                    <strong>The completed application packet contains the following::</strong>
                    <span class="p-l-24">
                        <strong>• </strong>Completed and signed application              
                    </span>
                    <span class="p-l-24">
                        <strong>• </strong>{{'Non-refundable three-year certification application fee'}}
                    </span>
                    <span class="p-l-24">
                        <strong>• </strong>Digital copy of current renovation training certificate for each Certified Renovator.
                    </span>
                    </div>
                    <div fxLayout="row" style="padding-top: 2em">
                    <div fxLayout="column" fxLayoutGap="8px">
                        <strong>Important Notes: </strong>
                        <span class="p-l-24">
                        <strong>• </strong>Please review each section of the application to ensure it is complete
                        </span>
                        <span class="p-l-24">
                        <strong>• </strong>A fee page will describe when and how to submit the application fee which may be paid
                        online or in-person
                        </span>
                        <span class="p-l-24">
                        <strong>• Incomplete and Insufficient Application Statuses:</strong> Please review the application guide
                        for more detailed information on all application statuses.
                        </span>
                        <div fxLayout="column" style="padding-left: 1em;" fxLayoutGap="8px">
                        <span class="p-l-24">
                            <strong>• </strong>Incomplete: An application is considered incomplete if the application is missing
                            required information (e.g., the applicant did not required documents).
                            If the application is incomplete, we will notify you by email to give you an opportunity to make your
                            application complete. You have 90 days from the initial date you are notified to complete your
                            application. If you fail to do so, the application will be closed.
                        </span>
                        <span class="p-l-24">
                            <strong>• </strong>Insufficient: An application is considered insufficient if the complete application 
                            does not meet requirements outlined in statute or rule. If the application is
                            insufficient, we will notify you by email to give you an opportunity to meet requirements. You will have 90 days from
                            the initial date you are notified to meet requirements, or your application will be denied.
                        </span>
                        </div>
                    </div>
                    </div>
                
                    <div fxLayout="column" fxLayoutGap="8px" style="padding-top: 2em">
                    <span>
                        <strong>When you click the continue button at the bottom of this page, you will proceed to the Individual application.</strong> 
                        The application automatically saves. You may exit the application and come back later to complete and submit the application, if needed.
                    </span>
                    </div>
                    <div fxLayout="column" fxLayoutGap="8px" style="padding-top: 2em;">
                    <span>
                        <strong>DISCLAIMER:</strong> The information you provide in your application
                        materials is subject to disclosure under Oregon's public records laws.
                    </span>
                    </div>

                    <div fxLayout="row">
                    <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="start center" fxLayoutGap="4px">
                        <button mat-raised-button matStepperNext color="primary" *ngIf="personLicense.id !== 0">Continue</button>
                        <button mat-raised-button type="button" color="primary" *ngIf="personLicense.id === 0" (click)="startPersonLicenseApplication()">Start Application</button>
                    </div>
                    
                    <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="end center" fxLayoutGap="4px">
                        <button mat-raised-button color="warn" *ngIf="personLicense.id !== 0" (click)="cancelApplication()">{{personLicense.status !== "Submitted" ? "Cancel Application" : "Withdraw Application"}}</button>
                        <button mat-raised-button (click)="exitApplication(false)">Exit</button>
                    </div>
                    </div> 
                </div>
            </mat-step> 
            
            <mat-step [stepControl]="licenseApplicationDataForm" *ngIf="personLicense !== null">        
                <ng-template matStepLabel>
                  <span class="text-red-400" *ngIf="!licenseApplicationDataForm.valid && licenseApplicationDataForm.touched">
                    Individual Information Incomplete</span>
                  <span *ngIf="licenseApplicationDataForm.valid || !licenseApplicationDataForm.touched">Individual Information</span>                          
                </ng-template>

                <div fxLayout="column">
                    <form [formGroup]="licenseApplicationDataForm">
                        <div fxLayout="column">
                            <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                            <mat-card fxFlex="100" fxFlex.lt-lg="100">
                                <mat-card-title>
                                <div fxLayout="row" fxLayout.lt-lg="column">
                                    <span fxFlex>Individual Information</span>
                                </div>
                                </mat-card-title>
                                <mat-card-content>
                                    <div fxLayout="column" fxLayoutGap="8px">
                                        <span><strong>Legal Name</strong></span>
                                            
                                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-lg="">
                                        <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                                            <input matInput placeholder="First" formControlName="firstName">
                                            <mat-error *ngIf="licenseApplicationDataForm.get('firstName').invalid">
                                            Please enter your first name
                                            </mat-error>
                                        </mat-form-field>
                            
                                        <mat-form-field fxFlex="40" fxFlex.lt-md="100">
                                            <input matInput placeholder="Last" formControlName="lastName">
                                            <mat-error *ngIf="licenseApplicationDataForm.get('lastName').invalid">
                                            Please enter your last name
                                            </mat-error>
                                        </mat-form-field>
                            
                                        <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                                            <input matInput placeholder="Middle" formControlName="middleName">
                                        </mat-form-field>
                            
                                        <mat-form-field fxFlex="20" fxFlex.lt-md="100">
                                            <input matInput placeholder="Title" formControlName="title">
                                        </mat-form-field>
                                        </div>
                            
                                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-lg="">
                                            <span fxFlex="50"><strong>SSN</strong></span>
                                            <span fxFlex="50"><strong>Date of Birth</strong></span>
                                        </div>
                                        
                                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="8px" fxLayoutGap.lt-lg="">
                                            <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                                                <input matInput placeholder="XXX-XX-XXXX" formControlName="ssn">
                                                <mat-error *ngIf="licenseApplicationDataForm.get('ssn').invalid">
                                                Please enter your social security number
                                                </mat-error>
                                            </mat-form-field>
                                
                                            <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                                                <input matInput [matDatepicker]="dobPicker" placeholder="Date of Birth" formControlName="dateOfBirth" (focus)="dobPicker.open()">
                                                <mat-datepicker-toggle matSuffix [for]="dobPicker"></mat-datepicker-toggle>
                                                <mat-datepicker #dobPicker></mat-datepicker>
                                                <mat-error *ngIf="licenseApplicationDataForm.get('dateOfBirth').invalid">
                                                    Please enter your Date of Birth
                                                </mat-error>
                                            </mat-form-field>                                            
                                            
                                            </div>

                                            <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                                                <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                                                    <span class="f-2-20"><strong>Public Listing</strong></span>
                                                    <mat-checkbox class="mat-checkbox" formControlName="isPublic">
                                                    <span class="mat-checkbox-label">
                                                        Check this box if you want to be published on the list we post on our website.
                                                    </span>
                                                    </mat-checkbox>
                                                </div>                          
                                                </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        
                            
                        
                            
                            </div>
                            <mat-card fxFlex="50" fxFlex.lt-lg="100">
                                <mat-card-title>
                                    Contact Information
                                </mat-card-title>
                                <mat-card-content>
                                <div fxLayout="column" fxLayoutGap="8px">
                        
                        
                                    <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                                        <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                                            <span class="f-2-20"><strong>Primary Phone</strong></span>
                                            <mat-form-field>
                                            <input matInput placeholder="Phone" formControlName="phone" mask="(000) 000-0000 000000">
                                                <!-- [validation]="false" -->
                                            <mat-error *ngIf="licenseApplicationDataForm.get('phone').hasError('required')">
                                                A primary phone number is required
                                            </mat-error>
                                            <mat-error *ngIf="licenseApplicationDataForm.get('phone').hasError('pattern')">
                                                Phone should match this format: 999-999-9999 x1234
                                            </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                                            <span class="f-2-20"><strong>Alternate Phone</strong></span>
                                            <mat-form-field>
                                            <input matInput placeholder="Alt Phone" formControlName="alternatePhone">
                                                <!-- mask="(000) 000-0000 000000" [validation]="false" -->
                                            <mat-error *ngIf="licenseApplicationDataForm.get('alternatePhone').hasError('pattern')">
                                                Phone should match this format: 999-999-9999 x1234
                                            </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                                            <span class="f-2-20"><strong>Fax Phone</strong></span>
                                            <mat-form-field>
                                            <input matInput placeholder="Fax Phone" formControlName="faxPhone">
                                                <!-- mask="(000) 000-0000 000000" [validation]="false" -->
                                            <mat-error *ngIf="licenseApplicationDataForm.get('faxPhone').hasError('pattern')">
                                                Phone should match this format: 999-999-9999 x1234
                                            </mat-error>
                                            </mat-form-field>
                                        </div>
                                        </div>
                            
                                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                                        <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                                            <span class="f-2-20"><strong>Primary Email Address</strong></span>
                                            <mat-form-field>
                                            <input matInput placeholder="Email" formControlName="email">
                                            <mat-error *ngIf="licenseApplicationDataForm.get('email').hasError('required')">
                                                Please enter an email adress
                                            </mat-error>
                                            <mat-error *ngIf="licenseApplicationDataForm.get('email').hasError('email')">
                                                This doesn't look like a valid email address
                                            </mat-error>
                                            </mat-form-field>
                                        </div>
                                        <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                                            <span class="f-2-20"><strong>Confirm Email Address</strong></span>
                                            <mat-form-field>
                                            <input matInput placeholder="Confirm Email" formControlName="confirmEmail">
                                            <mat-error *ngIf="firmService.validateEmail('confirmEmail',licenseApplicationDataForm)">
                                                Email does not match
                                            </mat-error>
                                            </mat-form-field>
                                        </div>
                                        </div> 
                                        
                                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                                            <div fxLayout="column" fxFlex="50" fxFlex.lt-lg="100">
                                                <span class="f-2-18"><strong>Website</strong></span>
                                                <mat-form-field>
                                                <input matInput placeholder="Website" formControlName="website">
                                                </mat-form-field>
                                            </div>
                                        </div>
                                </div>
                                </mat-card-content>
                            </mat-card>
                        
                            <mat-card fxFlex="50" fxFlex.lt-lg="100">
                                <mat-card-title>
                                Address Information
                                </mat-card-title>
                                <mat-card-content>
                                    <div fxLayout="column">
                                        <span><strong>Mailing Address</strong></span>
                                        <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
                                        <mat-form-field fxFlex="50" fxFlex.lt-md="100">
                                            <input matInput placeholder="Street Apt/Unit/Suite" formControlName="mailingStreet">
                                            <mat-error *ngIf="licenseApplicationDataForm.get('mailingStreet').invalid">
                                            Please provide a street
                                            </mat-error>
                                        </mat-form-field>
                            
                                        <mat-form-field fxFlex="25" fxFlex.lt-md="100">
                                            <input matInput placeholder="City" formControlName="mailingCity">
                                            <mat-error *ngIf="licenseApplicationDataForm.get('mailingCity').invalid">
                                            Please provide a city
                                            </mat-error>
                                        </mat-form-field>
                            
                                        <mat-form-field fxFlex="10" fxFlex.lt-md="100">
                                            <mat-label style="color:red" *ngIf="licenseApplicationDataForm.get('mailingState').value == ''">State</mat-label>
                                            <mat-label *ngIf="licenseApplicationDataForm.get('mailingState').value != ''">State</mat-label>
                                            <mat-select formControlName="mailingState">
                                            <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                                            </mat-select>
                                            <mat-error *ngIf="licenseApplicationDataForm.get('mailingState').invalid">
                                            Please select a state
                                            </mat-error>
                                        </mat-form-field>
                            
                                        <mat-form-field fxFlex="15" fxFlex.lt-md="100">
                                            <input matInput placeholder="Zip" formControlName="mailingZip">
                                            <mat-error *ngIf="licenseApplicationDataForm.get('mailingZip').hasError('required')">
                                            Please provide a zipcode
                                            </mat-error>
                                            <mat-error *ngIf="licenseApplicationDataForm.get('mailingZip').hasError('pattern')">
                                            Zipcode should match this format: 99999 or 99999-1234
                                            </mat-error>
                                        </mat-form-field>
                            
                                        <mat-form-field fxFlex="20" fxFlex.lt-md="100" *ngIf="licenseApplicationDataForm.get('mailingState').value === 'OR'">
                                            <mat-label>County</mat-label>
                                            <mat-select formControlName="mailingCounty">
                                            <mat-option>-- None --</mat-option>
                                            <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        </div>
                                    </div>
                            
                                    
                                </mat-card-content>
                            </mat-card>                                                                                                    
                        </div>
                        </form>

                  <div fxLayout="row">
                    <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="start center" fxLayoutGap="4px">
                      <button mat-raised-button matStepperPrevious>Back</button>
                      <button mat-raised-button color="{{canEdit ? 'primary' : ''}}" matStepperNext>{{canEdit ? "Save and Continue" : "Continue"}}</button>
                    </div>
      
                    <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="end center" fxLayoutGap="4px">
                      <button mat-raised-button color="warn" *ngIf="personLicense.id !== 0" (click)="cancelApplication()">{{personLicense.status !== "Submitted" ? "Cancel Application" : "Withdraw Application"}}</button>
                      <button mat-raised-button (click)="exitApplication(true)">Exit</button>
                    </div>
                  </div> 
                </div>
            </mat-step>

            <mat-step [stepControl]="licenseApplicationDataForm" *ngIf="personLicense !== null">
                <ng-template matStepLabel>
                    <span>Individual Training</span>          
                </ng-template> 
                <div fxLayout="column">
                    <div fxLayout="column" fxLayoutGap="8px" fxFlex="100" fxFlex.lt-lg="100">
                        <span *ngIf="sharedService.tablet" class="f-s-18">{{'Individual Training'}}</span>
                    
                        <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                          <div fxFlex="50" fxFlex.lt-sm="100" class="matero-col" fxLayoutAlign="start">
                            <h2 class="noMarginHeader">
                              {{'Individual Training'}}
                            </h2>
                          </div>
                        </div>                    
                        <ul>
                          <li>Add your individual training records if any.</li>
                          <li>A certificate is required for each training.</li>                          
                        </ul>
                        <br />
                        <mat-card>
                          <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;" fxLayout="column">
                              <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                  <div>
                                      <strong class="noMarginHeader">Results ({{trainingTable.layout.data.length}})</strong> 
                                      &nbsp;&nbsp;&nbsp;&nbsp;     
                                      <mat-form-field style="width:200px;">
                                      <input (keyup)="filterTrainingTable($event)" placeholder="Filter Results" matInput />
                                      <input placeholder="Filter Courses" matInput />
                                      </mat-form-field>      
                                  </div>
                              <button mat-raised-button color="primary" *ngIf="canEdit" (click)="editTrainingEntry($event,null)">
                                  <mat-icon>add</mat-icon> {{'Add Training'}}
                              </button>      
                          </div>
                          <br />
                    
                          <div [fxFlex]="100" style="overflow-x: auto;">
                            <mat-table [dataSource]="trainingTable.layout.data" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                              <mat-header-row *matHeaderRowDef="trainingTable.layout.columns.concat('Documents','Actions')"></mat-header-row>
                              <mat-row [ngClass]="sharedService.isMobile ? 'mobile-row':''" *matRowDef="let row; index as i; columns: trainingTable.layout.columns.concat('Documents','Actions');"
                              (click)="editTrainingEntry($event,personTrainingEntries[i])">
                              </mat-row>
                          
                              <ng-container *ngFor="let cellData of trainingTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                <mat-header-cell *matHeaderCellDef>{{cellData.displayName}}</mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                  <ng-container *ngIf="cellData.type === 'date'">
                                    {{row[cellData.columnName] | date:'shortDate'}}
                                  </ng-container>
                                  <ng-container *ngIf="cellData.type === 'boolean'">
                                    <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="!canEdit"></mat-checkbox>
                                  </ng-container>
                                  <ng-container *ngIf="cellData.type !== 'date' && cellData.type !== 'boolean'">
                                    {{row[cellData.columnName]}}
                                  </ng-container>
                                </mat-cell>
                              </ng-container>                                           
                          
                              <ng-container matColumnDef="Documents">
                                <mat-header-cell *matHeaderCellDef fxFlex.lt-sm="100" >Documents</mat-header-cell>
                                <mat-cell *matCellDef="let row" fxFlex.lt-sm="100" fxLayout="column" class="left-align">
                                  <div class="button-container" *ngFor="let documentName of row['Documents']">
                                    <div (click)="$event.stopPropagation()">
                                      <button mat-icon-button class="stop-propagation" color="primary" aria-label="Download File" (click)="downloadTrainingFile(documentName)">
                                        <mat-icon>save_alt</mat-icon>
                                      </button>                              
                                      <span>{{documentName | fileDisplayName:20}}</span>                              
                                      <button *ngIf="canEdit" mat-icon-button class="stop-propagation" color="warn" aria-label="Delete" (click)="clearTrainingFile(row,documentName)">
                                        <mat-icon>delete</mat-icon>
                                      </button> 
                                    </div>
                                  </div>
                                </mat-cell>
                              </ng-container>
                              
                              <ng-container matColumnDef="Actions">
                                <mat-header-cell *matHeaderCellDef fxFlex="12">Actions</mat-header-cell>
                                <mat-cell *matCellDef="let row; let i = index" fxFlex="12">
                                  <div (click)="$event.stopPropagation()">
                                    <button [disabled]="!canEdit" mat-raised-button color="primary" class="stop-propagation" aria-label="Add Document" (click)="fileUpload.click()">
                                      Attach <mat-icon>attach_file</mat-icon>
                                    </button>
                                  </div>
                                  <div (click)="$event.stopPropagation()">
                                    <button mat-icon-button color="warn" class="stop-propagation" aria-label="Delete" *ngIf="canEdit" (click)="deleteTraining(row)">
                                      <mat-icon>delete</mat-icon>
                                    </button>
                                  </div>
                                  <!-- The class for the input html prevents the click propagation.  editInvidual() is still called on the parent element but the class is checked to prevent propagation-->
                                  <input type="file" #fileUpload style="display:none" class="stop-propagation" (change)="onTrainingFileSelected($event, row)"> 
                                </mat-cell>
                              </ng-container>
                            </mat-table>
                          </div>                    
                                            
                            <br />
                            <mat-paginator [pageSize]="trainingPageSize" [pageSizeOptions]="[10, 50, trainingTable.layout.data.length]" #TablePaginator="matPaginator"
                              showFirstLastButtons></mat-paginator>
                          </div>
                        </mat-card>
                    
                      </div>
                      
                      <div fxLayout="row">
                        <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="start center" fxLayoutGap="4px">
                          <button mat-raised-button matStepperPrevious>Back</button>
                          <button mat-raised-button matStepperNext color="primary">Continue</button>
                        </div>
                      
                        <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="end center" fxLayoutGap="4px">
                          <button mat-raised-button color="warn" *ngIf="personLicense.id !== 0" (click)="cancelApplication()">{{personLicense.status !== "Submitted" ? "Cancel Application" : "Withdraw Application"}}</button>
                          <button mat-raised-button (click)="exitApplication(false)">Exit</button>
                        </div>
                      </div>
                    </div> 
            </mat-step>

            <mat-step [stepControl]="licenseApplicationDataForm" *ngIf="personLicense !== null">
                <ng-template matStepLabel>
                    <span>Individual Testing</span>          
                </ng-template> 
                <div fxLayout="column">
                    <div fxLayout="column" fxLayoutGap="8px" fxFlex="100" fxFlex.lt-lg="100">
                        <span *ngIf="sharedService.tablet" class="f-s-18">{{'Individual Testing'}}</span>
                    
                        <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                          <div fxFlex="50" fxFlex.lt-sm="100" class="matero-col" fxLayoutAlign="start">
                            <h2 class="noMarginHeader">
                              {{'Individual Testing'}}
                            </h2>
                          </div>
                        </div>                    
                        <ul>
                          <li>Add your individual testing records if any.</li>
                          <li>A certificate is required for each test entry.</li>                          
                        </ul>
                        <br />
                        <mat-card>
                          <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;" fxLayout="column">
                              <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
                                  <div>
                                      <strong class="noMarginHeader">Results ({{testingTable.layout.data.length}})</strong> 
                                      &nbsp;&nbsp;&nbsp;&nbsp;     
                                      <mat-form-field style="width:200px;">
                                      <input (keyup)="filterTestTable($event)" placeholder="Filter Results" matInput />
                                      <input placeholder="Filter Courses" matInput />
                                      </mat-form-field>      
                                  </div>
                              <button mat-raised-button color="primary" *ngIf="canEdit" (click)="editTestEntry($event,null)">
                                  <mat-icon>add</mat-icon> {{'Add Testing'}}
                              </button>      
                          </div>
                          <br />
                    
                          <div [fxFlex]="100" style="overflow-x: auto;">
                            <mat-table [dataSource]="testingTable.layout.data" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                              <mat-header-row *matHeaderRowDef="testingTable.layout.columns.concat('Documents','Actions')"></mat-header-row>
                              <mat-row [ngClass]="sharedService.isMobile ? 'mobile-row':''" *matRowDef="let row; index as i; columns: testingTable.layout.columns.concat('Documents','Actions');"
                              (click)="editTestEntry($event,personTestingEntries[i])">
                              </mat-row>
                          
                              <ng-container *ngFor="let cellData of testingTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                <mat-header-cell *matHeaderCellDef>{{cellData.displayName}}</mat-header-cell>
                                <mat-cell *matCellDef="let row">
                                  <ng-container *ngIf="cellData.type === 'date'">
                                    {{row[cellData.columnName] | date:'shortDate'}}
                                  </ng-container>
                                  <ng-container *ngIf="cellData.type === 'boolean'">
                                    <mat-checkbox [(ngModel)]="row[cellData.columnName]" [disabled]="!canEdit"></mat-checkbox>
                                  </ng-container>
                                  <ng-container *ngIf="cellData.type !== 'date' && cellData.type !== 'boolean'">
                                    {{row[cellData.columnName]}}
                                  </ng-container>
                                </mat-cell>
                              </ng-container>                                           
                          
                              <ng-container matColumnDef="Documents">
                                <mat-header-cell *matHeaderCellDef fxFlex.lt-sm="100" >Documents</mat-header-cell>
                                <mat-cell *matCellDef="let row" fxFlex.lt-sm="100" fxLayout="column" class="left-align">
                                  <div class="button-container" *ngFor="let documentName of row['Documents']">
                                    <div (click)="$event.stopPropagation()">
                                      <button mat-icon-button class="stop-propagation" color="primary" aria-label="Download File" (click)="downloadTestFile(documentName)">
                                        <mat-icon>save_alt</mat-icon>
                                      </button>                              
                                      <span>{{documentName | fileDisplayName:20}}</span>                              
                                      <button *ngIf="canEdit" mat-icon-button class="stop-propagation" color="warn" aria-label="Delete" (click)="clearTestFile(row,documentName)">
                                        <mat-icon>delete</mat-icon>
                                      </button> 
                                    </div>
                                  </div>
                                </mat-cell>
                              </ng-container>
                              
                              <ng-container matColumnDef="Actions">
                                <mat-header-cell *matHeaderCellDef fxFlex="12">Actions</mat-header-cell>
                                <mat-cell *matCellDef="let row; let i = index" fxFlex="12">
                                  <div (click)="$event.stopPropagation()">
                                    <button [disabled]="!canEdit" mat-raised-button color="primary" class="stop-propagation" aria-label="Add Document" (click)="fileUpload.click()">
                                      Attach <mat-icon>attach_file</mat-icon>
                                    </button>
                                  </div>
                                  <div (click)="$event.stopPropagation()">
                                    <button mat-icon-button color="warn" class="stop-propagation" aria-label="Delete" *ngIf="canEdit" (click)="deleteTesting(row)">
                                      <mat-icon>delete</mat-icon>
                                    </button>
                                  </div>
                                  <!-- The class for the input html prevents the click propagation.  editInvidual() is still called on the parent element but the class is checked to prevent propagation-->
                                  <input type="file" #fileUpload style="display:none" class="stop-propagation" (change)="onTestFileSelected($event, row)"> 
                                </mat-cell>
                              </ng-container>
                            </mat-table>
                          </div>                    
                                            
                            <br />
                            <mat-paginator [pageSize]="testingPageSize" [pageSizeOptions]="[10, 50, testingTable.layout.data.length]" #TablePaginator="matPaginator"
                              showFirstLastButtons></mat-paginator>
                          </div>
                        </mat-card>
                    
                      </div>
                      
                      <div fxLayout="row">
                        <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="start center" fxLayoutGap="4px">
                          <button mat-raised-button matStepperPrevious>Back</button>
                          <button mat-raised-button matStepperNext color="primary">Continue</button>
                        </div>
                      
                        <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="end center" fxLayoutGap="4px">
                          <button mat-raised-button color="warn" *ngIf="personLicense.id !== 0" (click)="cancelApplication()">{{personLicense.status !== "Submitted" ? "Cancel Application" : "Withdraw Application"}}</button>
                          <button mat-raised-button (click)="exitApplication(false)">Exit</button>
                        </div>
                      </div>
                    </div> 
            </mat-step>

            <mat-step [stepControl]="licenseApplicationDataForm" *ngIf="personLicense !== null">
                <ng-template matStepLabel>
                    <span>Fee Information</span>          
                </ng-template> 
                <div fxLayout="column">
                  Fee PlaceHolder
                </div> 
            </mat-step>

            <mat-step [stepControl]="licenseApplicationDataForm" *ngIf="personLicense !== null">
                <ng-template matStepLabel>
                    <span>Review and Submit</span>          
                </ng-template> 
                                
                <form [formGroup]="licenseApplicationAttestationForm">
                  <mat-card>
                    <mat-card-title>
                      <span>Review Your Application</span>
                    </mat-card-title>
                    <mat-card-content>
                      <div fxLayout="column">
                        <div>
                          <mat-card fxFlex="100" fxFlex.lt-lg="100">
                            <mat-card-title>
                              Individual Information
                            </mat-card-title>
                            <mat-card-content>
                              <div fxLayout="column" fxLayoutGap="8px">
                                <div fxLayout="column" fxLayout.lt-lg="column" fxLayoutGap="8px">                                  
                                  <div fxFlex fxLayout="column" fxLayoutAlign="space-between">
                                    <div><strong>First Name:</strong> {{personLicense.firstName}}</div>
                                    <div><strong>Middle Name:</strong> {{personLicense.middleName}}</div>
                                    <div><strong>Last Name:</strong> {{personLicense.lastName}}</div>
                                    <div><strong>Title:</strong> {{personLicense.title}}</div>
                                    <br>
                                    <div><strong>SSN:</strong> {{personLicense.ssn}}</div>
                                    <div><strong>Date of Birth:</strong> {{personLicense.dateOfBirth | date:'shortDate'}}</div>
                                    <div style="display: flex; align-items: center;">
                                      <strong style="margin-right: 10px;">Public Listing:</strong>
                                      <mat-checkbox [checked]="personLicense.isPublic" [disabled]="true"></mat-checkbox>
                                    </div>
                                    
                                  </div>                                  
                                </div>
                              </div>
                            </mat-card-content>
                          </mat-card>
                        </div>
                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                          <mat-card fxFlex="100" fxFlex.lt-lg="100">
                            <mat-card-title>
                              Contact Information
                            </mat-card-title>
                            <mat-card-content>
                              <div fxLayout="column" fxLayoutGap="8px">
                                <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">                            
                                  <div fxLayout="column">
                                    <div style="margin-top: 8px;" fxLayout="row" fxLayoutGap="8px">
                                      <span><strong>Primary Phone:</strong></span>                                 
                                      <span>{{personLicense.phone}}</span>
                                    </div>                              
                                    <div fxLayout="row" fxLayoutGap="8px">
                                      <span><strong>Alternate Phone:</strong></span>                              
                                      <span>{{personLicense.alternatePhone}}</span>
                                    </div>
                                    <div fxLayout="row" fxLayoutGap="8px">
                                      <span><strong>Fax Phone:</strong></span>                              
                                      <span>{{personLicense.faxPhone}}</span>
                                    </div>
                                    <div fxLayout="row" fxLayoutGap="8px">
                                      <span><strong>Email:</strong></span>
                                      <span>{{personLicense.email}}</span>
                                    </div>
                                    <div fxLayout="row" fxLayoutGap="8px">
                                      <span><strong>Website:</strong></span>
                                      <span>{{personLicense.website}}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </mat-card-content>
                          </mat-card>                                       
                        </div>
                        <div fxLayout="row">                    
                          <mat-card fxFlex="100">
                            <mat-card-title>
                              Address Information
                            </mat-card-title>
                            <mat-card-content>                              
                              <div style="margin-top: 8px;" fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">                                
                                  <div fxLayout="column" fxLayoutGap="4px">
                                    <span><strong>Street: </strong>{{personLicense.mailingStreet}},</span>
                                    <span><strong>City: </strong>{{personLicense.mailingCity}},</span>
                                    <span><strong>State: </strong>{{personLicense.mailingState}}</span>
                                    <span><strong>Zip: </strong>{{personLicense.mailingZip}}</span>
                                    <span><strong>County: </strong>{{personLicense.mailingCounty}}</span>                                    
                                  </div>                                
                              </div>                          
                            </mat-card-content>
                          </mat-card>                                                                 
                        </div>

                        <div fxLayout="row" fxLayout.lt-lg="column" fxLayoutGap="8px">
                          <mat-card fxFlex="100" fxFlex.lt-lg="100">
                            <mat-card-title>
                              Individual Training and Documents
                            </mat-card-title>
                            <mat-card-content>
                              <div fxLayout="column">
                                <div [fxFlex]="100" style="overflow-x: auto;">
                                  <mat-table [dataSource]="trainingTable.layout.data" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
                                    <mat-header-row *matHeaderRowDef="trainingTable.layout.columns.concat('Documents')"></mat-header-row>
                                    <mat-row *matRowDef="let row; index as i; columns: trainingTable.layout.columns.concat('Documents');"></mat-row>
                                
                                    <ng-container *ngFor="let cellData of trainingTable.layout.container; let i = index" [matColumnDef]="cellData.columnName">
                                      <mat-header-cell *matHeaderCellDef>{{cellData.displayName}}</mat-header-cell>
                                      <mat-cell *matCellDef="let row">
                                        <ng-container *ngIf="cellData.type === 'date'">
                                          {{row[cellData.columnName] | date:'shortDate'}}
                                        </ng-container>
                                        <ng-container *ngIf="cellData.type === 'boolean'">
                                          <mat-checkbox [checked]="row[cellData.columnName]" [disabled]="true"></mat-checkbox>
                                        </ng-container>
                                        <ng-container *ngIf="cellData.type !== 'date' && cellData.type !== 'boolean'">
                                          {{row[cellData.columnName]}}
                                        </ng-container>
                                      </mat-cell>
                                    </ng-container>                                           
                                
                                    <ng-container matColumnDef="Documents">
                                      <mat-header-cell *matHeaderCellDef fxFlex.lt-sm="100" >Documents</mat-header-cell>
                                      <mat-cell *matCellDef="let row" fxFlex.lt-sm="100" fxLayout="column" class="left-align">
                                        <div class="button-container" *ngFor="let documentName of row['Documents']">
                                          <div (click)="$event.stopPropagation()">
                                            <button mat-icon-button class="stop-propagation" color="primary" aria-label="Download File" (click)="downloadTrainingFile(documentName)">
                                              <mat-icon>save_alt</mat-icon>
                                            </button>                              
                                            <span>{{documentName | fileDisplayName:20}}</span>                              
                                          </div>
                                        </div>
                                      </mat-cell>
                                    </ng-container>                                                        
                                  </mat-table>
                                </div>   
                                <mat-paginator [pageSizeOptions]="[5, 10, 20]" #documentPaginator="matPaginator"></mat-paginator>
                              </div>
                            </mat-card-content>                        
                          </mat-card>
                        </div>
                        <div fxLayout="row">
                          <mat-card fxFlex="50" fxFlex.lt-lg="100">
                            <mat-card-title>
                              Signature and Acknowledgments
                            </mat-card-title>
                            <mat-card-content>
                              <div fxLayout="column" fxLayoutGap="16px">
                                <div fxLayout="column" fxLayoutGap="16px" class="p-t-24">
                                  <span class="p-l-24">
                                    <strong>• </strong>I certify that I have read and shall comply with ORS 431A.355, 431A.358, 431A.363 and the provisions therein, 
                                    and that the information and documentation given in this application is complete and accurate to the best of my 
                                    knowledge
                                </span>
              
                                <mat-checkbox [checked]="licenseApplicationAttestationForm.get('attestation').value" formControlName="attestation">
                                    <strong>I understand the application fee is non-refundable.</strong>
                                  </mat-checkbox>
                                </div>
              
                                <div fxLayout="column" fxLayoutGap="8px">
                                  <span><strong>Name or Initials</strong></span>
                                  <div fxLayout="row">
                                    <mat-form-field fxFlex="60" fxFlex.lt-lg="100">
                                      <input matInput placeholder="Signature" formControlName="esignature">
                                    </mat-form-field>
                                  </div>

                                  <div fxLayout="row">
                                    <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="start center" fxLayoutGap="4px">
                                      <button mat-raised-button matStepperPrevious>Back</button>
                                      <button mat-raised-button color="primary" *ngIf="canEdit" type="button" (click)="submitApplication()" [disabled]="!(licenseApplicationAttestationForm.valid && licenseApplicationDataForm.valid)">Submit Application</button>
                                    </div>
                                  
                                    <div fxLayout="row" fxFlex="50" fxFlex.lt-md="100" class="p-t-16" fxLayoutAlign="end center" fxLayoutGap="4px">
                                      <button mat-raised-button color="warn" *ngIf="personLicense.id !== 0" (click)="cancelApplication()">{{personLicense.status !== "Submitted" ? "Cancel Application" : "Withdraw Application"}}</button>
                                      <button mat-raised-button (click)="exitApplication(true)">Exit</button>
                                    </div>
                                  </div>
                                </div>
                                <span class="text-red-400" *ngIf="!licenseApplicationDataForm.valid">Name and address is incomplete</span>
                                <!-- ngIf="workerLicense.applicants[0].applicantDocuments.length < 1" -->
                                <span class="text-red-400" *>You need to upload proof of identity</span>
                                <span class="text-red-400" *ngIf="!licenseApplicationAttestationForm.valid">You need to complete the signature and acknowledgments section</span>
                              </div>
                            </mat-card-content>
                          </mat-card>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </form>
            </mat-step>
        </mat-stepper>
    </div> 

    <div *ngIf="showConfirmed">
      <mat-card class="sticky-header p-y-0" [style.background]="uiData.individual.color">
        <mat-card-content>
          <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
            <mat-icon class="medium-icon m-24">badge</mat-icon>
            <span class="f-s-20" fxFlex>Individual Application Submitted!"</span>
          </div>
        </mat-card-content>
      </mat-card>
    
      <mat-card>
        <mat-card-content *ngIf="currentHeader === applyHeader">
          <span class="f-s-16">Thank you for submitting the application. You can review your application status
            and make updates as needed. Please be sure to monitor your email for follow-up communication from the Oregon Lead Based Paint Program. <br /><br />
            Your application will not be processed until payment is made.  Please see payment instructions below.<br /><br />
            </span>
            <!-- <span>
              <app-payment-notice [canPay]="true" [entityType]="paymentService.FirmEntityType" [feeType]="licenseType"></app-payment-notice>
            </span> -->
        </mat-card-content>
        <mat-card-content *ngIf="currentHeader === linkHeader">
          <span class="f-s-16">Thank you for submitting the application. An approval email was sent to INSERT PRIMARY CONTACT for the firm INSERT FIRM NAME. If the primary contact listed can not be reached or your request is extremely urgent you can contact the Oregon Lead Based Paint Program at <strong>leadprogram@odhsoha.oregon.gov</strong>. Please be sure to monitor your email for follow-up communications.
            </span>
        </mat-card-content>
      </mat-card>
      
      <mat-card>
      
        <mat-card-content>
          <div fxLayout="column" fxLayoutGap="8px" fxLayoutAlign="center center">
            <!-- <app-payment-notice [canPay]="false" [entityType]="paymentService.WorkerPermitPaymentOption"
              [feeType]="paymentService.applicationFeeType" [id]="workerLicense.id"></app-payment-notice> -->
      
            <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
              <button mat-raised-button type="button" color="primary" (click)="continue()">Continue</button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
</div>  
