<section *ngIf="loading">
    <h2>Loading...</h2>
    <mat-progress-bar mode="indeterminate" color="primary" aria-label="Loading...">
    </mat-progress-bar>
</section>

<div (window:resize)="onResize($event)">
  <br />
  <mat-card>
    <div [fxFlex.gt-sm]="100" [fxFlex]="100" class="matero-col" style="overflow-x: auto;">
        <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
            <div>
                <strong class="noMarginHeader">Results ({{firmAccountAffiliations.length}})</strong> 
                &nbsp;&nbsp;&nbsp;&nbsp;     
                <mat-form-field style="width:200px;">
                  <input (keyup)="filterTable($event)" placeholder="Filter Administrators" matInput />
                  <input placeholder="Filter Administrators" matInput />
                </mat-form-field>      
            </div>     
        </div>
        <br />
        <mat-table [dataSource]="dataSource" matSort #TableSort="matSort" class="mat-elevation-z8" style="overflow-x: auto;">
        <mat-row>Results ({{firmAccountAffiliations.length}})</mat-row>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row [ngClass]="sharedService.isMobile ? 'mobile-row':''" *matRowDef="let row; columns: displayedColumns;"></mat-row>
        
        <ng-container matColumnDef="id">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="20">ID number</mat-header-cell>
            <mat-cell *matCellDef="let result" fxFlex="20">
            <span title="Edit {{result.id}}" *ngIf="result.id !== ''">{{result.id}}</span>
            </mat-cell>
        </ng-container>
        
        <ng-container matColumnDef="userId">
            <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex.lt-sm="100">User ID</mat-header-cell>
            <mat-cell *matCellDef="let result" fxFlex.lt-sm="100">
            <span>{{result.userID}}</span>
            </mat-cell>
        </ng-container>
        
        <ng-container matColumnDef="Actions">
          <mat-header-cell *matHeaderCellDef mat-sort-header fxFlex="7">Actions</mat-header-cell>
          <mat-cell *matCellDef="let result" fxFlex="7">
            <a *ngIf="result.userID !== userID" href="javascript:void(0)" (click)="removeAdmin(result)" title="Remove Admin {{result.userID}}">
              <mat-icon>delete</mat-icon>
            </a>
          </mat-cell>
        </ng-container>
        </mat-table>
    
      <br />
      <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="[10, 50, firmAccountAffiliations.length]" #TablePaginator="matPaginator"
        showFirstLastButtons></mat-paginator>
    </div>
  </mat-card>
</div>  