import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IFirmAddress } from 'app/models/firm/firm-address';
import { AddressService } from 'app/services/common/address.service';
import { SharedService } from 'app/services/core/shared.service';
import { FirmService } from 'app/services/firm/firm.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-address-add-edit',
  templateUrl: './address-add-edit.component.html',
  styleUrls: ['./address-add-edit.component.scss']
})
export class AddressAddEditComponent implements OnInit {
  public loading: boolean = true;
  public physicalChanged: boolean = false;
  public mailingChanged: boolean = false;

  public physicalAddressForm: FormGroup;
  public mailingAddressForm: FormGroup;
  private formFields = {
    //contactType:{name: 'Contact Type', type: 'string'},
    street:{name: 'Street', type: 'string'},
    city:{name: 'City', type: 'string'},
    state:{name: 'State', type: 'string'},
    zip:{name: 'Cover Fee', type: 'string'},
    county:{name: 'Cover Fee', type: 'string'}
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {currentPhysical:IFirmAddress,currentMailing:IFirmAddress},    
    public sharedService: SharedService,    
    private dialogRef: MatDialogRef<AddressAddEditComponent>,
    private toastr: ToastrService,
    private firmService: FirmService,
  ) 
  { 
    this.setFormValues(); 
    this.loading = false;   
  }

  ngOnInit(): void {
  }

  setFormValues() {
    this.physicalAddressForm = new FormGroup({});    
    this.mailingAddressForm = new FormGroup({});    

    for (const key in this.formFields) {          
      this.physicalAddressForm.addControl(key, new FormControl('', Validators.required));      
      this.mailingAddressForm.addControl(key, new FormControl('', Validators.required));      
    }

    for (const controlName in this.data.currentPhysical.address) {
      if (this.physicalAddressForm.controls.hasOwnProperty(controlName)) {
        this.physicalAddressForm.controls[controlName].setValue(this.data.currentPhysical.address[controlName]);
      } 
    } 

    for (const controlName in this.data.currentMailing.address) {
      if (this.mailingAddressForm.controls.hasOwnProperty(controlName)) {
        this.mailingAddressForm.controls[controlName].setValue(this.data.currentMailing.address[controlName]);
      } 
    } 

    this.physicalAddressForm.valueChanges.subscribe(() => {
      this.physicalChanged = true;
    });

    this.mailingAddressForm.valueChanges.subscribe(() => {
      this.mailingChanged = true;
    });
  }

  onSubmit(){
    if(this.physicalChanged || this.mailingChanged){
      if(this.physicalChanged){
        this.data.currentPhysical.isCurrentPhysical = false;
        this.firmService.saveFirmAddress(this.data.currentPhysical).subscribe(result=>{
          for (const controlName in this.physicalAddressForm.controls) {
            if(this.physicalAddressForm.controls[controlName].value !== this.data.currentPhysical.address[controlName])
            {          
              this.data.currentPhysical.address[controlName] = this.physicalAddressForm.controls[controlName].value;
            }        
          }
  
          this.data.currentPhysical.isCurrentPhysical = true;
          this.data.currentPhysical.id = 0;
          this.data.currentPhysical.address.id = 0;
  
          this.firmService.saveFirmAddress(this.data.currentPhysical).subscribe(result=>{
            this.data.currentPhysical.id = result.id
            this.data.currentPhysical.address.id = result.address.id;          
          },error=>{console.error("There was an error saving the new physical address: ", error)})
        },error=>{console.error("There was an error updating the current physical address: ", error)})
      }
  
      if(this.mailingChanged){
        this.data.currentMailing.isCurrentMailing = false;
        this.firmService.saveFirmAddress(this.data.currentMailing).subscribe(result=>{
          for (const controlName in this.mailingAddressForm.controls) {
            if(this.mailingAddressForm.controls[controlName].value !== this.data.currentMailing.address[controlName])
            {          
              this.data.currentMailing.address[controlName] = this.mailingAddressForm.controls[controlName].value;
            }        
          }
    
          this.data.currentMailing.isCurrentMailing = true;
          this.data.currentMailing.id = 0;
          this.data.currentMailing.address.id = 0;
    
          this.firmService.saveFirmAddress(this.data.currentMailing).subscribe(result=>{
            this.data.currentMailing.id = result.id;
            this.data.currentMailing.address.id = result.address.id;
          },error=>{console.error("There was an error saving the new mailing address: ", error)})
        },error=>{console.error("There was an error updating the current mailing address: ", error)})
      }

      this.toastr.success("Address Updated");
      this.dialogRef.close(); 
    }
  }
}
