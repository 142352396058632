import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { COURSE_TYPE, DISCIPLINE_CODES } from '@shared/utils/app-static-data';
import { StaffAddEditComponent } from 'app/components/common/staff/add-edit/staff-add-edit.component';
import { IFirmLicensePerson } from 'app/models/firm/firm-license-person';
import { IPersonLicense } from 'app/models/people/person-license';
import { IPersonTraining } from 'app/models/people/personTraining';
import { FirmService } from 'app/services/firm/firm.service';
import { PersonService } from 'app/services/person/person.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-edit-training',
  templateUrl: './add-edit-training.component.html',
  styleUrls: ['./add-edit-training.component.scss']
})
export class AddEditTrainingComponent implements OnInit {
  buttonText: string = "";
  loading: boolean = false;
  processing: boolean = false;  
  postErrorMessages: string = ""; 
  
  public personTrainingData: IPersonTraining = {
    id: 0,
    person: null,
    codeType: '',
    stage: '',
    trainingDate: '',
    expirationDate: '',
    dateOfBirth: '',
    personLicenseDocument: null,
    personLicense: null,
  };
  objectKeys = Object.keys;
  formFields = {    
    codeType:{name: 'Training Type', type: 'option', data: Object.values(COURSE_TYPE)},
    stage:{name: 'Training Category', type: 'option', data: Object.values(DISCIPLINE_CODES).map(item => item.display)},
    trainingDate:{name: 'Training Date', type: 'date'},
    expirationDate:{name: 'Training Expiration Date', type: 'date'}
  };

  personTrainingForm: FormGroup = null;

  constructor( 
    public personService: PersonService,     
    private toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: {personTrainingData: IPersonTraining, personLicense: IPersonLicense},
    private dialogRef: MatDialogRef<StaffAddEditComponent>) {
    this.personTrainingData = this.data?.personTrainingData ?? this.personTrainingData;
    this.personTrainingData.personLicense = this.data.personLicense;    
    this.loading = true;    
    this.loadfirm();
  }

  ngOnInit(): void { 
    // ngOnInit
  }

  loadfirm() {
    this.loading = true;    
    if (this.personTrainingData?.id > 0) {
      this.buttonText = "Update Training";      
    }
    else {
      this.buttonText = "Add Training";            
    }
    this.setFormValues();
    this.loading = false;
  }

  onSubmit() {
    if (!this.personTrainingForm.valid) {
      this.postErrorMessages = "Individual Name is required";
      this.toastr.error("Please fix validation errors");
      return;
    }
    
    this.getFormValues();    
    this.personService.savePersonTraining(this.personTrainingData).subscribe(
      result => {
        this.dialogRef.close();
        this.toastr.success("Training Entry Saved");    
      },
      error => {
        this.toastr.error("Error saving the training entry");
      }
    )
  }

  getFormValues(){
    for (const controlName in this.personTrainingData) {
      if (this.personTrainingForm.controls.hasOwnProperty(controlName)) {
        this.personTrainingData[controlName] = this.personTrainingForm.controls[controlName].value;        
      } 
    }  
  }

  setFormValues() {
    this.personTrainingForm = new FormGroup({});
    //this.formFields = this.licenseType == 'lbpa' ? this.lbpaFormFields : this.rrpFormFields;

    for (const key in this.formFields) {          
      this.personTrainingForm.addControl(key, new FormControl('', Validators.required));      
    }

    for (const controlName in this.personTrainingData) {
      if (this.personTrainingForm.controls.hasOwnProperty(controlName)) {
        this.personTrainingForm.controls[controlName].setValue(this.personTrainingData[controlName]);
      } 
    } 
  }
}