import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { map, take } from 'rxjs/operators';
import { environment } from '@env/environment';
import { ConfirmDialogComponent } from '@shared/components/confirm-dialog/confirm-dialog.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';


const httpOptionsFormData = {
  headers: new HttpHeaders({
    'Cache-Control': 'no-cache',
  }),
  withCredentials: true
};

@Injectable(
  { providedIn: 'root' }
)
export class SharedService {
  public mobile: boolean = false;
  private changeTheme: Subject<string> = new Subject<string>();
  public darkMode: boolean = false;
  public userTheme: string = environment.theme;
  private api: string = environment.privateApi;
  public loading: boolean = false;
  public tablet: boolean = false;

  //Shared application statuses  
  public pendingApplicationStatus = "InProgress";

  
  public blue: string = '#628ef4';

  stateList: string[] = [
    "AL", "AK", "AZ", "AR", "CA", "CO", "CT", "DE", "FL", "GA", "HI", "ID", "IL",
    "IN", "IA", "KS", "KY", "LA", "ME", "MD", "MA", "MI", "MN", "MS", "MO", "MT",
    "NE", "NV", "NH", "NJ", "NM", "NY", "NC", "ND", "OH", "OK", "OR", "PA", "RI",
    "SC", "SD", "TN", "TX", "UT", "VT", "VA", "WA", "WV", "WI", "WY"
  ];

  countyList: string[] = [
    "Baker", "Benton", "Clackamas", "Clatsop", "Columbia", "Coos", "Crook",
    "Curry", "Deschutes", "Douglas", "Gilliam", "Grant", "Harney", "Hood River",
    "Jackson", "Jefferson", "Josephine", "Klamath", "Lake", "Lane", "Lincoln",
    "Linn", "Malheur", "Marion", "Morrow", "Multnomah", "Polk", "Sherman",
    "Tillamook", "Umatilla", "Union", "Wallowa", "Wasco", "Washington", "Wheeler",
    "Yamhill"
  ];

  public dialogRef: MatDialogRef<ConfirmDialogComponent>;

  constructor(private httpClient: HttpClient, public dialog: MatDialog, public toastr: ToastrService) { }

  public openConfirm(message: string): void{
    this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: message,
      minWidth: !this.mobile? '400px' : '',
      maxWidth: !this.mobile? '700px' : '300px',
      maxHeight: this.mobile? '600px' : '700px',
      panelClass: this.darkMode ? "theme-dark" : "",
    });
  }

  public translateStatus(status: string) : string {
    if(status == this.pendingApplicationStatus) {
      return "In Progress";
    }

    return status;
  }

  public confirmed(): Observable<any>{
    return this.dialogRef.afterClosed().pipe(take(1), map(res => {
      return res;
    }));
  }

  get isMobile(): boolean {
    return this.mobile;
  }

  get counties(): string[] {
    return this.countyList;
  }

  get states(): string[] {
    return this.stateList;
  }

  themeChanged$ = this.changeTheme.asObservable();

  toggleTheme() {
    this.darkMode = this.userTheme === 'dark';
    this.changeTheme.next(this.userTheme);
  }

  updateUserTheme(): void {
    this.httpClient.post<boolean>(this.api + "UserProfile/UpdateUserTheme/" +  this.userTheme, null).subscribe(
      () => { },
      error => {
        console.log(error);
       }
    );
  }

  getUserTheme(): Observable<string> {
    return this.httpClient.get<string>(this.api +  "UserProfile/GetUserTheme/", httpOptionsFormData);
  }

}
