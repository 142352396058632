<img *ngIf="!sharedService.mobile && !authorized" src="../../../assets/images/translucentOhaLogo.png" class="bg" alt="OHA Logo">

<img *ngIf="sharedService.mobile && !authorized" src="../../../assets/images/translucentOhaLogo.png" class="bg-mobile" alt="OHA Logo">

<div *ngIf="!authorized">
     <div fxLayout="column" fxLayoutGap="32px" fxLayoutAlign="center center" class="p-t-32">
       <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign.lt-md="center center" fxLayoutGap="8px">
        <span style="font-family: Oswald; font-size: 30pt;" *ngIf="!sharedService.isMobile">{{title}}</span>
        <span style="font-family: Oswald; font-size: 20pt;" *ngIf="sharedService.isMobile">{{titleMobile}}</span>
       </div>
      <button mat-button (click)="login()" color="primary">
        <span class="f-s-20 p-32">Enter</span>
      </button>
    </div>
</div>


<div *ngIf="sharedService.loading">
  <div id="globalLoader" [ngClass]="{'global-loader': true, 'global-loader-dark': sharedService.darkMode}">
    <h1>Loading</h1>
  </div>
</div>

