import { Component, Output, EventEmitter,
         Input, ChangeDetectionStrategy, ViewEncapsulation, OnInit
} from '@angular/core';
import { environment } from 'environments/environment';
import { AuthService } from 'app/auth/auth.service';
import { SharedService } from 'app/services/core/shared.service';
import { Subscription } from 'rxjs';
import { MsalBroadcastService } from '@azure/msal-angular';
import { filter } from 'rxjs/operators';
import { EventMessage, EventType } from '@azure/msal-browser';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-navbar',
  host: {
    class: 'matero-header',
  },
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.Default,
})
export class NavbarComponent implements OnInit { 
  subscription !: Subscription;
  public environment = environment;
  username: string = null;
  @Input() isMobile = false;
  @Input() showToggle = true;
  @Output() toggleSidenav = new EventEmitter<void>();

  constructor(public sharedService: SharedService,
              public authService: AuthService,
              private msalBroadcastService: MsalBroadcastService,
               public toastr: ToastrService) { }

  ngOnInit(): void {
    if(this.authService.isAuthenticated()) {
      this.postLogin();
    }
    this.msalBroadcastService.msalSubject$
    .pipe(
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
    )
    .subscribe(() => {
      this.postLogin();
      if(!this.sharedService.isMobile) {
        this.toggleSidenav.emit();
      }
    });
  }

  postLogin() {
    console.log('post login');
    this.authService.loadUserProfile();
    this.sharedService.getUserTheme().subscribe(
      (response) => { 
        this.sharedService.userTheme = response;
        this.sharedService.toggleTheme();
      },
      error => {
        console.log(error);
        this.toastr.error("Unable to load user theme");
       }
    );    
  }

  login() {
    this.authService.login();
  }

  isAuthenticated(): boolean {
    return this.authService.isAuthenticated();
  }

  logout() {
    this.authService.logout();
  }

  toggleTheme() {
    this.sharedService.darkMode = !this.sharedService.darkMode;
    this.sharedService.userTheme = this.sharedService.darkMode ? "dark" : "light";
    if(this.isAuthenticated()){
      this.sharedService.updateUserTheme();
    }
    this.sharedService.toggleTheme();
  }
}
