<section *ngIf="loading">
    <h2>Loading...</h2>
    <mat-progress-bar mode="indeterminate" color="primary" aria-label="Loading...">
    </mat-progress-bar>
</section>

<div *ngIf="!loading">
    <div fxLayout="row wrap" class="matero-row" fxLayoutAlign="space-between start">
        <div fxFlex="50.00" fxFlex.lt-sm="100" class="matero-col" fxLayoutAlign="start">
          <h2 class="noMarginHeader">
            <mat-icon>edit</mat-icon> Edit {{data.currentPhysical.firm.name}} Address
          </h2>
        </div>
        
        <div fxFlex="50.00" fxFlex.lt-sm="100" class="matero-col" fxLayoutAlign="end">
          <button type="submit" (click)="onSubmit()" mat-raised-button color="primary"
            title="Update Address">Update</button>
          &nbsp;&nbsp;
          <button mat-button mat-dialog-close="Cancel" aria-label="Cancel" mat-raised-button color="basic"
            title="Close">Close</button>
            &nbsp;&nbsp;
        </div>
    </div>
    <div fxLayout="row" fxLayoutGap="15px" style="padding-right: 15px;">
        <mat-card fxFlex="50">
            <form [formGroup]="physicalAddressForm" fxLayout="column">
                <h3>Physical Address</h3>   
                <div fxLayout="row" fxLayoutGap="8px">
                    <mat-form-field fxFlex="100">
                        <input matInput placeholder="Street Apt/Unit/Suite" formControlName="street">
                        <mat-error *ngIf="physicalAddressForm.get('street').invalid">
                        Please provide a street
                        </mat-error>
                    </mat-form-field>
                </div> 
                <div fxLayout="row" fxLayoutGap="8px">
                    <mat-form-field fxFlex="85">
                        <input matInput placeholder="City" formControlName="city">
                        <mat-error *ngIf="physicalAddressForm.get('city').invalid">
                        Please provide a city
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="10">
                        <mat-label style="color:red" *ngIf="physicalAddressForm.get('state').value == ''">State</mat-label>
                        <mat-label *ngIf="physicalAddressForm.get('state').value != ''">State</mat-label>
                        <mat-select formControlName="state">
                        <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="physicalAddressForm.get('state').invalid">
                        Please select a state
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="15">
                        <input matInput placeholder="Zip" formControlName="zip">
                        <mat-error *ngIf="physicalAddressForm.get('zip').hasError('required')">
                        Please provide a zipcode
                        </mat-error>
                        <mat-error *ngIf="physicalAddressForm.get('zip').hasError('pattern')">
                        Zipcode should match this format: 99999 or 99999-1234
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="8px">
                    <mat-form-field *ngIf="physicalAddressForm.get('state').value === 'OR'" fxFlex="100">
                        <mat-label>County</mat-label>
                        <mat-select formControlName="county">
                        <mat-option>-- None --</mat-option>
                        <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
        </mat-card>
        <mat-card fxFlex="50">            
            <form [formGroup]="mailingAddressForm" fxLayout="column">
                <h3>Mailing Address</h3>            
                <div fxLayout="row" fxLayoutGap="8px">
                    <mat-form-field fxFlex="100">
                        <input matInput placeholder="Street Apt/Unit/Suite" formControlName="street">
                        <mat-error *ngIf="mailingAddressForm.get('street').invalid">
                        Please provide a street
                        </mat-error>
                    </mat-form-field>
                </div> 
                <div fxLayout="row" fxLayoutGap="8px">
                    <mat-form-field fxFlex="85">
                        <input matInput placeholder="City" formControlName="city">
                        <mat-error *ngIf="mailingAddressForm.get('city').invalid">
                        Please provide a city
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="10">
                        <mat-label style="color:red" *ngIf="mailingAddressForm.get('state').value == ''">State</mat-label>
                        <mat-label *ngIf="mailingAddressForm.get('state').value != ''">State</mat-label>
                        <mat-select formControlName="state">
                        <mat-option *ngFor="let state of sharedService.states" value={{state}}>{{state}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="mailingAddressForm.get('state').invalid">
                        Please select a state
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex="15">
                        <input matInput placeholder="Zip" formControlName="zip">
                        <mat-error *ngIf="mailingAddressForm.get('zip').hasError('required')">
                        Please provide a zipcode
                        </mat-error>
                        <mat-error *ngIf="mailingAddressForm.get('zip').hasError('pattern')">
                        Zipcode should match this format: 99999 or 99999-1234
                        </mat-error>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="8px">
                    <mat-form-field *ngIf="mailingAddressForm.get('state').value === 'OR'" fxFlex="100">
                        <mat-label>County</mat-label>
                        <mat-select formControlName="county">
                        <mat-option>-- None --</mat-option>
                        <mat-option *ngFor="let county of sharedService.counties" value={{county}}>{{county}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </form>
        </mat-card>
    </div>
</div>