import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonService } from 'app/services/common/common.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-confirmation-dialogue',
  templateUrl: './confirmation-dialogue.component.html',
  styleUrls: ['./confirmation-dialogue.component.scss']
})
export class ConfirmationDialogueComponent implements OnInit {   
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  private commonService: CommonService,
  private sanitizer: DomSanitizer) {}
  
  ngOnInit(): void {}
  
  sanitizedData(data:string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      data.replace(/\n/g, '<br>')
    );
  }
}
