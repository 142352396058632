import { trigger, transition, style, animate } from "@angular/animations";

export const LEAD_BASED_PAINT = {
    'course': 
    {
        'icon':'business_center'
    },
    'endorsement':
    {
        'icon':'how_to_vote'
    },
    'credential':
    {
        'icon':'card_membership'
    },
    'certificate':
    {
        'icon':'receipt'
    },
    'firm':
    {
        'icon':'business',
        'color':'#458c4c',        
    },
    'individual':
    {
        'icon':'recent_actors',
        'color':'#9355dc',
    },
    'atp':
    {
        'icon':'school',
        'color':'#628ef4',
    },
    'lbpa':
    {
        'icon':'store_mall_directory',
        'color':'#ffbe2e',        
    },
    'rrp':
    {
        'icon':'build',
        'color':'#EC891D',        
    },
    'complaint':
    {
        'icon':'comment',
        'color':'#ff685d'
    },
    'payment':
    {
        'icon':'attach_money'
    },
    'apply':
    {
        'icon':'book'
    },
    'staff':
    {
        'icon':'people'
    },
    'address':
    {
        'icon':'location_on'
    },
    'contact':
    {
        'icon':'contact_mail'
    },
    'name':
    {
        'icon':'label'
    },
    'id':
    {
        'icon':'info'
    },
    'calendar':
    {
        'icon':'event'
    },
};

export function getStatusColor(status: string): string {
    switch (status) {
        case APPLICATION_STATUS.submitted:
        return '#ffe599';
        case APPLICATION_STATUS.inProgress:
        return '#bcbcbc';
        case APPLICATION_STATUS.cancelled:
        return '#cc0000';
        case APPLICATION_STATUS.approved:
        return '#8fce00';
        case APPLICATION_STATUS.inReview:
        return '#2986cc';
        case APPLICATION_STATUS.actionNeeded:
        return '#e69138';
        default:
        return '#ffffff';
    }
};

export const APPLICATION_STATUS = {    
    cancelled: "Cancelled",
    inProgress: "In Progress",
    submitted: "Submitted",    
    inReview: "In Review",
    actionNeeded: "Action Needed",    
    approved: "Approved",
}

export const FIRM_SERVICES = {
    inspections: "Inspections",
    riskAssessments: "Risk Assessments",
    clearanceTesting: "Clearance Testing",
    leadHazardScreening: "Lead Hazard Screening",
    abatement: "Abatement",
}

export const FIRM_SERVICE_FOR = {
    internal: "Internal Only",
    residential: "Residential",
    commercial: "Commercial",
    both: "Both Residential and Commercial",    
}

export const FIRM_APPLICATION_TYPES = {
    rrp: "rrp",
    lbpa: "lbpa",
    link: "link"
}

export const DISCIPLINE_CODES = {
    inspector: {code:'I', display:'Inspector'},
    projectDesigner: {code:'D', display:'Project Designer'},
    riskAssessor: {code:'R', display:'Risk Assessor'},
    supervisor: {code:'S', display:'Supervisor'},
    worker: {code:'W', display:'Worker'},
}

export const COURSE_TYPE = {
    standard: 'Standard',
    refresher: 'Refresher'
}

export const ANIMATIONS = [
    trigger('slideLeft', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(-100%)' }))
      ])
    ]),
    trigger('slideRight', [
      transition(':enter', [
        style({ transform: 'translateX(100%)' }),
        animate('200ms ease-in', style({ transform: 'translateX(0%)' }))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({ transform: 'translateX(100%)' }))
      ])
    ]),
    
  ];