
<section *ngIf="loading">
    <h2>Loading...</h2>
    <mat-progress-bar mode="indeterminate" color="primary" aria-label="Loading...">
    </mat-progress-bar>
  </section>

<div *ngIf="!loading">
    <br />
    <mat-card fxLayout="row">
        <div fxFlex="50">
            <h3>Physical Address</h3>            
            <div><strong>Street: </strong>{{currentPhysicalAddress.address.street}}</div>
            <div><strong>City: </strong>{{currentPhysicalAddress.address.city}}</div>
            <div><strong>State: </strong>{{currentPhysicalAddress.address.state}}</div>
            <div><strong>Zip: </strong>{{currentPhysicalAddress.address.zip}}</div>
            <div *ngIf="currentPhysicalAddress.address.county !== ''"><strong>County: </strong>{{currentPhysicalAddress.address.county}}</div>            
        </div>
        <div fxFlex="50">
            <h3>Mailing Address</h3>            
            <div><strong>Street: </strong>{{currentMailingAddress.address.street}}</div>
            <div><strong>City: </strong>{{currentMailingAddress.address.city}}</div>
            <div><strong>State: </strong>{{currentMailingAddress.address.state}}</div>
            <div><strong>Zip: </strong>{{currentMailingAddress.address.zip}}</div>
            <div *ngIf="currentMailingAddress.address.county !== ''"><strong>County: </strong>{{currentMailingAddress.address.county}}</div>   
        </div>
    </mat-card>

    <button mat-raised-button color="primary" title="Edit Address" (click)="editAddress()">
        <mat-icon>edit</mat-icon> Edit Address
    </button>   
</div>