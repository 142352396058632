import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { ANIMATIONS, LEAD_BASED_PAINT } from '@shared/utils/app-static-data';
import { IPayment } from 'app/components/payments/models/payment';
import { IAccountAffiliation } from 'app/models/common/account-affiliation';
import { IFirm } from 'app/models/firm/firm';
import { IFirmLicense } from 'app/models/firm/firm-license';
import { CommonService } from 'app/services/common/common.service';
import { SharedService } from 'app/services/core/shared.service';
import { FirmService } from 'app/services/firm/firm.service';
import { UserInformationService } from 'app/services/user-info/user-info.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-healthy-dashboard',
  templateUrl: './healthy-dashboard.component.html',
  styleUrls: ['./healthy-dashboard.component.scss'],  
  providers: [],
  animations: ANIMATIONS,
  encapsulation: ViewEncapsulation.None
})
export class HealthyDashboardComponent implements OnInit {  
  uiData: any = LEAD_BASED_PAINT; 
  firmLicense: IFirmLicense = null;
  accountAffiliations: IAccountAffiliation[];  
  payment: IPayment; 
  firmData: IFirm;
  firmCertificates:any;
  loadCount: number = 0;
  loading: boolean = true; 
  objectKeys = Object.keys;

  constructor(public sharedService: SharedService,    
    public firmService: FirmService, 
    public commonService: CommonService, 
    public userService: UserInformationService,
    private toastr: ToastrService,) { }

  ngOnInit(): void {
    this.checkIfAffiliationsLoaded();
    this.checkIfFirmLicensesLoaded();
  }

  checkIfAffiliationsLoaded(){
    this.loading = true;
    if(!this.commonService.loaded){
      setTimeout(() => {                
        this.checkIfAffiliationsLoaded();
      }, 1500);
    }
    else {
      this.loadAffiliations();      
    }
  }

  checkIfFirmLicensesLoaded(){
    this.loading = true;
    if(!this.commonService.loaded){
      setTimeout(() => {                
        this.checkIfFirmLicensesLoaded();
      }, 1500);
    }
    else {
      this.loadFirmLicenseApplications();      
    }
  }

  filterTable(event: any) {    
  }  

  loadData(){  
    ++this.loadCount;    
    if(this.loadCount >= 2){      

      if(this.firmData){
        this.firmService.loadFirmData(this.firmData).subscribe(res=>{        
          this.firmCertificates = this.firmService.firmCertificates;          
          this.loading = false;
          
        },err=>{}) 
      }
      else{
        this.getPaymentStatus();
      }
    }        
  }

  loadAffiliations(){
    this.accountAffiliations = this.commonService.accountAffiliations; 
    var firmAffiliations = this.accountAffiliations.filter(x=>x.firm != null && x.status != "Cancelled")
    this.firmData = firmAffiliations.length ? firmAffiliations[0].firm : null;
    
    this.loadData();
  }

  loadFirmLicenseApplications(){
    this.firmLicense = this.firmService.firmLicense;       
    
    this.loadData() ;
  }

  getPaymentStatus() { 
    if (this.firmLicense)
    {   
      this.userService.getUserLicenses().subscribe(result => {            
        this.payment = result.filter(x=>x.id===this.firmLicense.id)[0].payments[0];     
        this.loading = false;    
      }, error => {      
        this.toastr.error('Unable to get user payments');
      });
    }
    else
      this.loading = false;    
  }
}