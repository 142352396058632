
<section *ngIf="loading">
    <h2>Loading...</h2>
    <mat-progress-bar mode="indeterminate" color="primary" aria-label="Loading...">
    </mat-progress-bar>
  </section>

<div *ngIf="!loading">
    <br />
    <mat-card fxLayout="row">
        <div fxFlex="50">
            <h3>Firm Contact Information</h3>            
            <div><strong>Primary Phone: </strong>{{firmContactInformation.phone}}</div>
            <div><strong>Alternate Phone: </strong>{{firmContactInformation.cell}}</div>
            <div><strong>Fax: </strong>{{firmContactInformation.fax}}</div>
            <div><strong>Email: </strong>{{firmContactInformation.email}}</div>    
            <div><strong>Website: </strong>{{firmContactInformation.website}}</div>
        </div>
        <div fxFlex="50">
            <h3>Firm Contact Person</h3>            
            <div><strong>Name: </strong>{{firmPersonContact[0].person.firstName + ' ' + firmPersonContact[0].person.lastName}}</div>
            <div><strong>Primary Phone: </strong>{{firmPersonContact[0].contactInformation.phone}}</div>
            <div><strong>Alternate Phone: </strong>{{firmPersonContact[0].contactInformation.cell}}</div>
            <div><strong>Fax: </strong>{{firmPersonContact[0].contactInformation.fax}}</div>
            <div><strong>Email: </strong>{{firmPersonContact[0].contactInformation.email}}</div>            
        </div>
    </mat-card>

    <button mat-raised-button color="primary" title="Add Course">
        <mat-icon>edit</mat-icon> Edit Contact
    </button>  
</div>