import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { environment } from '@env/environment';

import { DefaultLayoutComponent } from './layout/default-layout/default-layout.component';
import { HomeComponent } from './components/home/home.component';
import { UserInfoComponent } from './components/core/user-info/user-info.component';
import { AppInfoComponent } from './components/core/app-info/app-info.component';
import { LoginFailedComponent } from './components/core/login-failed/login-failed.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthGuard } from './auth/auth-guard';
import { ComplaintsComponent } from './components/complaints/complaints.component';
import { FirmComponent } from './components/firm/firm.component';
import { FirmLicenseComponent } from './components/firm/license/firm-license.component';
import { ApplicationsComponent } from './components/common/applications/applications.component';
import { PaymentsComponent } from './components/payments/components/payments/payments.component';
import { PaymentsModule } from './components/payments/payment.module';
import { PayOnlineComponent } from './components/payments/components/pay-online/pay-online.component';
import { PayOnlineCancelComponent } from './components/payments/components/pay-online/cancel/pay-online-cancel.component';
import { PayOnlineFailureComponent } from './components/payments/components/pay-online/failure/pay-online-failure.component';
import { PayOnlineDuplicateComponent } from './components/payments/components/pay-online/duplicate/pay-online-duplicate.component';
import { PayOnlineSuccessComponent } from './components/payments/components/pay-online/success/pay-online-success.component';
import { IndividualComponent } from './components/individual/individual.component';
import { IndividualLicenseComponent } from './components/individual/license/individual-license.component';

const routes: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      { path: 'home', component: HomeComponent },
      { path: "dashboard", component: DashboardComponent, canActivate: [ AuthGuard ] },
      { path: 'profile', component: UserInfoComponent, canActivate: [ AuthGuard ] },
      { path: 'app-info', component: AppInfoComponent, canActivate: [ AuthGuard ] },      
      { path: 'firm/manage', component: FirmComponent, canActivate: [ AuthGuard ] },     
      { path: 'firm/apply', component: FirmLicenseComponent, canActivate: [ AuthGuard ] },
      //{ path: 'firm/history', component: ApplicationsComponent, canActivate: [ AuthGuard ] },
      { path: 'individual/manage', component: IndividualComponent, canActivate: [ AuthGuard ] },     
      { path: 'individual/apply', component: IndividualLicenseComponent, canActivate: [ AuthGuard ] },
      { path: 'payments', component: PaymentsComponent, canActivate: [ AuthGuard ] },
      { path: 'complaints', component: ComplaintsComponent},
      { path: 'login-failed', component: LoginFailedComponent},      
      {
        path: 'sessions',
        loadChildren: () => import('./components/sessions/sessions.module').then(m => m.SessionsModule),
      },

      { path: 'payments',
        canActivate: [ AuthGuard ],
        //component: PaymentsComponent,
        loadChildren: () => import('./components/payments/payment.module').then(m => m.PaymentsModule)
      },

      { path: 'pay-online/:id',
        canActivate: [ AuthGuard ],
        component: PayOnlineComponent
      },
      { path: 'payment/cancel',
        canActivate: [ AuthGuard ],
        component: PayOnlineCancelComponent
      },
      { path: 'payment/failure',
        canActivate: [ AuthGuard ],
        component: PayOnlineFailureComponent
      },
      { path: 'payment/duplicate',
        canActivate: [ AuthGuard ],
        component: PayOnlineDuplicateComponent
      },
      { path: 'payment/success',
        canActivate: [ AuthGuard ],
        component: PayOnlineSuccessComponent
      },
    ],
  },
  { path: '**', redirectTo: 'home' },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
      initialNavigation: !isIframe ? "enabledNonBlocking" : "disabled",
      relativeLinkResolution: 'legacy'
    }),
  ],
  exports: [RouterModule],
  declarations: [
    LoginFailedComponent
  ],
})
export class RoutesRoutingModule { }
