import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LEAD_BASED_PAINT, getStatusColor } from '@shared/utils/app-static-data';
import { IPerson } from 'app/models/people/person';
import { IPersonLicense } from 'app/models/people/person-license';
import { SharedService } from 'app/services/core/shared.service';

@Component({
  selector: 'app-individual-status-card',
  templateUrl: './individual-status-card.component.html',
  styleUrls: ['./individual-status-card.component.scss']
})
export class IndividualStatusCardComponent implements OnInit {
  public uiData = LEAD_BASED_PAINT; 
  public statusColor = getStatusColor;

  applyData:{width:number, cols:number, rows:number, color:string, link:string, icon:string, title:string, description:string[], action:string} = 
    { 
      width:6, cols:2, rows:1, color:this.uiData.individual.color, link:'/individual/apply', icon:this.uiData.firm.icon, title:'Individual',
      description:['Apply for Individual Certifications','Link your account to an existing Individual\'s Certifications'],
      action:'Apply or Link to an Individual'
    };

  @Input() displayData: {personLicense:IPersonLicense, personData: IPerson};

  displayFields: {dataName:string,displayName:string,dataType:string}[] = [
    {dataName:'id',displayName:'Firm ID: ',dataType:'string'},
    {dataName:'ccb',displayName:'CCB: ',dataType:'string'},
    {dataName:'ccbExpirationDate',displayName:'CCB Expiration: ',dataType:'date'},
    {dataName:'inactive',displayName:'Firm Status: ',dataType:'string'},
    {dataName:'isPrivate',displayName:'Private Firm: ',dataType:'bool'},
    {dataName:'servicesAvailable',displayName:'Services Avalable: ',dataType:'string'},
  ]

  constructor(public sharedService: SharedService, private router: Router) { }

  ngOnInit(): void {
  }

  applicationClicked(){
    this.router.navigate(['/individual/apply']);
  }
}
